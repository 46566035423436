export const STATS_APP_ERROR_MESSAGES = {
    ActionRequired: 'A play must have an action',
    NoFlagPullOnTouchdown: 'cannot have a flag puller if it results in a touchdown',
    NoFlagPullOnDroppedLat: 'cannot have a flag puller if it results in a dropped lateral',
    NoShortYardsOnInterception: 'cannot result in short yards or a loss (should be a 1st down instead)',
    ResultRequired: 'must have a result',
    QuaterbackAndReceiverRequired: 'must have a quarterback and a receiver',
    QuaterbackRequired: 'must have a quarterback',
    KickerRequired: 'must have a kicker',
    KickReturnerRequired: 'must have a kick returner if: 1. It results in a touchdown, 2. The flag puller is set, or 3. A lateral is set',
    FlagPullerRequired: 'must have a flag puller (or someone who force the player down/out of bounds)',
    InterceptorRequired: 'must have an interceptor',
    DefenderRequired: 'must have a defender',
    DistanceRequired: 'must have a distance',
    KickReturnDistanceRequired: 'must have a kick return distance if the returner is set',
    NoResultAllowed: 'cannot have a result',
    NoDistanceAllowed: 'cannot have a distance',
}