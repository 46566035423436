export class LogSentence {
    actionOId: any;
    actionDId: any;
    resultId: any;
    penaltyId: any;
    sentence: string;

    get key(): string {
        return this.actionOId + '-' + 
            this.actionDId + '-' + 
            this.resultId + '-' + 
            this.penaltyId;
    }

    constructor(data?: { 
        actionOId?: any,
        actionDId?: any,
        resultId?: any,
        penaltyId?: any,
        sentence?: string,
    }) {

        if (data) {
            this.actionOId = data.actionOId;
            this.actionDId = data.actionDId;
            this.resultId = data.resultId;
            this.penaltyId = data.penaltyId;
            this.sentence = data.sentence;
        }
    }  

    static fromServer(sentence: LogSentence): LogSentence {
        sentence = new LogSentence(sentence);
        return sentence;
    }
    
}