// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,//facebook pixel needs this to be true for events to fire
    googleMapsApiKey: 'AIzaSyB98PnXeE0zuNKB9TbUoRHScyZQyuxQ-l4',
    apiBase: 'https://dev-api.torontoflagfootball.com',
    // apiBase: 'http://localhost:8888',
    statsAppUrl: '//dev-stats.torontoflagfootball.com',
    leagueId: '1',
    stripeKey: 'pk_test_Gf9OBWf4hPO6BgRCdKeyIEFh',
    angulartics2developerMode: true, //affects facebook pixel, google
    isDriftOn: false, //whether drift is turned on
};
