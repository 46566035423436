import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { CreditCard } from '../../classes/tfflModels/creditCard';
import { HelperService } from '../util/helper.service';

@Injectable({ providedIn: 'root' })
export class StripeCustomerService {

    private url = '/stripe';

    constructor(
        private http: HttpClient,
        private helperService: HelperService,
    ) { }

    getCards(userId: any) {
        return this.http.get<{ sources: CreditCard[], stripeId: string }>(this.url + '/' + userId).pipe(
            map((response) => response.sources),
            map(this.extractCards),
            catchError(this.helperService.handleError('getCards', []))
        );
    }

    addCard(userId: any, sourceId: any) {
        let url = this.url + '/' + userId + '/actions/add-card';
        return this.http.post<{ card: CreditCard }>(url, { source: sourceId }).pipe(
            map((response) => response.card),
            map(card => CreditCard.fromServer(card)),
            catchError(this.helperService.handleError('add card', []))
        );
    }

    private extractCards(cards: CreditCard[]): CreditCard[] {
        return cards.map(card => CreditCard.fromServer(card));
    }
}
