import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Notification } from './../../classes/miscellaneous/notification';

@Injectable({ providedIn: 'root' })
export class NotificationService {

    private notifications: Notification<any>[] = [];
    private subject: Subject<Notification<any>[]>;

    constructor(
    ) {
        this.subject = new Subject();
    }

    getNotifications(): Observable<Notification<any>[]> {
        return this.subject;
    }

    push(notification: Notification<any>) {
        this.notifications.push(notification);
        this.subject.next(this.notifications);
    }

}
