import { User } from './user';

export class FriendRequest {
    
    id: any;
    registrationId: any;
    notes: string;
    friendUserId: any;
    friendRegistrationId: any;
    friendEmail: string;
    friendName: string;
    friendRequestType: FriendRequestType;
    numberOfEmailsSent: number;
    isCancelled: boolean;

    constructor(data?: { 
        id?: any,
        registrationId?: any,
        notes?: string,
        friendUserId?: any,
        friendRegistrationId?: any,
        friendEmail?: string,
        friendName?: string,
        friendRequestType?: FriendRequestType,
        numberOfEmailsSent?: number,
        isCancelled?: boolean, 
    }) {

        if (data) {
            this.id = data.id;
            this.registrationId = data.registrationId;
            this.notes = data.notes;
            this.friendUserId = data.friendUserId;
            this.friendRegistrationId = data.friendRegistrationId;
            this.friendEmail = data.friendEmail;
            this.friendName = data.friendName;
            this.friendRequestType = data.friendRequestType;
            this.numberOfEmailsSent = data.numberOfEmailsSent;
            this.isCancelled = data.isCancelled;
        }
    }  
    
    static fromServer(friendRequest: FriendRequest): FriendRequest {
        friendRequest = new FriendRequest(friendRequest);
        return friendRequest;
    }
    
    // /**
	//  * Adds a user to the friend request model.
	//  * @param user
	//  * @returns void
    //  * @throws Error - if the user does not exist in the request model
	//  */
	// removeFriendRequest(user): void {
	// 	let users = this.users;
	// 	for (let i = 0; i < users.length; i ++) {
	// 		if (users [i].id == user.id) {
	// 			users.splice(i, 1);
	// 			return;
	// 		} 
	// 	}
	// 	throw new Error('Error: Friend was not requested');
	// }
}

export enum FriendRequestType {
    COACH = 'COACH',
    FRIEND = 'FRIEND',
    PLAYER = 'PLAYER',
}