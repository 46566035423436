export class ColorHelper {

    constructor(
    ) {
    }

    /**
     * Returns either white or black depending on the color provided. 
     * Black or white is chosen based on some sort of color contrast theory.
     * You can also specify the alpha as a 2 character string - ex. FF.
     * 
     * @param bgColor 
     * @param alpha 
     */
    getTextColor(bgColor: string, alpha?: string): string {

        if (!bgColor) {
            throw new Error('Background color cannot be null or empty: ' + bgColor);
        }

        let color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, bgColor.length) : bgColor;
        let rgb = this.getRgb(color);
        let r = rgb.r;
        let g = rgb.g;
        let b = rgb.b;
        let uicolors = [r / 255, g / 255, b / 255];
        let c = uicolors.map((col) => {
            if (col <= 0.03928) {
                return col / 12.92;
            }
            return Math.pow((col + 0.055) / 1.055, 2.4);
        });
        let L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
        let result = (L > 0.179) ? '#000000' : '#ffffff';
        if (alpha) result += alpha;
        return result;
    }

    isWhite(hex: string): boolean {
        hex = (hex.charAt(0) === '#') ? hex.substring(1, hex.length) : hex;
        let rgb = this.getRgb(hex);
        return rgb.r > 250 && rgb.g > 250 && rgb.b > 250;
    }

    private getRgb(hex: string): { r: number, g: number, b: number } {

        let r, g, b;

        if (hex.length == 6) {
            r = parseInt(hex.substring(0, 2), 16); // hexToR
            g = parseInt(hex.substring(2, 4), 16); // hexToG
            b = parseInt(hex.substring(4, 6), 16); // hexToB
        } else if (hex.length == 3) {
            r = parseInt(hex.charAt(0) + hex.charAt(0), 16); // hexToR
            g = parseInt(hex.charAt(1) + hex.charAt(1), 16); // hexToG
            b = parseInt(hex.charAt(2) + hex.charAt(2), 16); // hexToB
        } else {
            throw new Error ('Cannot parse hex color: ' + hex);
        }

        return { r: r, g: g, b: b};
        
    } 
}