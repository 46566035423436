import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthInterceptor } from './services/other/auth-interceptor';
import { UrlBaseInterceptor } from './services/other/url-base.interceptor';
import { TfflCoreConfig, TFFL_CORE_CONFIG } from './tffl-core.config';

@NgModule({
    declarations: [],
    imports: [
        HttpClientModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UrlBaseInterceptor,
            multi: true
        },
    ]
})
export class TfflCoreModule {
    static forRoot(config: TfflCoreConfig): ModuleWithProviders {
        return {
            ngModule: TfflCoreModule,
            providers: [
                {
                    provide: TFFL_CORE_CONFIG,
                    useValue: config
                }
            ]
        }
    }
}

