import * as momentImported from 'moment';
import { Address } from './address';
import { ProgramDay } from './programDay';
import { Season } from './seasons';
import { DateHelper } from '../util/dateHelper';

const moment = momentImported;

export class Program {
    id: any;
    name: string;
    /**
     * minBirthdate is the later year of minBirthdate and maxBirthdate. Put another way, 
     * the min birthdate signifies the birthdate of the minimum age that is allowed,
     * and the max birthdate signifies the birthdate of the maximum age that is allowed
     */
    minBirthdate: Date;
    maxBirthdate: Date;
    startDate: Date;
    endDate: Date;
    numberOfSessions: string;
    sessionDescription: string;
    teamPrice: number;
    individualPrice: number;
    leagueId: any;
    seasonId: any;
    season: Season;
    locations: Address[];
    leagueName: string;
    programDays: ProgramDay[];
    programTypeName: string;
    playerCapacity: number;
    registrationCount: number;
    order: number;
    
    private _programTypeId: ProgramType;
    
    get programTypeId(): ProgramType {
        return this._programTypeId;
    }
    
    set programTypeId(type: ProgramType) {
        this._programTypeId = type;
        this.setProgramTypeName(type);
    }

    /**
     * minBirthdate is the later year of minBirthdate and maxBirthdate. Put another way, 
     * the min birthdate signifies the birthdate of the minimum age that is allowed,
     * and the max birthdate signifies the birthdate of the maximum age that is allowed
     */
    get minAge(): number {
        return (this.minBirthdate) ? new Date().getFullYear() - this.minBirthdate.getFullYear() : null;
    }

    get maxAge(): number {
        return (this.maxBirthdate) ? new Date().getFullYear() - this.maxBirthdate.getFullYear() : null;
    }
    
    get spotsLeft(): number {
        return this.playerCapacity ? this.playerCapacity - this.registrationCount : 0;
    }

    get isOneDayTournament(): boolean {
        return DateHelper.isSameDate(this.startDate, this.endDate);
    }

    /**
     * This funciton is not used anymore - it was useful when there were different pages for adult and children registrations
     */
    // get registrationLink(): string {
    //     if (this.isAdultLeague()) {
    //         return '/registration/adults/' + this.id;
    //     } else {
    //         return '/registration/children/' + this.id;
    //     }
    // }

    /**
     * Deprecated because ages are confusing
     */
    // get ageString(): string {
    //     return this.maxAge ? this.minAge + '-' + this.maxAge : this.minAge + '+';
    // }

    /**
     * This assumes that minBirthdate will always be set, but maxBirthdate may not. For example, one program might
     * allow birthdates between the years 2012 and 2014, and another may allow all birthdates after the year
     * 2001.
     */
    get ageYearsString(): string {
        let newerYear = this.minBirthdate ? moment.utc(this.minBirthdate).format("Y") : null;
        let olderYear = this.maxBirthdate ? moment.utc(this.maxBirthdate).format("Y") : null;
        
        if (newerYear == olderYear) {
            return newerYear;
        } else if (olderYear) {
            return olderYear + '-' + newerYear;
        } else {
            return newerYear + '+';
        }
    }

    /**
     * This assumes that minBirthdate will always be set, but maxBirthdate may not. For example, one program might
     * allow birthdates between the years 2012 and 2014, and another may allow all birthdates after the year
     * 2001.
     */
    get ageYearsSentence(): string {
        let newerYear = this.minBirthdate ? moment.utc(this.minBirthdate).format("Y") : null;
        let olderYear = this.maxBirthdate ? moment.utc(this.maxBirthdate).format("Y") : null;
        
        if (newerYear == olderYear) {
            return 'Birth Year: ' + newerYear;
        } else if (olderYear) {
            return 'Birth Years: ' + olderYear + '-' + newerYear;
        } else {
            return 'Birth Years: ' + newerYear + '+';
        }
    }

    isAdultLeague(): boolean {
        return this.programTypeId == ProgramType.ADULT;
    }

    isValidBirthdate(d: Date): boolean {

        if (!d) return false;

        if (this.minBirthdate && this.maxBirthdate) {
            return this.maxBirthdate <= d && this.minBirthdate >= d;
        } else if (this.minBirthdate) {
            return this.minBirthdate >= d;
        } else {
            console.error(this);
            throw new Error('this program has an invalid date');
        }
    }

    // if (!d) return false;

    //     try {

    //         /** Wrap this in a try catch, because if there is
    //          * an error because the date is invalid, we want to
    //          * return false
    //          */
    //         if (this.minBirthdate && this.maxBirthdate) {
    //             return this.maxBirthdate <= d && this.minBirthdate >= d;
    //         } else if (this.minBirthdate) {
    //             return this.minBirthdate >= d;
    //         }
    //     } catch (e) {
    //         return false;
    //     }

    //     /**
    //      * We shouldn't get here. if we do throw an error
    //      */
    //     console.error(this);
    //     throw new Error('this program has an invalid date');

    constructor(data?: {
        id?: any,
        name?: string,
        programTypeId?: ProgramType,
        playerCapacity?: number,
        registrationCount?: number,
        minBirthdate?: Date,
        maxBirthdate?: Date,
        startDate?: Date,
        endDate?: Date,
        numberOfSessions?: string,
        sessionDescription?: string,
        teamPrice?: number,
        individualPrice?: number,
        leagueId?: any,
        seasonId?: any,
        season?: Season,
        locations?: Address[],
        leagueName?: string,
        programDays?: ProgramDay[],
        order?: number,
    }) {

        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.programTypeId = data.programTypeId;
            this.playerCapacity = data.playerCapacity;
            this.registrationCount = data.registrationCount;
            this.minBirthdate = data.minBirthdate;
            this.maxBirthdate = data.maxBirthdate;
            this.startDate = data.startDate;
            this.endDate = data.endDate;
            this.numberOfSessions = data.numberOfSessions;
            this.sessionDescription = data.sessionDescription;
            this.teamPrice = data.teamPrice;
            this.individualPrice = data.individualPrice;
            this.leagueId = data.leagueId;
            this.seasonId = data.seasonId;
            this.season = data.season;
            this.locations = data.locations;
            this.leagueName = data.leagueName;
            this.programDays = data.programDays;
            this.order = data.order;
        }
    }

    static fromServer(program: Program): Program {
        //Convert the birthdate timestamp to a Date object
        program.minBirthdate = program.minBirthdate ? new Date(program.minBirthdate) : null;
        program.maxBirthdate = program.maxBirthdate ? new Date(program.maxBirthdate) : null;
        program.startDate = program.startDate ? new Date(program.startDate) : null;
        program.endDate = program.endDate ? new Date(program.endDate) : null;

        program.playerCapacity = +program.playerCapacity;
        program.registrationCount = +program.registrationCount;
        program.order = +program.order;
        program.teamPrice = +program.teamPrice;
        program.individualPrice = +program.individualPrice;

        program.season = program.season ? Season.fromServer(program.season) : null;

        if (program.locations) {
            for (let i = 0; i < program.locations.length; i++) {
                program.locations[i] = Address.fromServer(program.locations[i]);
            }
        }

        if (program.programDays) {
            for (let i = 0; i < program.programDays.length; i++) {
                program.programDays[i] = ProgramDay.fromServer(program.programDays[i]);
            }
        }

        return new Program(program);
    }

    private setProgramTypeName(type: ProgramType) {
        switch (type) {
            case ProgramType.ADULT:
                this.programTypeName = 'Adult League';
                break;
            case ProgramType.CHILD:
                this.programTypeName = 'Youth League';
                break;
            case ProgramType.GIRLS:
                this.programTypeName = 'Girls League';
                break;
            case ProgramType.LEARN:
                this.programTypeName = 'Learn to Play League';
                break;
        }
    }
}

export enum ProgramType {
    ADULT = 'ADULT',
    CHILD = 'CHILD',
    GIRLS = 'GIRLS',
    LEARN = 'LEARN',
}