import { PRIMARY_COLOR } from '../deleteThis/colorScheme';
import { Registration } from "../tfflModels/registration";
import { ColorHelper } from '../util/colorHelper';

export class PlayerButton {

    registration: Registration;
    state: States;
    side: Sides;
    colors: {
        inactiveBackground: string,
        activeBackground: string,
        inactiveText: string,
        activeText: string,
    }
    private _colorScheme: 'dark-light' | 'light';

    set colorScheme(colorScheme: 'dark-light' | 'light') {
        this._colorScheme = colorScheme;
        this.updateColors();
    }

    constructor(data?: {
        registration?: Registration,
        state?: States,
        side?: Sides,
        colorScheme?: 'dark-light' | 'light',
    }) {
        if (data) {
            this.registration = data.registration;
            this.state = data.state;
            this.side = data.side;
            this.colorScheme = data.colorScheme ? data.colorScheme : 'dark-light';
        }
    }

    updateColors() {

        let scheme = this._colorScheme;
        let dark = '#343a40';
        let white = '#fff';
        let teamColor = (this.registration ? this.registration.team.color : null) || PRIMARY_COLOR;

        switch (scheme) {
            case 'dark-light':
                this.colors = {
                    inactiveBackground: dark,
                    inactiveText: white,
                    activeBackground: teamColor,
                    activeText: new ColorHelper().getTextColor(teamColor),
                };
                break;
            case 'light':
                this.colors = {
                    inactiveBackground: teamColor,
                    inactiveText: new ColorHelper().getTextColor(teamColor),
                    activeBackground: dark,
                    activeText: white
                };
                break;
        }

    }

    getBackgroundColor(): string {
        return this.state ? this.colors.activeBackground : this.colors.inactiveBackground;
    }

    getTextColor(): string {
        return this.state ? this.colors.activeText : this.colors.inactiveText;
    }

}

export enum States {
    QB = 'QB',
    R = 'R',
    Lat = 'Lat',
    FP = 'FP',
    D = 'D',
    K = 'K',
    RT = 'RT',
    Int = 'Int',
}

export enum Sides {
    OFFENSE = 'Offense',
    DEFENSE = 'Defense',
}

export enum Actions {
    COMPLETION = 'Completion',
    RUN = 'Run',
    INCOMPLETION = 'Incompletion',
    INTERCEPTION = 'Interception',
    KICK = 'Kick',
    PUNT = 'Punt',
}