import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Game } from '../../classes/tfflModels/game';
import { LogSentence } from '../../classes/tfflModels/logSentence';
import { PlayLog } from '../../classes/tfflModels/playLog';
import { HelperService } from '../util/helper.service';

@Injectable({ providedIn: 'root' })
export class LogsService {

    private logsUrl = '/logs';
    private sentencesUrl = '/logs/sentences';
    private sentencesSubject: ReplaySubject<{ [key: string]: LogSentence }>;

    constructor(
        private http: HttpClient,
        private helperService: HelperService,
    ) { }

    getLogs(gameId: any): Observable<PlayLog[]> {
        return this.http.get<{ logs: PlayLog[], game: Game }>('/games/' + gameId + '/logs')
            .pipe(
                map(this.extractLogs), /* IMPORTANT */
                catchError(this.helperService.handleError('getLogs', []))
            )
    }

    saveLogs(logs: PlayLog[]): Observable<PlayLog[]> {
        let data = { logs: logs.map(log => log.toServer()) };
        return this.http.post<{ logs: PlayLog[] }>(this.logsUrl, data).pipe(
            map(result => result.logs),
            catchError(this.helperService.handleError('saveLogs', null))
        );
        // return of(logs);
    }

    /**
     * @param logs - The list of logs related to a single play
     */
    getSentence(logs: PlayLog[]): Observable<string> {

        let subject = new BehaviorSubject<string>(null);

        this.getLogSentences().subscribe((sentenceMap) => {
            let sentence = '';

            for (let i = 0; i < logs.length; i++) {
                let hash = logs[i].computeHash();
                let logSentence = sentenceMap[hash] ? sentenceMap[hash].sentence : '?';
                sentence += logs[i].getSentence(logSentence) + ', ';
            }

            //replace ", " followed by any not ", " pattern with the empty string
            //i.e. remove the last occurence of ', '
            sentence = sentence.replace(/, (?![\s\S]*, )/, '');

            subject.next(sentence);
        });

        return subject;
    }

    /* This converts the json response from the server to properly instantiated instances of the class */
    private extractLogs(response: { logs: PlayLog[] }): PlayLog[] {

        let logs = response.logs;

        for (let i = 0; i < logs.length; i++) {
            logs[i] = PlayLog.fromServer(logs[i]);
        }

        return logs;
    }

    /* This converts the json response from the server to properly instantiated instances of the class */
    private extractSentences(sentences: LogSentence[]): { [key: string]: LogSentence } {
        let response = {};
        for (let i = 0; i < sentences.length; i++) {
            let logSentence = LogSentence.fromServer(sentences[i]);
            response[logSentence.key] = logSentence;
        }

        return response;
    }

    private getLogSentences(): Observable<{ [key: string]: LogSentence }> {

        if (this.sentencesSubject) {
            return this.sentencesSubject;
        }

        this.sentencesSubject = new ReplaySubject<{ [key: string]: LogSentence }>();

        this.http.get<{ sentences: LogSentence[] }>(this.sentencesUrl).pipe(
            map(result => result.sentences),
            map(this.extractSentences),
            catchError(this.helperService.handleError('getSentences', null))
        ).subscribe(result => {
            this.sentencesSubject.next(result);
        });

        return this.sentencesSubject;
    }
}