export enum RESULTS {
    DROP_LAT = 'Dropped Lateral',
    FIRST = '1st',
    INC = 'Incompletion',
    KICK = 'Kick',
    LOSS = 'Loss/Sack',
    PT1_GOOD = 'Automatic 1 Point Conversion',
    PT2_FAIL = 'Failed 2 Point Conversion',
    PT2_GOOD = 'Successful 2 Point Conversion',
    PUNT = 'Punt',
    SAFETY = 'Safety',
    SHORT = 'Short Yards',
    TD = 'TD',
    TO = 'Turnover',
    SS = 'Switch Sides',
    CORRECTION = 'Correction',
    COIN_TOSS = 'Coin Toss',
    PICK_SIDE = 'Choose Side',
    PICK_BALL = 'Choose to Receive',
    PICK_DEFER = 'Choose to Defer'
}