export enum ACTIONS {
    DEFL = 'Deflection',
    FLAG_PULL = 'Flag Pull',
    HANDOFF = 'Handoff',
    INT = 'Interception',
    KICK = 'Kick',
    KICK_RT = 'Kick Return',
    LATERAL = 'Lateral',
    PUNT = 'Punt',
    PUNT_RT = 'Punt Return',
    RUN = 'Run',
    THROW = 'Throw',
}