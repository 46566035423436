import { Injectable } from '@angular/core';
import { League } from '@tffl/core';
import { ReplaySubject } from 'rxjs';

interface LeagueData {
    league: League;
}

@Injectable({
    providedIn: 'root'
})
export class LeagueWaiverService {

    private dataSubject = new ReplaySubject<LeagueData>(1);

    get leagueInfo() {
        return this.dataSubject.asObservable();
    }

    constructor() { }

    setData(data: LeagueData) {
        console.log('setting league data', data);
        this.dataSubject.next(data);
    }
}
