/**
 * This class is used to load specific sponsors
 */
export class SponsorsFilter {

    userIds: any [];
    registrationIds: any [];
    teamIds: any[];

    constructor(data?: {
        userIds: any [], 
        registrationIds: any [], 
        teamIds: any [], 
    }) {

        if (data) {
            this.userIds = data.userIds;
            this.registrationIds = data.registrationIds;
            this.teamIds = data.teamIds;
        }
    }

    getQuery() {

        let params = new URLSearchParams();

        if (this.teamIds && this.teamIds.length) {
            this.teamIds.forEach(id => params.append('team_ids[]', id));        
        }

        if (this.userIds && this.userIds.length) {
            this.userIds.forEach(id => params.append('user_ids[]', id));        
        }

        if (this.registrationIds && this.registrationIds.length) {
            this.registrationIds.forEach(id => params.append('registration_ids[]', id));        
        }

        return params.toString();
    }
}