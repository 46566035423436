import { NgbDatepickerConfig, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as momentImported from 'moment';
const moment = momentImported;

export interface DateInputOptions {
    suggestedAge?: number,
    minAge?: number,
    maxAge?: number
}

export class DateHelper {

    static setDatepickerConfig(options: DateInputOptions, datepickerConfig: NgbDatepickerConfig) {

        let currentDate = new Date();

        if (!options) {
            return;
        }

        if (options.suggestedAge) {
            datepickerConfig.startDate = {
                year: currentDate.getFullYear() - options.suggestedAge,
                month: currentDate.getMonth() + 1
            };
        }

        if (options.minAge) {
            datepickerConfig.maxDate = {
                year: currentDate.getFullYear() - options.minAge,
                month: currentDate.getMonth() + 1,
                day: currentDate.getDate()
            };
        }

        if (options.maxAge) {
            datepickerConfig.minDate = {
                year: currentDate.getFullYear() - options.maxAge,
                month: currentDate.getMonth() + 1,
                day: currentDate.getDate()
            };
        }
    }

    static toDate(theDate: NgbDateStruct): Date {
        return !theDate ? null :
            new Date(
                theDate.year,
                theDate.month - 1,
                theDate.day
            );
    }

    static toNgbDateStruct(theDate: Date): NgbDateStruct {
        return !theDate ? null :
            {
                year: theDate.getUTCFullYear(),
                month: theDate.getUTCMonth() + 1,
                day: theDate.getUTCDate()
            };
    }

    static formatDateYYYYMMDD(d: Date): string {
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    static isSameDate(d1: Date, d2: Date) {
        return d1.getFullYear() == d2.getFullYear() &&
            d1.getMonth() == d2.getMonth() &&
            d1.getDate() == d2.getDate();
    }

    static fromStringYYYYMMDD(d: string) {
        if (!d) {
            throw new Error('cannot create instance from null date string');
        }

        let momentDate = moment(d);
        if (momentDate.isValid()) {
            return momentDate.toDate();
        }

        return null;
    }

    /**
     * Returns whether the futureDate provided is from a future date
     * compared to the pastDate
     */
    static isFutureDate(pastDate: Date, futureDate: Date) {
        return !DateHelper.isSameDate(pastDate, futureDate) &&
            pastDate < futureDate;
    }

    static isValidDate(d: any) {
        return !isNaN(d) && d instanceof Date;
    }

    static todayAtMidnight(): Date {
        let d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    }
}