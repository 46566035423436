import { Game } from "./game";

interface HeadToHeadStanding {
    wins: number,
    losses: number,
    pointsFor: number,
    pointsAgainst: number
}

export class TeamStanding {
    teamId: any;
    programId: any;
    divisionId: any;
    seasonId: any;
    teamName: string;
    teamLogoUrl: string;
    programName: string;
    divisionName: string;
    isAdult: boolean
    standingPoints: number;
    standingPointsPerGame: number;
    pointsPerGame: number;
    pointDifferential: number;
    sentence: string;
    sentenceWithoutProgram: string;
    shortSentence: string;

    /**
     * This stores the head to head wins this team has against other teams
     */
    headToHead: { [otherTeamId: number]: HeadToHeadStanding };

    get wins(): number { return this._wins; }
    get losses(): number { return this._losses; }
    get ties(): number { return this._ties; }
    get sportsmanshipPoints(): number { return this._sportsmanshipPoints; }
    get gamesPlayed(): number { return this._gamesPlayed; }
    get pointsFor(): number { return this._pointsFor; }
    get pointsAgainst(): number { return this._pointsAgainst; }
    get place(): number { return this._place; }

    get programDivisionTitle(): string {
        let title = this.programName;
        if (this.divisionName) {
            title += ' ' + this.divisionName;
        }
        return title;
    }

    set wins(w: number) {
        this._wins = w;
        this.updateFields();
    }
    set losses(l: number) {
        this._losses = l;
        this.updateFields();
    }
    set ties(t: number) {
        this._ties = t;
        this.updateFields();
    }
    set sportsmanshipPoints(s: number) {
        this._sportsmanshipPoints = s;
        this.updateFields();
    }
    set gamesPlayed(g: number) {
        this._gamesPlayed = g;
        this.updateFields();
    }
    set pointsFor(p: number) {
        this._pointsFor = p;
        this.updateFields();
    }
    set pointsAgainst(p: number) {
        this._pointsAgainst = p;
        this.updateFields();
    }
    set place(p: number) {
        this._place = p;
        this.updateFields();
    }

    private _wins: number = 0;
    private _losses: number = 0;
    private _ties: number = 0;
    private _sportsmanshipPoints: number = 0;
    private _gamesPlayed: number = 0;
    private _pointsFor: number = 0;
    private _pointsAgainst: number = 0;
    private _place: number;

    constructor(data?: {
        teamId?: any,
        programId?: any,
        divisionId?: any,
        seasonId?: any,
        teamName?: string,
        teamLogoUrl?: string,
        programName?: string,
        divisionName?: string,
        isAdult: boolean,
        wins?: number,
        losses?: number,
        ties?: number,
        sportsmanshipPoints?: number,
        gamesPlayed?: number,
        place?: number,
        headToHead?: { [otherTeamId: number]: HeadToHeadStanding },
    }) {

        if (data) {
            this.teamId = data.teamId;
            this.programId = data.programId;
            this.divisionId = data.divisionId;
            this.seasonId = data.seasonId;
            this.teamName = data.teamName;
            this.teamLogoUrl = data.teamLogoUrl;
            this.programName = data.programName;
            this.divisionName = data.divisionName;
            this.isAdult = data.isAdult || false;
            this.wins = data.wins || 0;
            this.losses = data.losses || 0;
            this.ties = data.ties || 0;
            this.sportsmanshipPoints = data.sportsmanshipPoints || 0;
            this.gamesPlayed = data.gamesPlayed || 0;
            this.place = data.place;
            this.headToHead = data.headToHead || null;
        }

        if (!this.headToHead) {
            this.headToHead = {};
        }
    }

    addGame(game: Game) {

        if (!game.isComplete) {
            console.error('Game: ', game);
            throw new Error('Game is not yet complete, so cannot updating standings');
        }

        this.gamesPlayed++;

        if (!this.isAdult) {
            this.sportsmanshipPoints += game.getSportsmanship(this.teamId);
        }

        let otherTeamId = game.getOtherTeamId(this.teamId);

        /**
         * Add the other team to the head to head wins map
         */
        if (!(otherTeamId in this.headToHead)) {
            this.headToHead[otherTeamId] = TeamStanding.newHeadToHead();
        }

        if (game.winningTeam && game.winningTeam.id == this.teamId) {
            this.wins++;
            this.headToHead[otherTeamId].wins++;
        } else if (game.isComplete && game.isTie) {
            this.ties++;
        } else {
            this.losses++;
            this.headToHead[otherTeamId].losses++;
        }

        if (game.homeTeamId == this.teamId) {
            this.pointsFor += game.homeScore;
            this.pointsAgainst += game.awayScore;
            this.headToHead[otherTeamId].pointsFor += game.homeScore;
            this.headToHead[otherTeamId].pointsAgainst += game.awayScore;
        } else {
            this.pointsFor += game.awayScore;
            this.pointsAgainst += game.homeScore;
            this.headToHead[otherTeamId].pointsFor += game.awayScore;
            this.headToHead[otherTeamId].pointsAgainst += game.homeScore;
        }
    }

    static newHeadToHead(): HeadToHeadStanding {
        return {
            wins: 0,
            losses: 0,
            pointsFor: 0,
            pointsAgainst: 0,
        }
    }

    private updateFields() {
        this.standingPoints =
            this.wins * 2 +
            this.ties * 1;

        if (!this.isAdult) {
            this.sportsmanshipPoints * 1;
        }

        this.pointDifferential = this.pointsFor - this.pointsAgainst;

        if (this.gamesPlayed > 0) {
            this.standingPointsPerGame = (this.standingPoints + 0.0) / this.gamesPlayed;
            this.pointsPerGame = (this.pointsFor + 0.0) / this.gamesPlayed;
        } else {
            this.standingPointsPerGame = 0;
            this.pointsPerGame = 0;
        }

        this.calculateSentence();
    }

    private calculateSentence() {
        if (!this.place) {
            this.shortSentence = '';
            this.sentence = '';
            return;
        }

        let placeString = this.ordinal(this.place);

        this.shortSentence =
            this.wins + '-' +
            this.losses + '-' +
            this.ties;

        this.sentenceWithoutProgram = this.shortSentence +
            ' (' + placeString + ' in division)';

        this.sentence = this.shortSentence +
            ' (' + placeString + ' in ' + this.programDivisionTitle + ')';


    }

    private ordinal(n: number): string {
        const ones = +n % 10, tens = +n % 100 - ones;
        return n + ["th", "st", "nd", "rd"][tens === 10 || ones > 3 ? 0 : ones];
    }
}