import { TimeHelper } from '../util/timeHelper';

export class GameClock {
    minutes: number;
    seconds: number;
    milliseconds: number;

    private _time: number;//total time

    set time(milliseconds: number) {
        this.minutes = TimeHelper.getMinutesFloor(milliseconds);
        this.seconds = TimeHelper.getSecondsFloor(milliseconds);
        this.milliseconds = TimeHelper.getMillisecondsFloor(milliseconds);
    }

    get time(): number {
        return this.minutes * 60000 + this.seconds * 1000 + this.milliseconds;
    }

    constructor(data?: {
        minutes?: number,
        seconds?: number,
        milliseconds?: number,
        time?: number,
    }) {

        if (data) {
            if (data.time || data.time === 0) {
                this.time = data.time;
            } else {
                this.minutes = data.minutes;
                this.seconds = data.seconds;
                this.milliseconds = data.milliseconds;
            }
        }
    }

}