import { Game } from '../tfflModels/game';
import { LeagueFilter } from './LeagueFilter';

export class GamesListFilter {
    id: any;
    leagueFilter: LeagueFilter;
    allGames: Game[] = [];
    date: Date;

    constructor() {
        this.leagueFilter = new LeagueFilter();
    }

    getFiltered(): Game[] {

        let teams = this.leagueFilter.getImpliedTeams();

        let filtered = this.allGames.filter(game => {
            return Game.isMatch({ date: this.date, game: game, teams })
        });

        return filtered;
    }

    getAllFiltered(): Game[] {

        let teams = this.leagueFilter.getImpliedTeams();

        let filtered = this.allGames.filter(game => {
            return Game.isMatch({ date: null, game: game, teams })
        });

        return filtered;
    }

    getEarliestDate(): Date {
        if (!this.allGames || !this.allGames.length) return null;
        return this.allGames[0].startTime;
    }

    getLatestDate(): Date {
        if (!this.allGames || !this.allGames.length) return null;
        return this.allGames[this.allGames.length - 1].startTime;
    }

    getDays(): Date[] {
        let games = this.getFilteredForAllDays();

        let days = Game.getGameDays(games);
        return days;
    }

    private getFilteredForAllDays(): Game[] {
        let teams = this.leagueFilter.getImpliedTeams();
        return this.allGames.filter(g => Game.isMatch({ date: null, game: g, teams }));
    }
}