import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BlogPost } from '../../classes/tfflModels/blog-post';
import { HelperService } from '../util/helper.service';

@Injectable({
    providedIn: 'root'
})
export class BlogService {

    private url: string = '/news';

    constructor(
        private http: HttpClient,
        private helperService: HelperService,
    ) { }

    //   getFeedContent(url: string): Observable<Feed> {
    //     return this.http.get(this.rssToJsonServiceBaseUrl + url)
    //             .map(this.extractFeeds)
    //             .catch(this.handleError);
    //   }
    getPosts(limit: number = 3): Observable<BlogPost[]> {
        return this.http.get<{ news: BlogPost[] }>(this.url).pipe(
            map(response => response.news),
            map(posts => posts.slice(0, limit)),
            map(this.extractFeeds),
            catchError(this.helperService.handleError('login', []))
        );
    }

    private extractFeeds(news: BlogPost[]): BlogPost[] {
        return news.map(item => BlogPost.fromServer(item));
    }
}
