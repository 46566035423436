export class PlayerStats {
    id: any;
    userId: any;
    registrationId: any;
    registrationName: string;
    registrationNumber: string;
    gameId: any;
    seasonId: any;
    programId: any;
    teamId: any;
    teamName: string;
    teamShortName: string;
    teamLogoUrl: string;
    flagPulls: number = 0;
    sacks: number = 0;
    deflections: number = 0;
    interceptions: number = 0;
    interceptionTds: number = 0;
    completions: number = 0;
    completionFirsts: number = 0;
    completionTds: number = 0;
    completionYards: number = 0;
    receptions: number = 0;
    receptionFirsts: number = 0;
    receptionTds: number = 0;
    receptionYards: number = 0;
    rushes: number = 0;
    rushFirsts: number = 0;
    rushTds: number = 0;
    rushYards: number = 0;
    kickReturns: number = 0;
    kickReturnTds: number = 0;
    kickReturnYards: number = 0;
    kicks: number = 0;
    punts: number = 0;
    gamesPlayed: number = 1;

    constructor(data?: {
        id?: any,
        userId?: any,
        registrationId?: any,
        registrationName?: string,
        registrationNumber?: string,
        gameId?: any,
        seasonId?: any,
        programId?: any,
        teamId?: any,
        teamName?: string,
        teamShortName?: string,
        teamLogoUrl?: string,
        flagPulls?: number,
        sacks?: number,
        deflections?: number,
        interceptions?: number,
        interceptionTds?: number,
        completions?: number,
        completionFirsts?: number,
        completionTds?: number,
        completionYards?: number,
        receptions?: number,
        receptionFirsts?: number,
        receptionTds?: number,
        receptionYards?: number,
        rushes?: number,
        rushFirsts?: number,
        rushTds?: number,
        rushYards?: number,
        kickReturns?: number,
        kickReturnTds?: number,
        kickReturnYards?: number,
        kicks?: number,
        punts?: number,
        gamesPlayed?: number,
    }) {
        if (data) {
            this.id = data.id;
            this.userId = data.userId;
            this.registrationId = data.registrationId;
            this.registrationName = data.registrationName;
            this.registrationNumber = data.registrationNumber;
            this.gameId = data.gameId;
            this.seasonId = data.seasonId;
            this.programId = data.programId;
            this.teamId = data.teamId;
            this.teamName = data.teamName;
            this.teamShortName = data.teamShortName;
            this.teamLogoUrl = data.teamLogoUrl;
            this.flagPulls = data.flagPulls || 0;
            this.sacks = data.sacks || 0;
            this.deflections = data.deflections || 0;
            this.interceptions = data.interceptions || 0;
            this.interceptionTds = data.interceptionTds || 0;
            this.completions = data.completions || 0;
            this.completionFirsts = data.completionFirsts || 0;
            this.completionTds = data.completionTds || 0;
            this.completionYards = data.completionYards || 0;
            this.receptions = data.receptions || 0;
            this.receptionFirsts = data.receptionFirsts || 0;
            this.receptionTds = data.receptionTds || 0;
            this.receptionYards = data.receptionYards || 0;
            this.rushes = data.rushes || 0;
            this.rushFirsts = data.rushFirsts || 0;
            this.rushTds = data.rushTds || 0;
            this.rushYards = data.rushYards || 0;
            this.kickReturns = data.kickReturns || 0;
            this.kickReturnTds = data.kickReturnTds || 0;
            this.kickReturnYards = data.kickReturnYards || 0;
            this.kicks = data.kicks || 0;
            this.punts = data.punts || 0;
            this.gamesPlayed = data.gamesPlayed || 1;
        }
    }

    combine(otherStats: PlayerStats): PlayerStats {
        let combined = new PlayerStats({
            userId: this.userId,
            registrationId: this.registrationId,
            registrationName: this.registrationName,
            registrationNumber: this.registrationNumber,
            gameId: this.gameId,
            seasonId: this.seasonId,
            programId: this.programId,
            teamId: this.teamId,
            teamName: this.teamName,
            teamShortName: this.teamShortName,
            teamLogoUrl: this.teamLogoUrl,
        });

        combined.flagPulls = this.flagPulls + otherStats.flagPulls;
        combined.sacks = this.sacks + otherStats.sacks;
        combined.deflections = this.deflections + otherStats.deflections;
        combined.interceptions = this.interceptions + otherStats.interceptions;
        combined.interceptionTds = this.interceptionTds + otherStats.interceptionTds;
        combined.completions = this.completions + otherStats.completions;
        combined.completionFirsts = this.completionFirsts + otherStats.completionFirsts;
        combined.completionTds = this.completionTds + otherStats.completionTds;
        combined.completionYards = this.completionYards + otherStats.completionYards;
        combined.receptions = this.receptions + otherStats.receptions;
        combined.receptionFirsts = this.receptionFirsts + otherStats.receptionFirsts;
        combined.receptionTds = this.receptionTds + otherStats.receptionTds;
        combined.receptionYards = this.receptionYards + otherStats.receptionYards;
        combined.rushes = this.rushes + otherStats.rushes;
        combined.rushFirsts = this.rushFirsts + otherStats.rushFirsts;
        combined.rushTds = this.rushTds + otherStats.rushTds;
        combined.rushYards = this.rushYards + otherStats.rushYards;
        combined.kickReturns = this.kickReturns + otherStats.kickReturns;
        combined.kickReturnTds = this.kickReturnTds + otherStats.kickReturnTds;
        combined.kickReturnYards = this.kickReturnYards + otherStats.kickReturnYards;
        combined.kicks = this.kicks + otherStats.kicks;
        combined.punts = this.punts + otherStats.punts;
        combined.gamesPlayed = this.gamesPlayed + 1;

        return combined;
    }

    toServer(): {} {

        let stats: any = {
            id: this.id,
            registrationId: this.registrationId,
            gameId: this.gameId,
            seasonId: this.seasonId,
        };

        /**
         * Only add non-zero stats to save data
         */
        if (this.flagPulls) stats.flagPulls = this.flagPulls;
        if (this.sacks) stats.sacks = this.sacks;
        if (this.deflections) stats.deflections = this.deflections;
        if (this.interceptions) stats.interceptions = this.interceptions;
        if (this.interceptionTds) stats.interceptionTds = this.interceptionTds;
        if (this.completions) stats.completions = this.completions;
        if (this.completionFirsts) stats.completionFirsts = this.completionFirsts;
        if (this.completionTds) stats.completionTds = this.completionTds;
        if (this.completionYards) stats.completionYards = this.completionYards;
        if (this.receptions) stats.receptions = this.receptions;
        if (this.receptionFirsts) stats.receptionFirsts = this.receptionFirsts;
        if (this.receptionTds) stats.receptionTds = this.receptionTds;
        if (this.receptionYards) stats.receptionYards = this.receptionYards;
        if (this.rushes) stats.rushes = this.rushes;
        if (this.rushFirsts) stats.rushFirsts = this.rushFirsts;
        if (this.rushTds) stats.rushTds = this.rushTds;
        if (this.rushYards) stats.rushYards = this.rushYards;
        if (this.kickReturns) stats.kickReturns = this.kickReturns;
        if (this.kickReturnTds) stats.kickReturnTds = this.kickReturnTds;
        if (this.kickReturnYards) stats.kickReturnYards = this.kickReturnYards;
        if (this.kicks) stats.kicks = this.kicks;
        if (this.punts) stats.punts = this.punts;

        return stats;
    }

    static fromServer(stats: PlayerStats): PlayerStats {

        stats.flagPulls = stats.flagPulls ? +stats.flagPulls : 0;
        stats.sacks = stats.sacks ? +stats.sacks : 0;
        stats.deflections = stats.deflections ? +stats.deflections : 0;
        stats.interceptions = stats.interceptions ? +stats.interceptions : 0;
        stats.interceptionTds = stats.interceptionTds ? +stats.interceptionTds : 0;
        stats.completions = stats.completions ? +stats.completions : 0;
        stats.completionFirsts = stats.completionFirsts ? +stats.completionFirsts : 0;
        stats.completionTds = stats.completionTds ? +stats.completionTds : 0;
        stats.completionYards = stats.completionYards ? +stats.completionYards : 0;
        stats.receptions = stats.receptions ? +stats.receptions : 0;
        stats.receptionFirsts = stats.receptionFirsts ? +stats.receptionFirsts : 0;
        stats.receptionTds = stats.receptionTds ? +stats.receptionTds : 0;
        stats.receptionYards = stats.receptionYards ? +stats.receptionYards : 0;
        stats.rushes = stats.rushes ? +stats.rushes : 0;
        stats.rushFirsts = stats.rushFirsts ? +stats.rushFirsts : 0;
        stats.rushTds = stats.rushTds ? +stats.rushTds : 0;
        stats.rushYards = stats.rushYards ? +stats.rushYards : 0;
        stats.kickReturns = stats.kickReturns ? +stats.kickReturns : 0;
        stats.kickReturnTds = stats.kickReturnTds ? +stats.kickReturnTds : 0;
        stats.kickReturnYards = stats.kickReturnYards ? +stats.kickReturnYards : 0;
        stats.kicks = stats.kicks ? +stats.kicks : 0;
        stats.punts = stats.punts ? +stats.punts : 0;
        stats = new PlayerStats(stats);

        return stats;
    }
}