
import { ACTIONS, PENALTIES, RESULTS } from '../constants/stats';
import { TimeHelper } from '../util/timeHelper';
import { Game } from "./game";
import { Registration } from "./registration";
import { Team } from './team';

export class PlayLog {
    id: any;
    gameId: number;
    half: number;
    time: number;
    down: number;
    playNumber: any;
    penalty: any;
    distance: any;
    playerQId: any;
    playerRId: any;
    playerDId: any;
    teamId: any;
    actionO: any;
    actionD: any;
    result: any;
    game: Game;
    homeScore: number;
    awayScore: number;

    get playerQ(): Registration {
        try {
            return this.getPlayer(this.playerQId);
        } catch (e) {
            return null;
        }
    }
    get playerR(): Registration {
        try {
            return this.getPlayer(this.playerRId);
        } catch (e) {
            return null;
        }
    }
    get playerD(): Registration {
        try {
            return this.getPlayer(this.playerDId);
        } catch (e) {
            return null;
        }
    }
    get teamO(): Team {
        try {
            return this.game.getTeam(this.teamId);
        } catch (e) {
            return null;
        }
    }
    get teamD(): Team {
        try {
            return this.game.getTeam(this.game.getOtherTeamId(this.teamId));
        } catch (e) {
            return null;
        }
    }

    setGameInfo(game: Game) {
        this.game = game;
        this.gameId = game.id;
        this.time = game.time;
        this.down = game.down;
        this.half = game.half;
        this.playNumber = game.playNumber;
    }

    constructor(data?: {
        id?: any,
        gameId?: any,
        half?: number,
        time?: number,
        down?: number,
        playNumber?: any,
        penalty?: any,
        distance?: any,
        playerQId?: any,
        playerRId?: any,
        playerDId?: any,
        teamId?: any,
        actionO?: any,
        actionD?: any,
        result?: any,
        game?: Game,
        homeScore?: number,
        awayScore?: number,
    }) {

        if (data) {
            if (data.game) this.game = data.game;
            this.id = data.id;
            this.gameId = data.gameId;
            this.half = data.half;
            this.time = data.time;
            this.down = data.down;
            this.playNumber = data.playNumber;
            this.penalty = data.penalty;
            this.distance = data.distance;
            this.playerQId = data.playerQId;
            this.playerRId = data.playerRId;
            this.playerDId = data.playerDId;
            this.teamId = data.teamId;
            this.actionO = data.actionO;
            this.actionD = data.actionD;
            this.result = data.result;
            this.homeScore = data.homeScore;
            this.awayScore = data.awayScore;
        }
    }

    computeHash(): string {
        let actionO = Object.keys(ACTIONS).find((k: any) => ACTIONS[k] == this.actionO) || 'null';
        let actionD = Object.keys(ACTIONS).find((k: any) => ACTIONS[k] == this.actionD) || 'null';
        let result = Object.keys(RESULTS).find((k: any) => RESULTS[k] == this.result) || 'null';
        let penalty = Object.keys(PENALTIES).find((k: any) => PENALTIES[k] == this.penalty) || 'null';
        return actionO + '-' + actionD + '-' + result + '-' + penalty;
    }

    getSentence(sentenceTemplate: string): string {

        let sentence = '';
        /**
         * Custom sentence for a play correction
         */
        if (this.result == RESULTS.CORRECTION) {
            sentence = this.getCorrectionSentence();
        } else {
            let yards = '';
            if (this.distance !== null) {
                yards = this.distance + ' yards';
            }

            sentence = sentenceTemplate;

            sentence = sentence.replace(/\[q\]/g, this.getPlayerPart(this.playerQ));
            sentence = sentence.replace(/\[r\]/g, this.getPlayerPart(this.playerR));
            sentence = sentence.replace(/\[d\]/g, this.getPlayerPart(this.playerD));
            sentence = sentence.replace(/\[Team O\]/g, this.teamO ? this.teamO.name : '?');
            sentence = sentence.replace(/\[Team D\]/g, this.teamD ? this.teamD.name : '?');
            sentence = sentence.replace(/\[Distance\]/g, yards);
        }

        return sentence;
    }

    private getPlayerPart(registration: Registration) {
        return registration ? registration.name + ' (' + registration.jerseyNumber + ')' : '?';
    }

    private getCorrectionSentence(): string {
        let sentence = '';
        if (this.homeScore || this.homeScore === 0) sentence += this.game.homeTeam.name + '\'s score corrected to ' + this.homeScore + '. ';
        if (this.awayScore || this.awayScore === 0) sentence += this.game.awayTeam.name + '\'s score corrected to ' + this.awayScore + '. ';
        if (this.down) sentence += 'Down corrected to ' + this.down + '. ';
        if (this.time || this.time === 0) {
            let minutes = TimeHelper.getMinutesString(this.time);
            let seconds = TimeHelper.getSecondsString(this.time);
            sentence += 'Time set to ' + minutes + ':' + seconds;
        }
        if (this.half) {
            let halfString = this.half == 1 ? '1st half' : '2nd half';
            sentence += 'Half updated: ' + halfString + '. ';
        }

        return sentence;
    }

    toServer(): {} {

        return {
            id: this.id,
            gameId: this.gameId,
            half: this.half,
            time: this.time,
            down: this.down,
            playNumber: this.playNumber,
            penalty: Object.keys(PENALTIES).find((k: any) => PENALTIES[k] == this.penalty),
            distance: this.distance,
            playerQId: this.playerQId,
            playerRId: this.playerRId,
            playerDId: this.playerDId,
            teamId: this.teamId,
            actionO: Object.keys(ACTIONS).find((k: any) => ACTIONS[k] == this.actionO),
            actionD: Object.keys(ACTIONS).find((k: any) => ACTIONS[k] == this.actionD),
            result: Object.keys(RESULTS).find((k: any) => RESULTS[k] == this.result),
            homeScore: this.homeScore,
            awayScore: this.awayScore,
        };
    }

    static fromServer(log: PlayLog): PlayLog {

        if (log.game) log.game = Game.fromServer(log.game);
        if (log.actionO) log.actionO = ACTIONS[log.actionO];
        if (log.actionD) log.actionD = ACTIONS[log.actionD];
        if (log.penalty) log.penalty = PENALTIES[log.penalty];
        if (log.result) log.result = RESULTS[log.result];
        if (log.down) log.down = +log.down;
        if (log.time) log.time = +log.time;
        if (log.half) log.half = +log.half;
        if (log.homeScore) log.homeScore = +log.homeScore;
        if (log.awayScore) log.awayScore = +log.awayScore;

        log = new PlayLog(log);
        return log;
    }

    private getPlayer(playerId: any): Registration {

        let registration = this.getPlayerOnTeam(playerId, this.game.homeTeam);
        if (registration != null) {
            return registration;
        }

        registration = this.getPlayerOnTeam(playerId, this.game.awayTeam);
        if (registration != null) {
            return registration;
        }

        throw new Error('Could not find player on either team for player id ' + playerId);
    }

    private getPlayerOnTeam(playerId: any, team: Team) {
        try {
            return team.getRegistration(playerId);
        } catch (e) {
            return null;
        }
    }
}