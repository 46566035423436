import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HelperService } from '../util/helper.service';

export interface CreateSportscardResponse {
    user: {
        id: string,
        sportscardsCustomerId: string,
    },
    registration: {
        id: string,
        sportscardsId: string,
    },
    sportscards: {
        cardUrl: string,
        message: string
    }
}

@Injectable({
    providedIn: 'root'
})
export class SportscardsService {

    private url = '/friend-requests';

    constructor(
        private http: HttpClient,
        private helperService: HelperService,
    ) { }

    createCard(registrationId: any): Observable<CreateSportscardResponse> {
        return this.http.post<CreateSportscardResponse>('/registrations/' + registrationId + '/actions/create-card', null).pipe(
            catchError(this.helperService.handleError('createCard', null))
        );
    }
}
