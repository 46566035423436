import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TfflCoreConfig, TFFL_CORE_CONFIG } from '../../tffl-core.config';
import { SeasonsService } from '../tfflServices/seasons-service';

@Injectable({ providedIn: 'root' })
export class StatSeasonGuardService {

    constructor(
        private seasonsService: SeasonsService,
        private router: Router,
        @Inject(TFFL_CORE_CONFIG) private config: TfflCoreConfig
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.seasonsService.getSeasons(this.config.leagueId).pipe(
            map(seasons => {
                console.log('getting seasons', this.config.leagueId, seasons);
                if (!seasons || !seasons.length) {
                    throw new Error('No seasons');
                } else {
                    return seasons.find(s => s.hasStats);
                }
            }),
            map(season => {
                this.router.navigate([state.url, season.id]);
                return true;
            }),
            catchError(this.handleError())
        );
    }

    private handleError() {
        return (error: any): Observable<boolean> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            this.router.navigate(['/programs/not-found']);

            // Let the app keep running by returning an empty result or throw an error
            return of(false);
        };
    }
}
