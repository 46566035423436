export class Feedback {

    id: any;
    gameId: any;
    registrationId: any;
    experienceRating: string;
    coachRating: string;
    refereeRating: string;
    notes: string;
    createdDate: Date;

    constructor(data?: {
        id?: any,
        gameId?: any,
        registrationId?: any,
        experienceRating?: string,
        coachRating?: string,
        refereeRating?: string,
        notes?: string,
        createdDate?: Date,
    }) {

        if (data) {
            this.id = data.id;
            this.gameId = data.gameId;
            this.registrationId = data.registrationId;
            this.experienceRating = data.experienceRating;
            this.coachRating = data.coachRating;
            this.refereeRating = data.refereeRating;
            this.notes = data.notes;
            this.createdDate = data.createdDate;
        }
    }

    static fromServer(item: Feedback): Feedback {
        item = new Feedback(item);

        return item;
    }
}