import { SafeHtml, SafeUrl } from '@angular/platform-browser';

export class League {
    id: any;
    name: string;
    logoUrl: string | SafeUrl;
    companyUrl: string | SafeUrl;
    url: string;
    domainName: string;
    downs: number;
    halfDuration: number;
    waiverMessage: string | SafeHtml;

    constructor(data?: {
        id?: any,
        name?: string,
        logoUrl?: string | SafeUrl,
        companyUrl: string | SafeUrl,
        url: string,
        domainName: string,
        downs?: number,
        halfDuration?: number,
        waiverMessage?: string | SafeHtml,
    }) {

        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.logoUrl = data.logoUrl;
            this.companyUrl = data.companyUrl;
            this.url = data.url;
            this.domainName = data.domainName;
            this.downs = data.downs;
            this.halfDuration = data.halfDuration;
            this.waiverMessage = data.waiverMessage;
        }
    }

    static fromServer(league: League): League {
        league.downs = +league.downs;
        league.halfDuration = +league.halfDuration;
        league = new League(league);
        return league;
    }
}