import { DISTANCES, RESULTS } from '../constants/stats';
import { PlayLog } from '../tfflModels/playLog';
import { Actions } from './playerButton';

export class PlayInfo {
    action: Actions = null;
    result: RESULTS = null;
    distance: DISTANCES = null;
    penalty: any = null;
    down: number;
    totalDowns: number;
    logs: PlayLog[] = [];
    time: number;
    isInterception: boolean = false;
    posession: any;
    playNumber: number;
    extraPoint: number;
    isKickAfterTD: boolean;
    isResetDown: boolean;

    constructor(data?: {
        action?: Actions,
        result?: RESULTS,
        distance?: DISTANCES,
        penalty?: any,
        down?: number,
        totalDowns?: number,
        logs?: PlayLog[],
        time?: number,
        isInterception?: boolean,
        posession?: any,
        playNumber?: number,
        extraPoint?: number
        isKickAfterTD?: boolean
    }) {

        if (data) {
            this.action = data.action;
            this.result = data.result;
            this.distance = data.distance;
            this.penalty = data.penalty;
            this.down = data.down;
            this.totalDowns = data.totalDowns;
            this.logs = data.logs;
            this.time = data.time;
            this.isInterception = data.isInterception;
            this.posession = data.posession;
            this.playNumber = data.playNumber;
            this.extraPoint = data.extraPoint;
            this.isKickAfterTD = data.isKickAfterTD;
        }
    }

    equals(obj: PlayInfo) {
        return obj &&
            obj.action == this.action &&
            obj.result == this.result &&
            obj.distance == this.distance &&
            obj.penalty == this.penalty &&
            obj.down == this.down &&
            obj.totalDowns == this.totalDowns &&
            obj.time == this.time &&
            obj.isInterception == this.isInterception &&
            obj.posession == this.posession &&
            obj.playNumber == this.playNumber &&
            obj.extraPoint == this.extraPoint &&
            obj.isKickAfterTD == this.isKickAfterTD;
    }

    clone() {
        return new PlayInfo(this);
    }
}