/**
 * This class is used to load specific users, such as based on their name
 */
export class UsersFilter {
    search: string;

    constructor(data?: {
        search?: string,
    }) {

        if (data) {
            this.search = data.search;
        }
    }

    getQuery() {

        let params = new URLSearchParams();

        if (this.search) {
            params.append('search', this.search);
        }

        return params.toString();
    }
}