export class ProgramDivision {

    programId: any;
    divisionId: any;

    constructor(data?: {
        programId?: any,
        divisionId?: any,
    }) {

        if (data) {
            this.programId = data.programId;
            this.divisionId = data.divisionId;
        }
    }
}