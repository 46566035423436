export class TimeHelper {

    public static getMinutesString(milliseconds: number): string {
        return TimeHelper.pad(TimeHelper.getMinutesFloor(milliseconds), 2);
    }

    public static getSecondsString(milliseconds: number): string {
        return TimeHelper.pad(TimeHelper.getSecondsFloor(milliseconds), 2);
    }

    public static getMillisecondsString(milliseconds: number): string {
        return TimeHelper.pad(TimeHelper.getMillisecondsFloor(milliseconds), 2);
    }

    public static getMinutesFloor(milliseconds: number): number {
        milliseconds = milliseconds || 0;
        return Math.floor(milliseconds / 60000);
    }

    public static getSecondsFloor(milliseconds: number): number {
        milliseconds = milliseconds || 0;
        return Math.floor(milliseconds / 1000) % 60;
    }

    public static getMillisecondsFloor(milliseconds: number): number {
        milliseconds = milliseconds || 0;
        return Math.round(milliseconds % 1000);
    }

    public static toMinutesExact(milliseconds: number): number {
        return milliseconds / 60000.0;
    }

    public static toMillisecondsExact(milliseconds: number): number {
        return milliseconds * 60000.0;
    }

    private static pad(num: number, size: number): string {
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }
}
