import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FeedbackFilter } from '../../classes/filters/feedbackFilter';
import { Feedback } from '../../classes/tfflModels/feedback';
import { HelperService } from '../util/helper.service';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService {

    constructor(
        private http: HttpClient,
        private helperService: HelperService,
    ) { }

    getFeedback(filter: FeedbackFilter): Observable<Feedback[]> {
        let params = '?' + filter.getQuery();
        return this.http.get<{ feedback: Feedback[] }>('/feedback' + params).pipe(
            map(response => response.feedback),
            map(this.extractFeedback), /* IMPORTANT */
            catchError(this.helperService.handleError('getFeedback', null))
        );
    }

    updateFeedback(feedback: Feedback): Observable<any> {
        let data = { feedback: feedback };
        return this.http.post<{}>('/feedback', data).pipe(
            catchError(this.helperService.handleError('set feedback', null))
        )
    }

    private extractFeedback(feedback: Feedback[]) {
        for (let i = 0; i < feedback.length; i++) {
            feedback[i] = Feedback.fromServer(feedback[i]);
        }

        return feedback;
    }
}
