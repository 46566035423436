
export class Franchise {
    id: any;
    name: string;
    leagueId: any;
    creator: any;//id of user who create the Franchise

    constructor(data?: { 
        id?: any,
        name?: string,
        leagueId?: any,
        creator?: any,
    }) {

        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.leagueId = data.leagueId;
            this.creator = data.creator;
        }
       
    }

    static fromServer(franchise: Franchise): Franchise {
        franchise = new Franchise(franchise);
        return franchise;
    }
}