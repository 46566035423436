import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from './../formValidators/validators';
import { UserFormFields } from './userFormFields';

export class User {
    id: any;
    firstName: string;
    lastName: string;
    email: string;
    birthdate: Date;
    phoneNumber: string;
    profileUrl: string;
    referralCode: string;
    isReferralCodeActive: boolean;
    hasRegistered: boolean;
    isArgosOptin: boolean;
    hasBeenAskedArgosOptin: boolean;
    favouriteArgosPlayer: string;
    argosGames: string;
    credit: number;
    city: string;
    heightInches: number;
    signupReason: string;

    /** Staff Info */
    socialInsuranceNumber: string;
    address: string;
    postalCode: string;
    bankTransitNumber: string;
    bankInstitutionNumber: string;
    bankAccountNumber: string;

    /** The relation to another user */
    relation: RelationshipType;

    /** The side of the relationship (i.e. the parent or the child) */
    relationSide: RelationshipSide;

    get age(): number {
        return (this.birthdate) ? new Date().getFullYear() - this.birthdate.getFullYear() : null;
    };

    get nameFirstInitial(): string {
        let first = this.firstName ? this.firstName.charAt(0) : '';
        return first + '. ' + this.lastName;
    };

    /** 
     * ex.  
     * firstName: "Joseph Elija"
     * lastName: "Cohen" 
     * 
     * becomes:
     * 
     * "Joseph Elija Cohen" 
     */
    get name(): string {
        return this.firstName + ' ' + this.lastName;
    }

    /** 
     * ex.  
     * firstName: "Joseph Elija"
     * lastName: "Cohen" 
     * 
     * becomes:
     * 
     * "Cohen Joseph Elija" 
     */
    get nameReversed(): string {
        return this.lastName + ' ' + this.firstName;
    }

    isChild(): boolean {
        return this.relation == RelationshipType.GUARDIAN &&
            this.relationSide == RelationshipSide.CHILD;
    }

    isParent(): boolean {
        return this.relation == RelationshipType.GUARDIAN &&
            this.relationSide == RelationshipSide.PARENT;
    }

    clone(): User {
        let cloned = new User(this);
        cloned.id = null;
        return cloned;
    }

    constructor(data?: {
        id?: any,
        firstName?: string,
        lastName?: string,
        phoneNumber?: string,
        birthdate?: Date,
        email?: string,
        profileUrl?: string,
        referralCode?: string,
        isReferralCodeActive?: boolean,
        hasRegistered?: boolean,
        isArgosOptin?: boolean,
        hasBeenAskedArgosOptin?: boolean,
        favouriteArgosPlayer?: string,
        argosGames?: string,
        relation?: RelationshipType,
        relationSide?: RelationshipSide,
        credit?: number,
        city?: string,
        heightInches?: number,
        signupReason?: string,
        socialInsuranceNumber?: string,
        address?: string,
        postalCode?: string,
        bankTransitNumber?: string,
        bankInstitutionNumber?: string,
        bankAccountNumber?: string,
    }) {

        if (data) {
            this.id = data.id;
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.email = data.email;
            this.birthdate = data.birthdate;
            this.phoneNumber = data.phoneNumber;
            this.profileUrl = data.profileUrl;
            this.referralCode = data.referralCode;
            this.isReferralCodeActive = data.isReferralCodeActive;
            this.hasRegistered = data.hasRegistered;
            this.isArgosOptin = data.isArgosOptin;
            this.hasBeenAskedArgosOptin = data.hasBeenAskedArgosOptin;
            this.favouriteArgosPlayer = data.favouriteArgosPlayer;
            this.argosGames = data.argosGames;
            this.relation = data.relation;
            this.relationSide = data.relationSide;
            this.credit = data.credit;
            this.city = data.city;
            this.heightInches = data.heightInches;
            this.signupReason = data.signupReason;
            this.socialInsuranceNumber = data.socialInsuranceNumber;
            this.address = data.address;
            this.postalCode = data.postalCode;
            this.bankTransitNumber = data.bankTransitNumber;
            this.bankInstitutionNumber = data.bankInstitutionNumber;
            this.bankAccountNumber = data.bankAccountNumber;
        }

    }

    /* This converts a json object representing a user from the server to a properly instantiated instance of the class */
    static fromServer(user: User) {

        //Convert the birthdate timestamp to a Date object
        user.birthdate = user.birthdate ? new Date(user.birthdate) : null;

        user.credit = +user.credit;
        if (user.heightInches) user.heightInches = +user.heightInches;

        //Instantiate the user, so that methods defined by the class are accessible
        user = new User(user);

        return user;
    }

    /**
     * Generates a FormGroup that can be used by components with a user creation form
     * 
     * @param options - the fields that should be included on the FormGroup
     * @param fb - The formbuilder service
     */
    static getFormGroup(
        options: UserFormFields,
        fb: FormBuilder,
        user?: User
    ) {

        /* FOR QUICKER TESTING */
        // let defaultValues = {
        //     firstName: 'Kyle',
        //     lastName: 'Lowry',
        //     birthdate: { year: 2007, month: 10, day: 19 },
        //     email: 'klow@gmail.com',
        //     phoneNumber: '6473003814',
        //     password: 'noam',
        //     confirmPassword: 'noam',
        //     waiver: true,
        // }

        let defaultValues = {
            firstName: '',
            lastName: '',
            birthdate: null,
            email: '',
            phoneNumber: '',
            password: '',
            confirmPassword: '',
            signupReason: '',
            isArgosOptin: false,
            favouriteArgosPlayer: '',
            argosGames: '',
            waiver: false,
        }

        if (user) {
            defaultValues.firstName = user.firstName;
            defaultValues.lastName = user.lastName;
            defaultValues.birthdate = user.birthdate;
            defaultValues.email = user.email;
            defaultValues.phoneNumber = user.phoneNumber;
            defaultValues.signupReason = user.signupReason;
            defaultValues.isArgosOptin = user.isArgosOptin;
            defaultValues.favouriteArgosPlayer = user.favouriteArgosPlayer;
            defaultValues.argosGames = user.argosGames;
        }

        let userSettings: any = {};

        if (options.firstName) { userSettings.firstName = [defaultValues.firstName, Validators.required] }
        if (options.lastName) { userSettings.lastName = [defaultValues.lastName, Validators.required] }
        if (options.birthdate) { userSettings.birthdate = [defaultValues.birthdate, Validators.required]; }
        if (options.email) { userSettings.email = [defaultValues.email, [Validators.email, Validators.required]]; }
        if (options.phoneNumber) { userSettings.phoneNumber = [defaultValues.phoneNumber, Validators.required]; }
        if (options.signupReason) { userSettings.signupReason = [defaultValues.phoneNumber, Validators.required]; }
        if (options.isArgosOptin) { userSettings.isArgosOptin = [defaultValues.isArgosOptin, Validators.required]; }
        if (options.favouriteArgosPlayer) { userSettings.favouriteArgosPlayer = [defaultValues.favouriteArgosPlayer, Validators.required]; }
        if (options.argosGames) { userSettings.argosGames = [defaultValues.argosGames, Validators.required]; }

        if (options.firstName === false) { userSettings.firstName = [defaultValues.firstName] }
        if (options.lastName === false) { userSettings.lastName = [defaultValues.lastName] }
        if (options.birthdate === false) { userSettings.birthdate = [defaultValues.birthdate]; }
        if (options.email === false) { userSettings.email = [defaultValues.email, [Validators.email]]; }
        if (options.phoneNumber === false) { userSettings.phoneNumber = [defaultValues.phoneNumber]; }
        if (options.signupReason === false) { userSettings.signupReason = [defaultValues.signupReason]; }
        if (options.isArgosOptin === false) { userSettings.isArgosOptin = [defaultValues.isArgosOptin]; }
        if (options.favouriteArgosPlayer === false) { userSettings.favouriteArgosPlayer = [defaultValues.favouriteArgosPlayer]; }
        if (options.argosGames === false) { userSettings.argosGames = [defaultValues.argosGames]; }

        let formGroupSettings: any = {};
        formGroupSettings.user = fb.group(userSettings);

        if (options.password) {

            let passwordSettings: any = {};
            passwordSettings.password = [defaultValues.password, Validators.required];
            passwordSettings.confirmPassword = [defaultValues.confirmPassword, Validators.required];
            formGroupSettings.passwords = fb.group(passwordSettings, {
                validator: CustomValidators.passwordValidator
            });

        }

        if (options.waiver) { formGroupSettings.waiver = [defaultValues.waiver, Validators.requiredTrue]; }

        let form = fb.group(formGroupSettings);

        return form;
    }

    /**
     * Converts a Form Group to a user
     * @param form 
     */
    static toUser(form: FormGroup): User {
        let model = form.value.user;

        let user = new User();

        if ('firstName' in model) user.firstName = model.firstName;
        if ('lastName' in model) user.lastName = model.lastName;
        if ('birthdate' in model) user.birthdate = model.birthdate;
        if ('email' in model) user.email = model.email;
        if ('phoneNumber' in model) user.phoneNumber = model.phoneNumber;
        if ('signupReason' in model) user.signupReason = model.signupReason;
        if ('isArgosOptin' in model) user.isArgosOptin = model.isArgosOptin;
        if ('favouriteArgosPlayer' in model) user.favouriteArgosPlayer = model.favouriteArgosPlayer;
        if ('argosGames' in model) user.argosGames = model.argosGames;

        return user;
    }
}

export enum RelationshipType {
    GUARDIAN = 'GUARDIAN',
}

/**
 * Parent, child denote which side of the relationship the user is on.
 * For example, if the relationship type is "COACH", the "PARENT" is the coach,
 * and the "CHILD" is the player
 */
export enum RelationshipSide {
    PARENT = 'PARENT',
    CHILD = 'CHILD'
}