import { Injectable } from '@angular/core';
import { ACTIONS, DISTANCES, RESULTS } from '../../classes/constants/stats';
import { STATS_APP_ERROR_MESSAGES } from '../../classes/statsApp/errorCodes';
import { Actions, States } from '../../classes/statsApp/playerButton';
import { PlayFragment } from '../../classes/statsApp/playFragment';
import { PlayInfo } from '../../classes/statsApp/playInfo';
import { CoinToss, CoinTossOption } from '../../classes/tfflModels/coinToss';
import { Game } from '../../classes/tfflModels/game';
import { GameCorrection } from '../../classes/tfflModels/gameCorrection';
import { PlayerStats } from '../../classes/tfflModels/playerStats';
import { PlayLog } from '../../classes/tfflModels/playLog';
import { Registration } from '../../classes/tfflModels/registration';
import { Team } from '../../classes/tfflModels/team';
import { PlayerButtonsService } from '../other/player-buttons.service';

@Injectable({ providedIn: 'root' })
export class GameEngineService {

    constructor(
        private playerButtonsService: PlayerButtonsService
    ) { }

    simulatePlays(logs: PlayLog[], currentGame: Game): { stats: PlayerStats[], plays: PlayInfo[], game: Game } {

        // console.log('simulate plays', logs);

        /**
         * Clone the currentGame so that we don't make changes to it
         */
        // let game: Game = currentGame.clone();
        let game: Game = currentGame;
        let stats: { [playerId: number]: PlayerStats } = {};
        let plays: PlayInfo[] = [];

        /**
         * Initialize the stats for the home and away team
         */
        [game.homeTeam, game.awayTeam].map(team => {
            for (let i = 0; i < team.registrations.length; i++) {
                stats[team.registrations[i].id] = new PlayerStats({
                    registrationId: team.registrations[i].id,
                    registrationName: team.registrations[i].name,
                    registrationNumber: team.registrations[i].jerseyNumber,
                    gameId: game.id,
                    seasonId: game.seasonId,
                });
            }
        });

        let playInfo = new PlayInfo();
        playInfo.totalDowns = game.totalDowns;

        for (let i = 0; i < logs.length; i++) {
            let log = logs[i];
            let nextLog = i + 1 < logs.length ? logs[i + 1] : null;

            let specialResults = [RESULTS.CORRECTION, RESULTS.COIN_TOSS, RESULTS.PICK_BALL, RESULTS.PICK_DEFER, RESULTS.PICK_SIDE];

            if (!specialResults.includes(log.result) && !this.isValidDownChange(log.down, game.down)) {
                console.warn('Invalid down, calculated: ' + game.down + ', log: ' + log.down + ' on play: ' + log.playNumber);
            }

            playInfo.posession = game.posession;
            playInfo.down = game.down;
            playInfo.logs.push(log);

            /**
             * If there is a player in the stats that wasn't already on either of
             * the teams (ex. if a player gets traded mid season), then we must 
             * initialize their stats
             */
            this.initStats(log, game, stats);

            /**
             * Calculate scores/posession changes
             */
            switch (log.result) {
                case RESULTS.KICK:
                case RESULTS.PUNT:
                    game.switchPossession();
                    game.down = 1;
                    break;
                case RESULTS.FIRST:
                    game.down = 1;
                    break;
                case RESULTS.LOSS:
                case RESULTS.INC:
                case RESULTS.SHORT:
                    game.down = log.down + 1;
                    break;
                case RESULTS.TO:
                case RESULTS.SS:
                    game.switchPossession();
                    playInfo.isResetDown = true;
                    break;
                case RESULTS.TD:
                    game.offenseScore += 6;
                    break;
                case RESULTS.PT1_GOOD:
                    game.offenseScore += 1;
                    playInfo.isResetDown = true;
                    break;
                case RESULTS.PT2_GOOD:
                    game.offenseScore += 2;
                    playInfo.isResetDown = true;
                    break;
                case RESULTS.PT2_FAIL:
                    playInfo.isResetDown = true;
                    break;
                case RESULTS.DROP_LAT:
                    game.down = 1;
                    game.switchPossession();
                    break;
                case RESULTS.SAFETY:
                    game.defenseScore += 1;
                    game.down = 1;
                    break;
                case RESULTS.CORRECTION:
                    if (log.homeScore || log.homeScore == 0) game.homeScore = log.homeScore;
                    if (log.awayScore || log.awayScore == 0) game.awayScore = log.awayScore;
                    if (log.down) game.down = log.down;
                    if (log.time || log.time === 0) game.time = log.time;
                    if (log.half) game.half = log.half;
                    break;
                case RESULTS.PICK_BALL:
                    game.posession = game.getOtherTeamId(log.teamId);
                    break;
                case RESULTS.COIN_TOSS:
                    game.coinToss = log.teamId;
                    break;
                case RESULTS.PICK_DEFER:
                case RESULTS.PICK_SIDE:
                    game.down = 1;
                case null:
                case undefined:
                    break;
                default:
                    throw new Error('Error. Result did not match expected results: ' + log.result);
            }

            switch (log.actionO) {
                case ACTIONS.RUN:

                    if (playInfo.isInterception) {

                        if (log.result === RESULTS.TD) {
                            stats[log.playerQId].interceptionTds++;
                        }

                    } else {

                        /* Rush */
                        stats[log.playerQId].rushes++;

                        /* Rush yards */
                        let distanceSign = (log.result === RESULTS.LOSS) ? -1 : 1;
                        stats[log.playerQId].rushYards += distanceSign * log.distance;

                        /* Rush 1st */
                        if (log.result === RESULTS.FIRST) {
                            stats[log.playerQId].rushFirsts++;
                        }

                        /* Rush Tds */
                        if (log.result === RESULTS.TD) {
                            stats[log.playerQId].rushFirsts++; /** Touchdowns count as 1st downs */
                            stats[log.playerQId].rushTds++;
                        }
                    }

                    break;
                case ACTIONS.KICK_RT:
                case ACTIONS.PUNT_RT:
                    /* Kick return */
                    stats[log.playerQId].kickReturns++;

                    /* Kick return td */
                    if (log.result === RESULTS.TD) {
                        stats[log.playerQId].kickReturnTds++;
                    }

                    /* Kick return yards */
                    let distanceSign = (log.result === RESULTS.LOSS) ? -1 : 1;
                    stats[log.playerQId].kickReturnYards += distanceSign * log.distance;

                    break;
                case ACTIONS.KICK:
                    /* Kicks */
                    stats[log.playerQId].kicks++;
                    break;
                case ACTIONS.PUNT:
                    /* Punts */
                    stats[log.playerQId].punts++;
                    break;
                case ACTIONS.THROW:

                    /* Completed pass */
                    if (log.result === RESULTS.SHORT || log.result === RESULTS.FIRST || log.result === RESULTS.TD || log.result === RESULTS.LOSS) {

                        let distanceSign = (log.result === RESULTS.LOSS) ? -1 : 1;

                        /* completion, reception, yards */
                        stats[log.playerQId].completions++;
                        stats[log.playerQId].completionYards += distanceSign * log.distance;
                        stats[log.playerRId].receptions++;
                        stats[log.playerRId].receptionYards += distanceSign * log.distance;
                    }

                    /* first down */
                    if (log.result === RESULTS.FIRST) {
                        stats[log.playerQId].completionFirsts++;
                        stats[log.playerRId].receptionFirsts++;
                    }

                    /* touchdown */
                    if (log.result === RESULTS.TD) {
                        stats[log.playerQId].completionFirsts++; /** Touchdowns counts as first downs */
                        stats[log.playerQId].completionTds++;
                        stats[log.playerRId].receptionFirsts++; /** Touchdowns counts as first downs */
                        stats[log.playerRId].receptionTds++;
                    }

                    break;
            }

            switch (log.actionD) {
                case ACTIONS.FLAG_PULL:

                    /* Flag pull */
                    stats[log.playerDId].flagPulls++;

                    /* Sack */
                    if (log.result === RESULTS.LOSS) {
                        stats[log.playerDId].sacks++;
                    }

                    break;

                case ACTIONS.DEFL:

                    /* Deflection */
                    stats[log.playerDId].deflections++;

                    break;

                case ACTIONS.INT:

                    playInfo.isInterception = true;

                    /* Interception (interception touchdowns calculated in offensive rush calcualtions) */
                    stats[log.playerDId].interceptions++;

                    break;
            }

            switch (log.penalty) {
                // case PENALTIES.ILLEGAL_RUSH:
                //     game.down++;
                //     break;
            }

            /* IF WE REACHED THE END OF THE CURRENT PLAY */
            if (i == logs.length - 1 || log.playNumber !== logs[i + 1].playNumber) {

                playInfo.playNumber = game.playNumber;

                plays.push(playInfo);

                /* UPDATE THE DOWN AND POSESSION IF IT WAS A TURNOVER TO BE FIRST DOWN */
                if (playInfo.isResetDown) {
                    game.down = 1;
                }

                /* UPDATE THE TIME OF THE GAME IF THE TIME IS SET */
                if (log.time) game.time = log.time;

                /* RESET THE CURRENT PLAY VARIABLE FOR THE NEXT PLAY */
                playInfo = new PlayInfo();
                playInfo.totalDowns = game.totalDowns;
                playInfo.time = log.time;

                /* UPDATE THE PLAY NUMBER OF THE GAME */
                game.playNumber = log.playNumber;
            }
        }

        /**
         * Increment the playNumber at the end of the simulation to 
         * go to the next play
         */
        if (logs.length) game.playNumber++;

        return {
            game: game,
            stats: Object.values(stats),
            plays: plays
        };
    }

    createFragments(playInfo: PlayInfo, game: Game): PlayFragment[] {
        let action = playInfo.action;
        let result = playInfo.result;
        let distance = playInfo.distance;
        let penalty = playInfo.penalty;

        //for now, assume playInfo.action is defined and playInfo.result is defined

        let fragments = [];
        let errors = [];
        let fragment;
        let errorMessages = STATS_APP_ERROR_MESSAGES;

        switch (action) {
            case Actions.COMPLETION:

                if (result == RESULTS.TD && this.playerButtonsService.existsPlayer(States.FP)) {
                    errors.push(errorMessages.NoFlagPullOnTouchdown);
                } else if (!result) {
                    errors.push(errorMessages.ResultRequired);
                } else if (result == RESULTS.DROP_LAT && this.playerButtonsService.existsPlayer(States.FP)) {
                    errors.push(errorMessages.NoFlagPullOnDroppedLat);
                }

                try {
                    fragments = fragments.concat(
                        this.getReceptionFragments()
                    );
                } catch (e) {
                    errors.push(errorMessages.QuaterbackAndReceiverRequired);
                }

                try {
                    fragments = fragments.concat(
                        this.getFlagPullFragments(result)
                    );
                } catch (e) {
                    errors.push(errorMessages.FlagPullerRequired);
                }

                fragment = this.getResultFragment(playInfo, this.playerButtonsService.getOffenseTeam());
                fragments.push(fragment);

                try {
                    fragments.push(this.getDistanceFragment(distance, result));
                } catch (e) {
                    errors.push(errorMessages.DistanceRequired);
                }

                this.doErrors(errors, 'A throwing play');

                break;

            case Actions.INCOMPLETION:

                if (result) {
                    errors.push(errorMessages.NoResultAllowed);
                }
                if (distance) {
                    errors.push(errorMessages.NoDistanceAllowed);
                }

                try {
                    fragments = fragments.concat(
                        this.getReceptionFragments()
                    );
                } catch (e) {
                    errors.push(errorMessages.QuaterbackAndReceiverRequired);
                }

                try {
                    fragments = fragments.concat(
                        this.getDeflectionFragments()
                    );
                } catch (e) {
                    errors.push(errorMessages.DefenderRequired);
                }

                /** Add the result fragment */
                fragment = new PlayFragment();
                fragment.team = this.playerButtonsService.getOffenseTeam();
                fragment.result = RESULTS.INC;
                fragment.disableEdits = true;
                fragments.push(fragment);

                this.doErrors(errors, 'An incompletion');

                break;

            case Actions.RUN:

                if (result === RESULTS.TD && this.playerButtonsService.existsPlayer(States.FP)) {
                    errors.push(errorMessages.NoFlagPullOnTouchdown);
                } else if (!result) {
                    errors.push(errorMessages.ResultRequired);
                } else if (result == RESULTS.DROP_LAT && this.playerButtonsService.existsPlayer(States.FP)) {
                    errors.push(errorMessages.NoFlagPullOnDroppedLat);
                }

                try {
                    fragments = fragments.concat(
                        this.getRushFragments()
                    );

                } catch (e) {
                    errors.push(errorMessages.QuaterbackRequired);
                }

                try {
                    fragments = fragments.concat(
                        this.getFlagPullFragments(result)
                    );
                } catch (e) {
                    errors.push(errorMessages.FlagPullerRequired);
                }

                fragment = this.getResultFragment(playInfo, this.playerButtonsService.getOffenseTeam());
                fragments.push(fragment);

                try {
                    fragments.push(this.getDistanceFragment(distance, result));
                } catch (e) {
                    errors.push(errorMessages.DistanceRequired);
                }

                this.doErrors(errors, 'A running play');

                break;

            case Actions.INTERCEPTION:

                fragment = new PlayFragment();

                if (result === RESULTS.TD && this.playerButtonsService.existsPlayer(States.FP)) {
                    errors.push(errorMessages.NoFlagPullOnTouchdown);
                }
                if (!result) {
                    errors.push(errorMessages.ResultRequired);
                }
                if (result == RESULTS.DROP_LAT && this.playerButtonsService.existsPlayer(States.FP)) {
                    errors.push(errorMessages.NoFlagPullOnDroppedLat);
                }
                if ([RESULTS.SHORT, RESULTS.LOSS].includes(result)) {
                    errors.push(errorMessages.NoShortYardsOnInterception);
                }

                try {
                    fragments = fragments.concat(this.getInterceptionFragments());
                } catch (e) {
                    errors.push(errorMessages.InterceptorRequired);
                }

                try {
                    fragments.push(this.getDistanceFragment(distance, result));
                } catch (e) {
                    errors.push(errorMessages.DistanceRequired);
                }

                try {
                    fragments = fragments.concat(
                        this.getFlagPullFragments(result)
                    );
                } catch (e) {
                    errors.push(errorMessages.FlagPullerRequired);
                }

                fragment.result = RESULTS.TO;
                fragment.disableEdits = true;
                fragments.push(fragment);

                fragment = this.getResultFragment(playInfo, this.playerButtonsService.getDefenseTeam());
                fragments.push(fragment);

                this.doErrors(errors, 'An interception');

                break;

            case Actions.KICK:
            case Actions.PUNT:

                // fragment.result = (action === ACTIONS.KICK) ? RESULTS.KICK : RESULTS.PUNT;
                // fragment.disableEdits = true;
                // fragments.push(fragment);

                if (result === RESULTS.TD && this.playerButtonsService.existsPlayer(States.FP)) {
                    errors.push(errorMessages.NoFlagPullOnTouchdown);
                } else if (!result) {
                    errors.push(errorMessages.ResultRequired);
                } else if (result == RESULTS.DROP_LAT && this.playerButtonsService.existsPlayer(States.FP)) {
                    errors.push(errorMessages.NoFlagPullOnDroppedLat);
                }

                try {
                    fragments = fragments.concat(
                        this.getKickerFragments(action)
                    );

                } catch (e) {
                    errors.push(errorMessages.KickerRequired);
                }

                try {
                    fragments = fragments.concat(
                        this.getKickReturnFragments(result)
                    );

                } catch (e) {
                    errors.push(errorMessages.KickReturnerRequired);
                }

                try {
                    fragments.push(this.getDistanceFragment(distance, result));
                } catch (e) {
                    if (this.playerButtonsService.existsPlayer(States.RT)) {
                        errors.push(errorMessages.KickReturnDistanceRequired);
                    }
                }

                try {
                    fragments = fragments.concat(
                        this.getFlagPullFragments(result)
                    );
                } catch (e) {
                    errors.push(errorMessages.FlagPullerRequired);
                }

                fragment = this.getResultFragment(playInfo, this.playerButtonsService.getDefenseTeam());
                fragments.push(fragment);

                this.doErrors(errors, 'A kicking play');

                break;

            default:
                throw new Error(errorMessages.ActionRequired);

        }

        fragments.push(this.getDownFragment(game, result, action));

        // console.log('created fragments', fragments);

        return fragments;
    }

    createLogs(fragments: PlayFragment[], game: Game): PlayLog[] {
        let logs: PlayLog[] = [];
        let results = [];
        let distances = [];
        let downs = [];
        let flagPullers = [];
        let deflectors = [];
        let quarterback = null;
        let receiver = null;
        let extraPoint = null;
        let isKickAfterTD = false;
        let isKick = false;
        let filteredFragments = [];
        let log: PlayLog;
        let currentLogCount = 0;
        let currentPosession = game.posession;

        /* do some preprocessing */
        for (let i = 0; i < fragments.length; i++) {
            let fragment = fragments[i];
            if (fragment.result) {
                results.push(fragment.result);

                if (fragment.extraPoint) {
                    extraPoint = fragment.extraPoint;
                    isKickAfterTD = fragment.isKickAfterTD;
                }
            } else if (fragment.action == ACTIONS.THROW) {
                if (fragment.playerQId) {
                    quarterback = fragment.playerQId;
                }
                if (fragment.playerRId) {
                    receiver = fragment.playerRId;
                }
            } else if (fragment.distance) {
                distances.push(fragment.distance);
            } else if (fragment.playerDId && fragment.action == ACTIONS.FLAG_PULL) {
                flagPullers.push(fragment);
            } else if (fragment.playerDId && fragment.action == ACTIONS.DEFL) {
                deflectors.push(fragment.playerDId);
            } else if (fragment.down) {
                downs.push(fragment);
            } else if ([ACTIONS.KICK, ACTIONS.PUNT].includes(fragment.action)) {
                isKick = true;
            }

            if (!fragment.result && !fragment.distance && !fragment.down && ![ACTIONS.FLAG_PULL, ACTIONS.DEFL].includes(fragment.action)) {
                filteredFragments.push(fragment);
            }
        }

        fragments = filteredFragments;

        // console.log({
        //     filteredFragments: filteredFragments,
        //     isKick: isKick,
        //     distances: distances,
        //     flagPullers: flagPullers,
        //     downs: downs,
        //     results: results,
        // });

        for (let i = 0; i < fragments.length; i++) {
            let fragment = fragments[i];
            currentLogCount++;

            let isFinalLog = currentLogCount == fragments.length;

            log = new PlayLog();
            log.setGameInfo(game);

            switch (fragment.action) {
                case null:
                case undefined:
                    break;

                case ACTIONS.HANDOFF:
                case ACTIONS.LATERAL:

                    log.playerQId = fragment.playerQId;
                    log.playerRId = fragment.playerRId;
                    log.actionO = fragment.action;

                    logs.push(log);

                    if (isFinalLog) {

                        log = new PlayLog();
                        log.setGameInfo(game);
                        log.playerQId = fragment.playerRId;
                        log.actionO = ACTIONS.RUN;
                        log.result = results[results.length - 1];

                        if (flagPullers.length === 1) {
                            log.playerDId = flagPullers[0].playerDId;
                            log.actionD = ACTIONS.FLAG_PULL;
                        }

                        logs.push(log);
                    }

                    break;

                case ACTIONS.THROW:

                    /** Skip if the play was an incompletion */
                    if (results.length == 1 && results[0] === RESULTS.INC) {
                        break;
                    }

                    if (!fragment.playerQId || !fragment.playerRId) {
                        throw new Error('quarterback or receiver not defined for throw');
                    } else if (results.length !== 1) {
                        throw new Error('no result');
                    } else if (flagPullers.length > 1) {
                        throw new Error('too many flag pulls');
                    }

                    log.playerQId = fragment.playerQId;
                    log.playerRId = fragment.playerRId;
                    log.actionO = ACTIONS.THROW;
                    log.distance = this.generateYards(distances[0]);
                    log.teamId = currentPosession;


                    if (isFinalLog) {
                        log.result = results[0];
                    }

                    if (flagPullers.length === 1) {
                        log.playerDId = flagPullers[0].playerDId;
                        log.actionD = ACTIONS.FLAG_PULL;
                    }

                    logs.push(log);
                    break;

                case ACTIONS.RUN:
                case ACTIONS.KICK_RT:

                    if (!isKick && !fragment.playerQId) {
                        throw new Error('quarterback not defined for run');
                    } else if (results.length !== 1) {
                        throw new Error('no result');
                    } else if (flagPullers.length > 1) {
                        throw new Error('too many flag pulls');
                    }

                    log.playerQId = fragment.playerQId;
                    log.actionO = fragment.action;
                    log.distance = this.generateYards(distances[0]);

                    if (isFinalLog) {
                        log.result = results[0];
                    }

                    if (flagPullers.length == 1) {
                        log.playerDId = flagPullers[0].playerDId;
                        log.actionD = ACTIONS.FLAG_PULL;
                    }

                    logs.push(log);
                    break;

                case ACTIONS.INT:

                    if (!fragment.playerDId) {
                        throw new Error('defender not defined for interception');
                    } else if (results.length > 2) {
                        throw new Error('too many results for an interception play');
                    }

                    log.playerDId = fragment.playerDId;
                    log.actionD = ACTIONS.INT;
                    log.result = RESULTS.TO;
                    logs.push(log);

                    currentPosession = game.getOtherTeamId(currentPosession);

                    if (isFinalLog) {

                        log = new PlayLog();
                        log.setGameInfo(game);
                        log.playerQId = fragment.playerDId;
                        log.actionO = ACTIONS.RUN;
                        log.distance = this.generateYards(distances[0]);
                        log.result = results[results.length - 1];

                        if (flagPullers.length === 1) {
                            log.playerDId = flagPullers[0].playerDId;
                            log.actionD = ACTIONS.FLAG_PULL;
                        }

                        if (results.includes(RESULTS.DROP_LAT)) {
                            currentPosession = game.getOtherTeamId(currentPosession);
                        }

                        logs.push(log);
                    }

                    break;
                case ACTIONS.KICK:
                case ACTIONS.PUNT:

                    log.playerQId = fragment.playerQId;
                    log.actionO = fragment.action;
                    log.teamId = currentPosession;
                    log.result = RESULTS.KICK;

                    currentPosession = game.getOtherTeamId(currentPosession);

                    logs.push(log);

                    break;
                default:
                    throw new Error('Did not understand play fragment action: ' + fragment.action);

            }
        }

        if (results.length === 1) {
            if (results[0] === RESULTS.INC) {
                log = new PlayLog();
                log.setGameInfo(game);

                log.actionO = ACTIONS.THROW;

                if (quarterback) {
                    log.playerQId = quarterback;
                }

                if (receiver) {
                    log.playerRId = receiver;
                }

                log.teamId = currentPosession;
                log.result = RESULTS.INC;

                if (deflectors.length) {
                    log.playerDId = deflectors[0];
                    log.actionD = ACTIONS.DEFL;
                }

                logs.push(log);
            }

            // else if (results[0] === RESULTS.SAFETY) {
            //     log = new PlayLog();
            //     log.setGameInfo(game);

            //     if (flagPullers.length === 1) {
            //         log.playerDId = flagPullers[0].playerDId;
            //         log.actionD = ACTIONS.FLAG_PULL;
            //     }

            //     log.teamId = currentPosession;
            //     log.result = RESULTS.SAFETY;

            //     logs.push(log);
            // }
        }

        if (extraPoint != null) {
            log = new PlayLog();
            log.setGameInfo(game);

            log.teamId = currentPosession;

            switch (extraPoint) {
                case 0:
                    log.result = RESULTS.PT2_FAIL;
                    break;
                case 1:
                    log.result = RESULTS.PT1_GOOD;
                    break;
                case 2:
                    log.result = RESULTS.PT2_GOOD;
                    break;
                default:
                    throw new Error("Extra point must be 0, 1, or 2: " + extraPoint);
            }

            logs.push(log);
        }

        if (extraPoint != null && !isKickAfterTD) {
            log = new PlayLog();
            log.setGameInfo(game);
            log.teamId = currentPosession;
            log.result = RESULTS.SS;
            logs.push(log);
        }

        if (downs.length === 1) {
            if (downs[0].isTurnoverOnDowns) {
                log = new PlayLog();
                log.setGameInfo(game);

                log.teamId = currentPosession;
                log.result = RESULTS.TO;

                logs.push(log);
            }
        }

        return logs;
    }

    createCorrectionLogs(correction: GameCorrection, game: Game): PlayLog[] {
        let log = new PlayLog();
        log.result = RESULTS.CORRECTION;
        if (correction.homeScore !== undefined) log.homeScore = correction.homeScore;
        if (correction.awayScore !== undefined) log.awayScore = correction.awayScore;
        if (correction.down) log.down = correction.down;
        if (correction.half) log.half = correction.half;
        if (correction.time !== undefined) {
            log.time = correction.time;
        }

        log.game = game;
        log.gameId = game.id;
        log.playNumber = game.playNumber;

        return [log];
    }

    createCoinTossLogs(coinToss: CoinToss, game: Game, targetHalf: number): PlayLog[] {
        let logs = [];
        let log: PlayLog;
        let result: RESULTS;

        if (coinToss.winner && targetHalf == 1) {
            result = RESULTS.COIN_TOSS;
            log = this.getCoinTossLog(coinToss.winner.id, result, game, targetHalf);
            logs.push(log);
        }

        if (coinToss.firstChoice) {
            result = this.getCoinTossResult(coinToss.firstChoice);
            log = this.getCoinTossLog(coinToss.winner.id, result, game, targetHalf);
            logs.push(log);
        }

        if (coinToss.secondChoice) {
            result = this.getCoinTossResult(coinToss.secondChoice);
            log = this.getCoinTossLog(game.getOtherTeamId(coinToss.winner.id), result, game, targetHalf);
            logs.push(log);
        }

        if (coinToss.thirdChoice) {
            result = this.getCoinTossResult(coinToss.thirdChoice);
            log = this.getCoinTossLog(coinToss.winner.id, result, game, targetHalf);
            logs.push(log);
        }

        return logs;
    }

    private initStats(log: PlayLog, game: Game, stats: { [playerId: number]: PlayerStats }) {

        let newPlayers = [];

        if (log.playerQId && !stats[log.playerQId]) newPlayers.push(log.playerQId);
        if (log.playerRId && !stats[log.playerRId]) newPlayers.push(log.playerRId);
        if (log.playerDId && !stats[log.playerDId]) newPlayers.push(log.playerDId);

        newPlayers.forEach(playerId => {
            stats[playerId] = new PlayerStats({
                registrationId: playerId,
                gameId: game.id,
                seasonId: game.seasonId,
            });
        });
    }

    private getCoinTossLog(teamId: any, result: RESULTS, game: Game, half: number): PlayLog {
        let log = new PlayLog();
        log.result = result;
        log.teamId = teamId;
        log.half = half;
        log.down = 1;
        log.game = game;
        log.gameId = game.id;
        log.playNumber = game.playNumber;

        return log;
    }

    private getReceptionFragments() {
        let fragments = [];
        let fragment: PlayFragment;
        let playerQ = this.playerButtonsService.findOnePlayer(States.QB);
        let playerR = this.playerButtonsService.findOnePlayer(States.R);
        let playersLat = this.playerButtonsService.findPlayers(States.Lat);

        if (!playerQ || !playerR) {
            throw new Error('QB or Receiver not set');
        }

        if (playersLat.length) {
            for (let i = 0; i < playersLat.length - 1; i++) {
                fragment = new PlayFragment();
                fragment.playerQ = playersLat[i].registration;
                fragment.playerR = playersLat[i + 1].registration;
                fragment.action = ACTIONS.HANDOFF;
                fragments.push(fragment);
            }

            fragment = new PlayFragment();
            fragment.playerQ = playersLat[playersLat.length - 1].registration;
            fragment.playerR = playerQ.registration;
            fragment.action = ACTIONS.HANDOFF;
            fragments.push(fragment);
        }

        fragment = new PlayFragment();
        fragment.playerQ = playerQ.registration;
        fragment.playerR = playerR.registration;
        fragment.action = ACTIONS.THROW;
        fragments.push(fragment);

        return fragments;
    }

    private getRushFragments() {
        let fragments = [];
        let fragment: PlayFragment;
        let playerQ = this.playerButtonsService.findOnePlayer(States.QB);
        let playerR = this.playerButtonsService.findOnePlayer(States.R);
        let playersLat = this.playerButtonsService.findPlayers(States.Lat);

        if (!playerQ) {
            throw new Error('QB not set');
        }

        if (playersLat.length) {
            fragment = new PlayFragment();
            fragment.playerQ = playerQ.registration;
            fragment.playerR = playersLat[0].registration;
            fragment.action = ACTIONS.HANDOFF;
            fragments.push(fragment);

            for (let i = 1; i < playersLat.length; i++) {
                fragment = new PlayFragment();
                fragment.playerQ = playerQ.registration;
                fragment.playerR = playersLat[i].registration;
                fragment.action = ACTIONS.HANDOFF;
                fragments.push(fragment);
            }

            if (playerR) {
                // fragment = this.createOffensivePlayFragment(playersLat[playersLat.length - 1], playerR, ACTIONS.HANDOFF, game.offenseTeam.id);
                fragment = new PlayFragment();
                fragment.playerQ = playersLat[playersLat.length - 1].registration;
                fragment.playerR = playerR.registration;
                fragment.action = ACTIONS.HANDOFF;
                fragments.push(fragment);
            }

        } else if (playerR) {
            // fragment = this.createOffensivePlayFragment(playerQ, playerR, ACTIONS.HANDOFF, game.offenseTeam.id);
            fragment = new PlayFragment();
            fragment.playerQ = playerQ.registration;
            fragment.playerR = playerR.registration;
            fragment.action = ACTIONS.HANDOFF;
            fragments.push(fragment);
        }

        let playerWithBall: Registration = !fragments.length ? playerQ.registration : fragments[fragments.length - 1].playerR;

        // fragment = this.createOffensivePlayFragment(playerWithBall, null, ACTIONS.RUN, game.offenseTeam.id);
        fragment = new PlayFragment();
        fragment.playerQ = playerWithBall;
        fragment.action = ACTIONS.RUN;
        fragments.push(fragment);

        return fragments;

    }

    private getFlagPullFragments(result: RESULTS) {

        let fragments = [];
        let fragment: PlayFragment;
        let playerD = this.playerButtonsService.findOnePlayer(States.FP);

        if (!playerD) {
            /**
             * If the play is not a touchdown, etc. then there should 
             * be a flag puller
             */
            if (![RESULTS.TD, RESULTS.DROP_LAT, RESULTS.SAFETY].includes(result)) {
                throw new Error('Defensive player (flag pull) not set');
            } else {
                return [];
            }
        }

        fragment = new PlayFragment();
        fragment.playerD = playerD.registration;
        fragment.action = ACTIONS.FLAG_PULL;
        fragments.push(fragment);

        return fragments;

    }

    private getDeflectionFragments() {

        let fragments = [];
        let fragment: PlayFragment;
        let playerD = this.playerButtonsService.findOnePlayer(States.D);

        if (!playerD) {
            throw new Error('Defensive player (deflection) not set');
        }

        fragment = new PlayFragment();
        fragment.playerD = playerD.registration;
        fragment.action = ACTIONS.DEFL;
        fragments.push(fragment);

        return fragments;
    }

    private getInterceptionFragments() {
        let fragments = [];
        let fragment: PlayFragment;
        let playerInt = this.playerButtonsService.findOnePlayer(States.Int);
        let playersLat = this.playerButtonsService.findPlayers(States.Lat);

        if (!playerInt) {
            throw new Error('Defensive player (interception) not set');
        }

        fragment = new PlayFragment();
        fragment.playerD = playerInt.registration;
        fragment.action = ACTIONS.INT;
        fragments.push(fragment);

        if (playersLat && playersLat.length > 0) {

            // fragment = this.createOffensivePlayFragment(playerInt, playersLat[0], ACTIONS.LATERAL, teamId);
            fragment = new PlayFragment();
            fragment.playerQ = playerInt.registration;
            fragment.playerR = playersLat[0].registration;
            fragment.action = ACTIONS.LATERAL;
            fragments.push(fragment);

            for (let i = 0; i < playersLat.length - 1; i++) {
                // fragment = this.createOffensivePlayFragment(playersLat[i], playersLat[i + 1], ACTIONS.LATERAL, teamId);
                fragment = new PlayFragment();
                fragment.playerQ = playersLat[i].registration;
                fragment.playerR = playersLat[i + 1].registration;
                fragment.action = ACTIONS.LATERAL;
                fragments.push(fragment);
            }
        }

        return fragments;
    }

    private getKickerFragments(action) {
        let fragments = [];
        let fragment: PlayFragment;
        let playerK = this.playerButtonsService.findOnePlayer(States.K);
        // let playerK = getPlayer(players, APP_PLAYERS.KICKER, allowMultiple);

        // fragment = this.createOffensivePlayFragment(playerK, null, action, teamId);
        fragment = new PlayFragment();
        fragment.playerQ = playerK.registration;
        fragment.action = action;
        fragments.push(fragment);

        return fragments;
    }

    private getKickReturnFragments(result) {
        let fragments = [];
        let fragment: PlayFragment;
        let playerReturn = this.playerButtonsService.findOnePlayer(States.RT);
        let playerFP = this.playerButtonsService.findOnePlayer(States.FP);
        let playersLat = this.playerButtonsService.findPlayers(States.Lat);
        if (!playerReturn && (playerFP || playersLat.length || result == RESULTS.TD)) {
            throw new Error('Kick returner not set');
        }

        if (playersLat !== null && playersLat.length > 0) {

            // fragment = this.createOffensivePlayFragment(playerReturn, playersLat[0], ACTIONS.LATERAL, teamId);
            fragment = new PlayFragment();
            fragment.playerQ = playerReturn.registration;
            fragment.playerR = playersLat[0].registration;
            fragment.action = ACTIONS.LATERAL;
            fragments.push(fragment);

            for (let i = 0; i < playersLat.length - 1; i++) {
                // fragment = this.createOffensivePlayFragment(playersLat[i], playersLat[i + 1], ACTIONS.LATERAL, teamId);
                fragment = new PlayFragment();
                fragment.playerQ = playersLat[i].registration;
                fragment.playerR = playersLat[i + 1].registration;
                fragment.action = ACTIONS.LATERAL;
                fragments.push(fragment);
            }
        }

        if (playerReturn) {
            let playerWithBall: Registration = !fragments.length ? playerReturn.registration : fragments[fragments.length - 1].playerR;

            // fragment = this.createOffensivePlayFragment(playerWithBall, null, ACTIONS.KICK_RETURN, teamId);
            fragment = new PlayFragment();
            fragment.playerQ = playerWithBall;
            fragment.action = ACTIONS.KICK_RT;
            fragments.push(fragment);
        }

        return fragments;
    }

    private getResultFragment(playInfo: PlayInfo, team?: Team) {

        let fragment = new PlayFragment();
        fragment.result = playInfo.result;
        fragment.disableEdits = true;
        fragment.team = team ? team : null;

        if (playInfo.result == RESULTS.TD) {
            fragment.isKickAfterTD = playInfo.isKickAfterTD;
            fragment.extraPoint = playInfo.extraPoint;
        }

        return fragment;
    }

    private getDistanceFragment(distance, result) {

        if (!distance) {
            throw new Error('Null distance');
        }

        let fragment = new PlayFragment();
        // fragment.distance = result == RESULTS.LOSS ? "Loss of " + distance : distance;
        fragment.distance = distance;
        fragment.isLoss = [RESULTS.LOSS, RESULTS.SAFETY].includes(result);
        fragment.disableEdits = true;
        return fragment;
    }

    private getDownFragment(game: Game, result, action) {
        let fragment = new PlayFragment();
        let newDown = game.down + 1;

        if ([RESULTS.FIRST, RESULTS.TD, RESULTS.DROP_LAT, RESULTS.SAFETY].includes(result) ||
            [Actions.INTERCEPTION, Actions.PUNT, Actions.KICK].includes(action)) {
            fragment.down = 1;
        } else {
            if (newDown <= game.totalDowns) {
                fragment.down = newDown;
            } else {
                fragment.down = 1;
                fragment.isTurnoverOnDowns = true;
            }
        }

        /* CALCULATE THE NEW POSSESSION */
        let currentPosession = game.posession;

        /** 
         * change posession if any of the following are true:
         *   
         *   1. Turnover on downs
         *   2. An interception, kick, or punt occured without a dropped lateral or touchdown afterwards
         *   3. A dropped lateral or touchdown occured without an interception, kick, or punt beforehand
         */

        let isTurnoverOnDowns = fragment.isTurnoverOnDowns;
        let isResultTurnover = [RESULTS.TD, RESULTS.DROP_LAT].includes(result);
        let isActionTurnover = [Actions.INTERCEPTION, Actions.KICK, Actions.PUNT].includes(action);

        // console.log({
        //     action: action,
        //     result: result,
        //     newDown: newDown,
        //     isTurnoverOnDowns: isTurnoverOnDowns,
        //     isResultTurnover: isResultTurnover,
        //     isActionTurnover: isActionTurnover,
        //     prevPosession: currentPosession,
        // });

        if (isTurnoverOnDowns || (isResultTurnover != isActionTurnover)) {
            currentPosession = game.getOtherTeamId(currentPosession);
        }

        // console.log('currentPos', currentPosession);

        fragment.team = game.getTeam(currentPosession);

        fragment.disableEdits = true;

        return fragment;
    }

    private doErrors(errors, messagePrefix) {
        if (errors.length > 0) {
            let errorMessage = messagePrefix + ' ';
            errorMessage += errors[0];

            if (errors.length > 1) {
                for (let i = 1; i < errors.length - 1; i++) {
                    errorMessage += ', ' + errors[i];
                }

                errorMessage += ', and ' + errors[errors.length - 1];
            }

            throw new Error(errorMessage);
        }
    }

    private generateYards(distance) {

        let start;
        let range;
        let end;

        if (distance === DISTANCES.MINI) {
            start = 1;
            end = 5;
        } else if (distance === DISTANCES.SHORT) {
            start = 5;
            end = 10;
        } else if (distance === DISTANCES.MEDIUM) {
            start = 10;
            end = 20;
        } else if (distance === DISTANCES.LONG) {
            start = 20;
            end = 40;
        } else if (distance === DISTANCES.MEGA) {
            start = 40;
            end = 60;
        } else {
            throw new Error('Distance not defined: ' + distance);
        }

        return Math.floor(Math.random() * (end - start + 1)) + start;
    }

    private isValidDownChange(from: number, to: number) {
        let difference = to - from;
        return to == 1 || difference == 0 || difference == 1;
    }

    private getCoinTossResult(option: CoinTossOption): RESULTS {
        switch (option) {
            case CoinTossOption.RECEIVE:
                return RESULTS.PICK_BALL;
            case CoinTossOption.SIDE:
                return RESULTS.PICK_SIDE;
            case CoinTossOption.DEFER:
                return RESULTS.PICK_DEFER;
            default:
                console.error('unkown coin toss option: ', option);
                throw new Error('unkown coin toss option: ' + option);
        }
    }

}