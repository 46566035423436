import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, ReplaySubject } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { League } from '../../classes/tfflModels/league';
import { HelperService } from '../util/helper.service';

@Injectable({
    providedIn: 'root'
})
export class LeaguesService {

    private leaguesSubject: ReplaySubject<League[]>;
    g: Observable<League>;

    constructor(
        private http: HttpClient,
        private helperService: HelperService,
        private sanitizer: DomSanitizer
    ) { }

    getLeagues(): Observable<League[]> {
        return this.loadLeagues();
    }

    getLeague(leagueId: any): Observable<League> {
        return this.loadLeagues().pipe(
            map(leagues => leagues.find(l => l.id == leagueId))
        );
    }

    private loadLeagues(): Observable<League[]> {
        if (!this.leaguesSubject) {
            this.leaguesSubject = new ReplaySubject<League[]>(1);

            let url = '/leagues';
            this.http.get<{ leagues: League[] }>(url).pipe(
                map((response) => response.leagues),
                map(leagues => this.extracLeagues(leagues)),
                catchError(this.helperService.handleError('getLeagues', []))
            ).subscribe(leagues => {
                this.leaguesSubject.next(leagues);
            });
        }

        return this.leaguesSubject.asObservable().pipe(first());
    }

    private extracLeagues(leagues: League[]): League[] {
        for (let i = 0; i < leagues.length; i++) {
            leagues[i] = League.fromServer(leagues[i]);

            let logo = leagues[i].logoUrl;
            if (typeof logo == 'string') {
                leagues[i].logoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('//' + logo);
            }
            let companyUrl = leagues[i].companyUrl;
            if (typeof companyUrl == 'string') {
                /** Companyurl already has https or http in the database */
                leagues[i].companyUrl = this.sanitizer.bypassSecurityTrustResourceUrl(companyUrl);
            }
            let waiverMessage = leagues[i].waiverMessage;
            if (typeof waiverMessage == 'string') {
                leagues[i].waiverMessage = this.sanitizer.bypassSecurityTrustHtml(waiverMessage);
            }
        }

        return leagues;
    }
}
