
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { GameBundle } from '../../classes/tfflModels/GameBundle';
import { GamesService } from '../tfflServices/games.service';

@Injectable({ providedIn: 'root' })
export class GameDetailResolver implements Resolve<GameBundle> {

    constructor(
        private gamesService: GamesService,
        private router: Router,
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GameBundle> {
        let gameId = +route.paramMap.get('gameId');

        /**
         * Using take(1) is essential. It ensures that the observable completes after
         * receiving 1 value. If we leave out take(1), then the page won't load
         */
        return this.gamesService.getGameInfo(gameId).pipe(
            take(1),
            map(gameBundle => {

                // if

                return gameBundle;
            })
        );
    }
}
