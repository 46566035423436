import { Coupon } from './coupon';
import { FriendRequest } from './friendrequest';
import { Program } from './program';
import { Season } from './seasons';
import { Team } from './team';
import { User } from './user';

export class Registration {

    /* The id of the registration */
    id: any;

    /* The the user that this registration is linked to */
    userId: any;
    user: User;

    /* The id of the program that this registration is linked to */
    programId: any;
    program: Program;

    /* The id of the season that this registration is linked to (if no program is set - like for a staff) */
    private _seasonId: any;
    private _season: Season;

    /* The team that this registration is linked to */
    teamId: any;
    team: Team;

    /* Coupon that is applied to this registration */
    private _couponId: any;
    private _coupon: Coupon;

    /* The team registration that this registration is linked to */
    teamRegistrationId: any;
    teamRegistration: Registration;

    referralUserId: any;
    registrationTypeId: RegistrationType;
    notes: string;
    school: string;
    camp: string;
    reason: string;
    isCaptain: boolean;
    jerseyNumber: string;
    friendRequests: FriendRequest[];
    sportscardsId: string;
    isFriendRequestsStepComplete: boolean;
    isNotesStepComplete: boolean;
    isVolunteerStepComplete: boolean;
    isSponsorStepComplete: boolean;
    isAwardMvp: boolean;
    isAwardMostImproved: boolean;
    isAwardSpirit: boolean;
    isWaiverSigned: boolean;
    salaryLowRate: number;
    salaryHighRate: number;
    salaryRateThreshold: number;
    creditUsed: number;
    status: RegistrationStatus;

    private _price: number;
    private _isPaid: boolean;

    /* Order that this registration was paid in */
    private _orderId: any;

    get isTeam(): boolean {
        return this.registrationTypeId == RegistrationType.TEAM;
    }

    get seasonId(): any {
        if (this.program && !this._seasonId) {
            return this.program.seasonId;
        } else {
            /** Return whatever _seasonId is - null/undefined matter here, as the 
             * toServer() function ignores undefined values, but keeps null
             * values */
            return this._seasonId; 
        }
    }

    set seasonId(s: any) {
        this._seasonId = s;
    }

    get season(): Season {
        if (this._season) {
            return this._season;
        } else if (this.program) {
            return this.program.season;
        } else {
            return null;
        }
    }

    set season(s: Season) {
        this._season = s;
    }

    get couponId(): any {
        return this.teamRegistration ?
            this.teamRegistration.couponId :
            this._couponId;
    }

    set couponId(c: any) {
        this._couponId = c;
    }

    get coupon(): Coupon {
        return this.teamRegistration ?
            this.teamRegistration.coupon :
            this._coupon;
    }

    set coupon(c: Coupon) {
        this._coupon = c;
    }

    get discountedPrice(): number {
        return !this.coupon ?
            this.price :
            this.coupon.getDiscountedPrice(this.price);
    }

    get price(): number {
        return this.teamRegistration ?
            this.teamRegistration._price :
            this._price;
    }

    set price(p: number) {
        this._price = p;
    }

    get isPaid(): boolean {
        return this.teamRegistration ?
            this.teamRegistration._isPaid :
            this._isPaid;
    }

    set isPaid(p: boolean) {
        this._isPaid = p;
    }

    get isCancelled(): boolean {
        return this.status == RegistrationStatus.CANCELLED;
    }

    get orderId(): any {
        return this.teamRegistration ?
            this.teamRegistration._orderId :
            this._orderId;
    }

    set orderId(o: any) {
        this._orderId = o;
    }

    get name(): string {
        return this.user ? this.user.name : null;
    }

    get firstName(): string {
        return this.user ? this.user.firstName : null;
    }

    get lastName(): string {
        return this.user ? this.user.lastName : null;
    }

    get nameReversed(): string {
        return this.user ? this.user.nameReversed : null;
    }

    get nameFirstInitial(): string {
        return this.user ? this.user.nameFirstInitial : null;
    }

    get teamName(): string {
        return this.team && this.team.name;
    }

    get teamColor() :string {
        return this.team && this.team.teamColor && this.team.teamColor.name
    }

    get creditAvailable(): number {
        return this.user ? Math.min(this.user.credit, this.discountedPrice) : 0;
    }

    get award(): string {
        let awards = [];
        if (this.isAwardMvp) awards.push('MVP');
        if (this.isAwardMostImproved) awards.push('Hard Hat');
        if (this.isAwardSpirit) awards.push('Sportsmanship');

        return awards.join(', ');
    }

    /**
     * There is no salary threshold if either salary rate is not set 
     * or the salary rate threshold is less than or equal to 1 (1 is 
     * included, because it makes the admin frontend easier, but 
     * really, it should be 0).
     */
    hasSalaryThreshold(): boolean {
        return this.salaryLowRate != 0 && this.salaryHighRate != 0 && this.salaryRateThreshold > 1;
    }

    constructor(data?: {
        id?: any,
        userId?: any,
        user?: User,
        seasonId?: any,
        season?: Season,
        programId?: any,
        program?: Program,
        teamId?: any,
        team?: Team,
        orderId?: any,
        couponId?: any,
        referralUserId?: any,
        coupon?: Coupon,
        teamRegistrationId?: any,
        teamRegistration?: Registration,
        registrationTypeId?: RegistrationType,
        notes?: string,
        school?: string,
        camp?: string,
        reason?: string,
        isPaid?: boolean,
        isCaptain?: boolean,
        price?: number,
        friendRequests?: FriendRequest[],
        jerseyNumber?: string,
        sportscardsId?: string,
        isFriendRequestsStepComplete?: boolean,
        isNotesStepComplete?: boolean,
        isVolunteerStepComplete?: boolean,
        isSponsorStepComplete?: boolean,
        isAwardMvp?: boolean,
        isAwardMostImproved?: boolean,
        isAwardSpirit?: boolean,
        isWaiverSigned?: boolean,
        salaryLowRate?: number,
        salaryHighRate?: number,
        salaryRateThreshold?: number,
        creditUsed?: number,
        status?: RegistrationStatus,
    }) {

        if (data) {
            this.id = data.id;
            this.user = data.user;
            this.userId = data.userId;
            this.seasonId = data.seasonId;
            this.season = data.season;
            this.program = data.program;
            this.programId = data.programId;
            this.team = data.team;
            this.teamId = data.teamId;
            this.orderId = data.orderId;
            this.couponId = data.couponId;
            this.referralUserId = data.referralUserId;
            this.coupon = data.coupon;
            this.teamRegistrationId = data.teamRegistrationId;
            this.teamRegistration = data.teamRegistration;
            this.registrationTypeId = data.registrationTypeId;
            this.notes = data.notes;
            this.school = data.school;
            this.camp = data.camp;
            this.reason = data.reason;
            this.isPaid = data.isPaid;
            this.isCaptain = data.isCaptain;
            this.price = data.price;
            this.friendRequests = data.friendRequests;
            this.jerseyNumber = data.jerseyNumber;
            this.sportscardsId = data.sportscardsId;
            this.isFriendRequestsStepComplete = data.isFriendRequestsStepComplete;
            this.isNotesStepComplete = data.isNotesStepComplete;
            this.isVolunteerStepComplete = data.isVolunteerStepComplete;
            this.isSponsorStepComplete = data.isSponsorStepComplete;
            this.isAwardMvp = data.isAwardMvp;
            this.isAwardMostImproved = data.isAwardMostImproved;
            this.isAwardSpirit = data.isAwardSpirit;
            this.salaryLowRate = data.salaryLowRate;
            this.salaryHighRate = data.salaryHighRate;
            this.salaryRateThreshold = data.salaryRateThreshold;
            this.isWaiverSigned = data.isWaiverSigned;
            this.creditUsed = data.creditUsed;
            this.status = data.status;
        }
    }

    static fromServer(registration: Registration): Registration {

        registration.isPaid = registration.isPaid == true;
        registration.isFriendRequestsStepComplete = registration.isFriendRequestsStepComplete || false;
        registration.isNotesStepComplete = registration.isNotesStepComplete || false;
        registration.isVolunteerStepComplete = registration.isVolunteerStepComplete || false;
        registration.isSponsorStepComplete = registration.isSponsorStepComplete || false;
        registration.isAwardMvp = registration.isAwardMvp || false;
        registration.isAwardMostImproved = registration.isAwardMostImproved || false;
        registration.isAwardSpirit = registration.isAwardSpirit || false;

        registration.price = +registration.price;
        registration.creditUsed = +registration.creditUsed;
        
        registration.salaryLowRate = +registration.salaryLowRate;
        registration.salaryHighRate = +registration.salaryHighRate;
        registration.salaryRateThreshold = +registration.salaryRateThreshold;
        
        if (registration.program) {
            registration.program = Program.fromServer(registration.program);
        }

        if (registration.season) {
            registration.season = Season.fromServer(registration.season);
        }

        if (registration.user) {
            registration.user = User.fromServer(registration.user);
        }

        if (registration.team) {
            registration.team = Team.fromServer(registration.team);
        }

        if (registration.coupon) {
            registration.coupon = Coupon.fromServer(registration.coupon);
        }

        if (registration.teamRegistration) {
            registration.teamRegistration = Registration.fromServer(registration.teamRegistration);
        }

        return new Registration(registration);
    }

    toServer(): {} {
        let data: any = {};

        if (this.id !== undefined) data.id = this.id;
        if (this.userId !== undefined) data.userId = this.userId;
        if (this.programId !== undefined) data.programId = this.programId;
        if (this.seasonId !== undefined) data.seasonId = this.seasonId;
        if (this.teamId !== undefined) data.teamId = this.teamId;
        if (this.couponId !== undefined) data.couponId = this.couponId;
        if (this.referralUserId !== undefined) data.referralUserId = this.referralUserId;
        if (this.teamRegistrationId !== undefined) data.teamRegistrationId = this.teamRegistrationId;
        if (this.registrationTypeId !== undefined) data.registrationTypeId = this.registrationTypeId;
        if (this.status !== undefined) data.status = this.status;
        if (this.notes !== undefined) data.notes = this.notes;
        if (this.school !== undefined) data.school = this.school;
        if (this.camp !== undefined) data.camp = this.camp;
        if (this.reason !== undefined) data.reason = this.reason;
        if (this.isPaid !== undefined) data.isPaid = this.isPaid;
        if (this.isCaptain !== undefined) data.isCaptain = this.isCaptain;
        if (this.isWaiverSigned !== undefined) data.isWaiverSigned = this.isWaiverSigned;
        if (this.jerseyNumber !== undefined) data.jerseyNumber = this.jerseyNumber;
        if (this.sportscardsId !== undefined) data.sportscardsId = this.sportscardsId;
        if (this.isFriendRequestsStepComplete !== undefined) data.isFriendRequestsStepComplete = this.isFriendRequestsStepComplete;
        if (this.isNotesStepComplete !== undefined) data.isNotesStepComplete = this.isNotesStepComplete;
        if (this.isVolunteerStepComplete !== undefined) data.isVolunteerStepComplete = this.isVolunteerStepComplete;
        if (this.isSponsorStepComplete !== undefined) data.isSponsorStepComplete = this.isSponsorStepComplete;
        if (this.friendRequests !== undefined) data.friendRequests = this.friendRequests;

        return data;
    }
}

export enum RegistrationStatus {
    ACTIVE = 'ACTIVE',
    OFFERED = 'OFFERED',
    HIRED = 'HIRED',
    CANCELLED = 'CANCELLED',
}

export enum RegistrationType {
    COACH = "COACH",
    PLAYER = "PLAYER",
    STAFF = "STAFF",
    TEAM = "TEAM",
}