/**
 * This class is used to load specific games that a user wants. 
 * Some examples of filters include:
 *  
 *  - by multiple player ids (ex. parents checking all the games of their
 *  3 children)
 *  - by program (ex. player checking the times of all the games in their
 *  league)
 *  - by startdate and/or enddate (can be in conjuction with other filters)
 *  - by team ids (ex. player checking all of the games for a rival team)
 * 
 * Note, a person should be able to filter by multiple teams, players,
 * and programs, but only by one type at a time. For example, a person
 * should not be able to filter games for 3 teams and 2 players. They 
 * must filter by one or the other.
 */
export class GamesFilter {
    startDate: Date;
    endDate: Date;
    programIds: any[];
    divisionId: any;
    teamIds: any[];
    seasonId: any;
    includeTbd: boolean;

    constructor(data?: {
        startDate?: Date,
        endDate?: Date,
        programIds?: any[],
        divisionId?: any,
        teamIds?: any[],
        seasonId?: any,
        includeTbd?: boolean,
    }) {

        if (data) {
            this.startDate = data.startDate;
            this.endDate = data.endDate;
            this.programIds = data.programIds;
            this.divisionId = data.divisionId;
            this.teamIds = data.teamIds;
            this.seasonId = data.seasonId;
            this.includeTbd = data.includeTbd;
        }
    }

    /**
     * This sets the start time to the beginning of the day and the end time
     * to the end of the day
     * 
     * @param date
     */
    setDate(date: Date) {
        this.startDate = this.toBeginningOfDay(date),
            this.endDate = this.toEndOfDay(date)
    }

    getQuery() {

        let params = new URLSearchParams();

        /**
         * start_time must be in seconds
         */
        if (this.startDate) {
            params.append('start_time', (this.startDate.getTime() / 1000) + '');
        }

        /**
         * end_time must be in seconds
         */
        if (this.endDate) {
            params.append('end_time', (this.endDate.getTime() / 1000) + '');
        }

        if (this.seasonId) {
            params.append('season_id', this.seasonId);
        }

        if (this.programIds) {
            this.programIds.forEach(id => params.append('program_ids[]', id));
        }

        if (this.divisionId) {
            params.append('division_id', this.divisionId);
        }

        if (this.teamIds && this.teamIds.length) {
            this.teamIds.forEach(id => params.append('team_ids[]', id));
        }

        return params.toString();
    }

    private toBeginningOfDay(date: Date) {
        let newDate = new Date(date.getTime());
        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0);
        newDate.setMilliseconds(0);

        return newDate;
    }

    private toEndOfDay(date: Date) {
        let newDate = new Date(date.getTime());
        newDate.setHours(23);
        newDate.setMinutes(59);
        newDate.setSeconds(59);
        newDate.setMilliseconds(0);

        return newDate;
    }
}