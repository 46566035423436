export class ProgramDay {

    id: any;
    programId: any;
    day: string;
    startTime: Date;
    endTime: Date;

    constructor(data?: {
        id?: any,
        programId?: any,
        day?: string,
        startTime?: Date,
        endTime?: Date,
    }) {

        if (data) {
            this.id = data.id;
            this.programId = data.programId;
            this.day = data.day;
            this.startTime = data.startTime;
            this.endTime = data.endTime;
        }
    }

    static fromServer(programDay: ProgramDay): ProgramDay {
        programDay.startTime = this.toDate(programDay.startTime);
        programDay.endTime = this.toDate(programDay.endTime);
        programDay = new ProgramDay(programDay);

        return programDay;
    }

    /** Converts a string in hh:mm:ss format to a Date object, where the date is the current date (since we only care about time) */
    private static toDate(timeString): Date {

        if (!timeString) {
            return null;
        }

        let pieces = timeString.split(':');

        let d = new Date();
        d.setHours(+pieces[0]);
        d.setMinutes(+pieces[1]);
        d.setSeconds(+pieces[2]);

        return d;
    }
}