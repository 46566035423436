import { SafeHtml } from "@angular/platform-browser";

export class News {

    id: any;
    title: string;
    content: SafeHtml | string;
    order: number;

    constructor(data?: {
        id?: any,
        title?: string,
        content?: SafeHtml,
        order?: number,
    }) {

        if (data) {
            this.id = data.id;
            this.title = data.title;
            this.content = data.content;
            this.order = data.order;
        }
    }

    static fromServer(news: News): News {
        news.order = +news.order;
        news = new News(news);

        return news;
    }
}