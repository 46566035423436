import { AbstractControl, Validators, ValidationErrors } from '@angular/forms';

export const CustomValidators = {
    passwordValidator(control: AbstractControl): ValidationErrors | null {
        let pass = control.get('password');
        let confirm = control.get('confirmPassword');
        if (!pass || !confirm || pass.value === confirm.value) return null;
        return { nomatch: true };
    },
    resetPasswordValidator(control: AbstractControl): ValidationErrors | null {
        let current = control.get('currentPassword');
        let newPass = control.get('newPasswords.password');
        if (!newPass || !current || current.value !== newPass.value) return null;
        return { nomatch: true };
    },
    hasIdValidator(control: AbstractControl): ValidationErrors | null {
        let val = control.value;
        if (val && val.id) return null;
        return { message: 'no id' };
    },
}