import { ACTIONS, RESULTS } from '../constants/stats';
import { Registration } from '../tfflModels/registration';
import { Team } from '../tfflModels/team';

export class PlayFragment {
    playerQId: any;
    playerRId: any;
    playerDId: any;
    _playerQ: Registration;
    _playerR: Registration;
    _playerD: Registration;
    team: Team;
    action: ACTIONS;
    distance: string;
    down: number;
    disableEdits: boolean;
    isLoss: boolean;
    isTurnoverOnDowns: boolean;
    result: RESULTS;
    extraPoint: number;
    isKickAfterTD: boolean;

    set playerQ(reg: Registration) {
        this._playerQ = reg;
        this.playerQId = reg.id;
    }

    set playerR(reg: Registration) {
        this._playerR = reg;
        this.playerRId = reg.id;
    }

    set playerD(reg: Registration) {
        this._playerD = reg;
        this.playerDId = reg.id;
    }

    get playerQ(): Registration {
        return this._playerQ;
    }

    get playerR(): Registration {
        return this._playerR;
    }

    get playerD(): Registration {
        return this._playerD;
    }

    get pointsScored(): number {
        if (this.result == RESULTS.SAFETY) {
            return 1;
        } else if (!isNaN(this.extraPoint)) {
            return this.extraPoint + 6;
        }

        return null;
    }
}