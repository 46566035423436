/**
 * This class is used to load specific users, such as based on their name
 */
export class StaffHoursFilter {
    registrationIds: any[];
    locationId: any;
    date: any;

    constructor(data?: {
        registrationIds?: any[],
        locationId?: any,
        date?: any
    }) {

        if (data) {
            this.registrationIds = data.registrationIds;
            this.locationId = data.locationId;
            this.date = data.date;
        }
    }

    getQuery() {

        let params = new URLSearchParams();

        if (this.registrationIds) {
            this.registrationIds.forEach(id => params.append('registration_ids[]', id));
        }

        if (this.locationId) {
            params.append('location_id', this.locationId);
        }

        if (this.date) {
            params.append('date', this.date);
        }
        
        return params.toString();
    }
}