import { Component } from '@angular/core';
import { NgbTabsetConfig } from '@ng-bootstrap/ng-bootstrap';
import { ResponsiveService } from '@tffl/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(
        private ngbTabsConfig: NgbTabsetConfig,
        private responsiveService: ResponsiveService,
    ) {
        this.initSettings();
    }

    initSettings() {
        this.responsiveService.deviceChanged.subscribe(_ => {
            if (this.responsiveService.isMobile) {
                this.initMobile();
            } else {
                this.initDesktop();
            }
        });
    }

    initDesktop() {
        this.ngbTabsConfig.justify = 'start';
    }

    initMobile() {
        this.ngbTabsConfig.justify = 'fill';
    }
}
