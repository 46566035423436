import { Registration } from "./registration";
import { Season } from "./seasons";
import { Team } from "./team";

export class Sponsor {
    id: any;
    userId: any;
    registrationId: any;
    registration: Registration;
    teamId: any;
    team: Team;
    seasonId: any;
    season: Season;
    orderId: any;
    title: string;
    price: number;
    isPaid: boolean;
    isCancelled: boolean;
    logoUrl: string;
    
    constructor(data?: {
        id?: any,
        userId?: any,
        registrationId?: any,
        registration?: Registration,
        teamId?: any,
        team?: Team,
        seasonId?: any,
        season?: Season,
        orderId?: any,
        title?: string,
        price?: number,
        isPaid?: boolean,
        isCancelled?: boolean,
        logoUrl?: string,
    }) {

        if (data) {
            this.id = data.id;
            this.userId = data.userId;
            this.registrationId = data.registrationId;
            this.registration = data.registration;
            this.teamId = data.teamId;
            this.team = data.team;
            this.seasonId = data.seasonId;
            this.season = data.season;
            this.orderId = data.orderId;
            this.title = data.title;
            this.price = data.price;
            this.isPaid = data.isPaid;
            this.isCancelled = data.isCancelled;
            this.logoUrl = data.logoUrl;
        }
    }

    static fromServer(sponsor: Sponsor): Sponsor {

        sponsor.price = +sponsor.price;

        if (sponsor.season) {
            sponsor.season = Season.fromServer(sponsor.season);
        }

        if (sponsor.team) {
            sponsor.team = Team.fromServer(sponsor.team);
        }

        if (sponsor.registration) {
            sponsor.registration = Registration.fromServer(sponsor.registration);
        }

        sponsor = new Sponsor(sponsor);
        return sponsor;
    }
}