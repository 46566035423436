import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TfflCoreConfig, TfflCoreModule } from '@tffl/core';
import { Angulartics2Module } from 'angulartics2';
import { ResponsiveModule } from 'ngx-responsive';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';

const CORE_CONFIG: TfflCoreConfig = {
    apiBase: environment.apiBase,
    leagueId: null,
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CommonModule,
        // BrowserModule,
        RouterModule,
        HttpClientModule,
        BrowserAnimationsModule,
        TfflCoreModule.forRoot(CORE_CONFIG),
        CoreModule,
        ResponsiveModule.forRoot(),
        Angulartics2Module.forRoot({
            pageTracking: {
                clearQueryParams: true,
            },
            developerMode: environment.angulartics2developerMode
        }),
        AppRoutingModule,
    ],
    providers: [
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
