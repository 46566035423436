import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, concatMap, first, map, tap } from 'rxjs/operators';
import { RESULTS } from '../../classes/constants/stats';
import { AttendanceFilter } from '../../classes/filters/attendanceFilter';
import { EvaluationFilter } from '@tffl/core/classes/filters/evaluationFilter';
import { GamesFilter } from '../../classes/filters/gamesFilter';
import { TeamsFilter } from '../../classes/filters/teamsFilter';
import { PlayInfo } from '../../classes/statsApp/playInfo';
import { Attendance } from '../../classes/tfflModels/attendance';
import { Evaluation } from '@tffl/core/classes/tfflModels/evaluation';
import { Game } from '../../classes/tfflModels/game';
import { GameBundle } from '../../classes/tfflModels/GameBundle';
import { PlayerStats } from '../../classes/tfflModels/playerStats';
import { PlayLog } from '../../classes/tfflModels/playLog';
import { SeasonStandings } from '../../classes/tfflModels/seasonStanding';
import { Team } from '../../classes/tfflModels/team';
import { TeamStanding } from '../../classes/tfflModels/teamStanding';
import { DateHelper } from '../../classes/util/dateHelper';
import { HelperService } from '../util/helper.service';
import { GameEngineService } from './game-engine.service';
import { LogsService } from './logs.service';
import { TeamsService } from './teams.service';

export interface GameGroup {
    date: Date,
    games: Game[]
};

interface GameCacheItem {
    game: Game,
    filterReplayQueries: Set<string>
}

@Injectable({ providedIn: 'root' })
export class GamesService {

    private gameCache: { [gameId: string]: GameCacheItem } = {};
    private gameFilterReplays: { [queryKey: string]: ReplaySubject<Game[]> } = {};
    private gameFilterList: { [queryKey: string]: string[] } = {};

    private url = '/games';

    constructor(
        private http: HttpClient,
        private logsService: LogsService,
        private gameEngineService: GameEngineService,
        private teamsService: TeamsService,
        private helperService: HelperService,
    ) { }

    getGameStream(filter: GamesFilter): Observable<Game[]> {
        if (!filter) {
            throw new Error('The games filter must be set to obtain a stream of games');
        }

        let query = filter.getQuery();

        if (!this.gameFilterReplays[query]) {
            this.gameFilterReplays[query] = new ReplaySubject<Game[]>(1);
            this.getGames(filter).subscribe();
        }

        return this.gameFilterReplays[query].asObservable();
    }

    getGames(filter: GamesFilter, useCache: boolean = false): Observable<Game[]> {

        if (!filter) {
            throw new Error('The games filter must be set to get games');
        }

        let filterQuery = filter.getQuery();
        let queryParams = '?' + filterQuery;

        if (useCache && this.gameFilterReplays[filterQuery]) {
            return this.gameFilterReplays[filterQuery].pipe(first());
        }

        let gameObs: Observable<{ games: Game[] }> = null;

        if (filter.includeTbd && !filter.teamIds) {
            throw new Error('Cannot include tbd without any teams');
        } else if (filter.includeTbd) {
            /**
             * Call the teams endpoint if includeTbd is true
             */
            let url = this.url + '/by-team' + queryParams;
            gameObs = this.http.get<{ games: Game[] }>(url);
        } else {
            let url = this.url + queryParams;
            gameObs = this.http.get<{ games: Game[] }>(url);
        }

        return gameObs.pipe(
            map(response => response.games),
            map(this.extractGames), /* IMPORTANT */
            tap(games => this.updateCache(games, filterQuery)),
            catchError(this.helperService.handleError('getGames', []))
        );

    }

    getGame(id: any): Observable<Game> {
        try {
            return of(this.findGame(id));
        } catch (e) {
            return this.http.get<{ game: Game }>(this.url + '/' + id).pipe(
                map(response => response.game),
                map(Game.fromServer), /* IMPORTANT */
                tap(game => this.updateCache([game])),
                catchError(this.helperService.handleError('getGame', null))
            );
        }
    }

    getGameInfo(id: any): Observable<GameBundle> {
        return this.getGame(id).pipe(
            concatMap((game: Game) => {

                let homeTeamObs = game.homeTeamId ?
                    this.teamsService.getTeam(game.homeTeamId) :
                    of(null);
                let awayTeamObs = game.awayTeamId ?
                    this.teamsService.getTeam(game.awayTeamId) :
                    of(null);
                let attendanceFilter = new AttendanceFilter({ gameIds: [game.id] });

                return forkJoin(
                    homeTeamObs,
                    awayTeamObs,
                    this.logsService.getLogs(game.id),
                    this.getAttendances(attendanceFilter),
                ).pipe(
                    /* map the teams back to the game */
                    map(([homeTeam, awayTeam, logs, attendances]) => {

                        let stats = [];

                        game.homeTeam = homeTeam;
                        game.awayTeam = awayTeam;

                        if (game.isPlayable()) {
                            let results = this.simulateGame(game, homeTeam, awayTeam, logs);
                            stats = results.stats;
                        }

                        console.log({
                            game,
                            logs,
                            attendances,
                            stats,
                        })

                        return {
                            game: game,
                            logs: logs,
                            attendances: attendances,
                            stats: stats,
                        };
                    })
                )
            })
        );

    }

    getStandings({ seasonId, programId, divisionId }: {
        seasonId?: any,
        programId?: any,
        divisionId?: any
    }): Observable<SeasonStandings> {
        let filter = new GamesFilter();
        let teamFilter = new TeamsFilter();

        if (seasonId) {
            filter.seasonId = seasonId;
            teamFilter.seasonId = seasonId;
        }

        if (programId) {
            filter.programIds = [programId];
            teamFilter.programIds = [programId];
        }

        if (divisionId) {
            filter.divisionId = divisionId;
            teamFilter.divisionIds = [divisionId];
        }

        return forkJoin(
            this.teamsService.getTeams(teamFilter),
            this.getGames(filter, true),
        ).pipe(
            map(([teams, games]) => this.calculateStandings(teams, games)),
            map(standings => this.groupStandings(standings))
        );
    }

    /**
     * Used to sort team standings that have identical scores
     */
    compareStandingsByPointsPerGame(a: TeamStanding, b: TeamStanding): number {

        if (!a || !b) {
            console.error('cannot compare standings, at least one is null', a, b);
            throw new Error('cannot compare standings, at least one is null');
        }

        let aPoints = a.standingPointsPerGame;
        let bPoints = b.standingPointsPerGame;

        if (aPoints != bPoints) {
            return bPoints - aPoints;
        }

        /**
         * Tiebreaking rules:
         * 
         * 1. Highest total number of wins
         * 2. Head-to-head record
         * 3. Best net points in games between the teams (PF minus PA in head-to-head games)
         * 4. Best net points in all games; and then
         * 5. Best PA in all games
         */
        let winDiff = a.wins - b.wins;
        if (winDiff != 0) return winDiff;

        let aH2H = a.headToHead[b.teamId] || TeamStanding.newHeadToHead();
        let bH2H = b.headToHead[a.teamId] || TeamStanding.newHeadToHead()

        let h2HWinsDiff = aH2H.wins - bH2H.wins;
        if (h2HWinsDiff != 0) return h2HWinsDiff;

        let h2HPointsDiff = (aH2H.pointsFor - aH2H.pointsAgainst) - (bH2H.pointsFor - bH2H.pointsAgainst);
        if (h2HPointsDiff != 0) return h2HPointsDiff;

        let netPointsDiff = (a.pointsFor - a.pointsAgainst) / a.gamesPlayed - (b.pointsFor - b.pointsAgainst) / b.gamesPlayed;
        if (netPointsDiff != 0) return netPointsDiff;

        let pointsAgainstDiff = b.pointsAgainst / b.gamesPlayed - a.pointsAgainst / a.gamesPlayed;
        if (pointsAgainstDiff != 0) return pointsAgainstDiff;
    }

    updateGame(game: Game): Observable<Game> {
        return this.http.put<{ game: Game }>(this.url + '/' + game.id, { game: game.toServer() }).pipe(
            map(response => response.game),
            map(Game.fromServer), /* IMPORTANT */
            tap(game => this.updateCache([game])),
            catchError(this.helperService.handleError('updated game', null))
        );
    }

    create(game: Game): Observable<Game> {
        return this.http.post<{ game: Game }>(this.url, { game: game.toServer() }).pipe(
            map(response => response.game),
            map(Game.fromServer), /* IMPORTANT */
            tap(game => this.updateCache([game])),
            catchError(this.helperService.handleError('created game', null))
        );
    }

    getAttendances(filter: AttendanceFilter): Observable<Attendance[]> {
        let params = '?' + filter.getQuery();
        return this.http.get<{ attendance: Attendance[] }>('/attendance' + params).pipe(
            map(response => response.attendance),
            map(this.extractAttendance), /* IMPORTANT */
            catchError(this.helperService.handleError('getAttendances', null))
        );
    }

    updateAttendance(attendance: Attendance): Observable<any> {
        let data = { attendance: attendance };
        return this.http.post<{}>(this.url + '/actions/attendance', data).pipe(
            catchError(this.helperService.handleError('set attendance', null))
        )
    }

    getEvaluations(filter: EvaluationFilter): Observable<Evaluation[]> {
        let params = '?' + filter.getQuery();
        return this.http.get<{ evaluation: Evaluation[] }>('/evaluation' + params).pipe(
            map(response => response.evaluation),
            map(this.extractEvaluation), /* IMPORTANT */
            catchError(this.helperService.handleError('getEvaluations', null))
        );
    }

    createEvaluation(evaluation: Evaluation): Observable<any> {
        let data = { evaluation: evaluation };
        return this.http.post<{ evaluation: Evaluation}>(this.url + '/actions/create-evaluation', data).pipe(
            map(response => Evaluation.fromServer(response.evaluation)),
            catchError(this.helperService.handleError('create evaluation', null))
        )
    }

    updateEvaluation(evaluation: Evaluation): Observable<any> {
        let data = { evaluation: evaluation };
        return this.http.put<{ evaluation: Evaluation}>(this.url + '/actions/update-evaluation', data).pipe(
            map(response => Evaluation.fromServer(response.evaluation)),
            catchError(this.helperService.handleError('set evaluation', null))
        )
    }

    resetGame(gameId: any) {

        return this.http.post<{ game: Game, clonedGame: Game }>(this.url + '/' + gameId + '/actions/reset', {}).pipe(
            map(response => response.game),
            map(Game.fromServer),
            tap(game => this.updateCache([game])),
            catchError(this.helperService.handleError('set attendance', null))
        );
    }

    groupGamesByDate(games: Game[]): { date: Date, games: Game[] }[] {
        let groups = [];
        let group;
        let prevGame: Game;

        for (let i = 0; i < games.length; i++) {

            let isDifferentDate = prevGame == null ||
                !DateHelper.isSameDate(games[i].startTime, prevGame.startTime);

            if (isDifferentDate) {

                if (group) {
                    groups.push(group);
                }

                group = {
                    date: games[i].startTime,
                    games: []
                };
            }

            group.games.push(games[i]);
            prevGame = games[i];
        }

        if (group) {
            groups.push(group);
        }

        return groups;
    }

    private simulateGame(
        game: Game,
        homeTeam: Team,
        awayTeam: Team,
        logs: PlayLog[]): {
            stats: PlayerStats[],
            plays: PlayInfo[],
            game: Game
        } {

        game.posession = homeTeam.id;

        /**
         * Update the game on the logs (for the players and teams)
         */
        logs.map(log => log.game = game);

        /**
         * If the down is not set, set it to first down
         */
        if (!game.down) {
            game.down = 1;
        }

        /**
         * If the half is not set, set it to 1
         */
        if (!game.half) {
            game.half = 1;
        }

        /**
         * If the play is not set, set it to 1
         */
        if (!game.playNumber) {
            game.playNumber = 1;
        }

        /**
         * If the time is not set, set it to the normal time for a half
         */
        if (!game.time) {
            game.time = game.halfDuration;
        }

        /** 
         * If the game possession is not set, set it according to the first kickoff
         */
        if (!game.posession) {
            game.posession = logs.find(log => log.result == RESULTS.KICK).teamId || game.homeTeamId;
        }

        /**
         * Reset the score to 0 - 0 (simulating the logs will set the score)
         */

        let calculatedHome = game.homeScore;
        let calculatedAway = game.awayScore;

        game.homeScore = 0;
        game.awayScore = 0;

        let result = this.gameEngineService.simulatePlays(logs, game);

        if (game.homeScore != calculatedHome ||
            game.awayScore != calculatedAway) {
            this.logError(game, logs, calculatedHome, calculatedAway);
        }

        return result;
    }

    private findGame(id: any): Game {
        let cacheItem = this.gameCache[id];
        if (!cacheItem) {
            throw new Error('Invalid id. No game exists with the id: ' + id);
        }
        // let teams = [cacheItem.game.homeTeam, cacheItem.game.awayTeam].filter(t => !!t);
        // let isPopulated = !teams.some(t => !t.registrations);
        // if (!isPopulated) {
        //     throw new Error('Team registrations not populated');
        // }
        return cacheItem.game;
    }

    private extractAttendance(attendances: Attendance[]) {
        for (let i = 0; i < attendances.length; i++) {
            attendances[i] = Attendance.fromServer(attendances[i]);
        }

        return attendances;
    }

    private extractEvaluation(evaluations: Evaluation[]) {
        for (let i = 0; i < evaluations.length; i++) {
            evaluations[i] = Evaluation.fromServer(evaluations[i]);
        }

        return evaluations;
    }

    private extractGames(games: Game[]) {
        for (let i = 0; i < games.length; i++) {
            games[i] = Game.fromServer(games[i]);
        }

        return games;
    }

    private emitGameFilterReplay(filterQuery: string): void {
        // console.log('emitting game filter replay', filterQuery);
        let gameIds = this.gameFilterList[filterQuery];
        let games = gameIds.map(id => this.gameCache[id].game);

        this.gameFilterReplays[filterQuery].next(games);
    }

    private updateCache(games: Game[], filterQuery?: string) {

        /**
         * Updated queries is the list of filter queries that are affected
         * by the changes
         */
        let updatedQueries = new Set<string>();

        games.forEach(g => {
            if (!this.gameCache[g.id]) {
                this.gameCache[g.id] = {
                    game: g,
                    filterReplayQueries: new Set<string>()
                }
            } else {
                this.gameCache[g.id].game = g;
                this.gameCache[g.id].filterReplayQueries.forEach(filterQuery => {
                    updatedQueries.add(filterQuery);
                });
            }

            if (filterQuery) {
                this.gameCache[g.id].filterReplayQueries.add(filterQuery);
            }
        });

        if (filterQuery) {
            updatedQueries.add(filterQuery);
            this.gameFilterList[filterQuery] = games.map(g => g.id);
        }

        updatedQueries.forEach(updatedFilter => {
            if (this.gameFilterReplays[updatedFilter]) {
                this.emitGameFilterReplay(updatedFilter);
            }
        });

    }

    private groupStandings(standings: TeamStanding[]): SeasonStandings {

        let seasonStandings = new SeasonStandings();

        standings.forEach(s => {
            seasonStandings.addStanding(s);
        });

        let groups = seasonStandings.getAllStandings();

        Object.keys(groups).forEach(key => {
            groups[key].standings.sort((s1, s2) => this.compareStandingsByPointsPerGame(s1, s2));
            groups[key].standings.forEach((s, index) => {
                s.place = index + 1;
            });
        });

        return seasonStandings;
    }

    private calculateStandings(teams: Team[], games: Game[]): TeamStanding[] {
        let teamStandings: { [teamId: number]: TeamStanding } = {};

        teams.forEach(t => {
            if (t.program) {
                teamStandings[t.id] = new TeamStanding({
                    teamId: t.id,
                    programId: t.programId,
                    programName: t.programName,
                    seasonId: t.program.seasonId,
                    divisionId: t.divisionId,
                    divisionName: t.divisionName,
                    teamName: t.name,
                    teamLogoUrl: t.logoUrl,
                    isAdult: t.program.isAdultLeague()
                });
            }
        });

        games.forEach(g => {

            /**
             * Skip over incomplete games
             */
            if (!g.isComplete || !g.isPlayable()) {
                return;
            }

            /**
             * Initialize the home team standings
             */
            if (!teamStandings[g.homeTeamId]) {
                teamStandings[g.homeTeamId] = new TeamStanding({
                    teamId: g.homeTeamId,
                    programId: g.programId,
                    programName: g.programName,
                    seasonId: g.seasonId,
                    divisionId: g.divisionId,
                    divisionName: g.divisionName,
                    teamName: g.homeTeam.name,
                    teamLogoUrl: g.homeTeam.logoUrl,
                    isAdult: g.isAdultLeague
                });
            }

            /**
             * Initialize the away team standings
             */
            if (!teamStandings[g.awayTeamId]) {
                teamStandings[g.awayTeamId] = new TeamStanding({
                    teamId: g.awayTeamId,
                    programId: g.programId,
                    programName: g.programName,
                    seasonId: g.seasonId,
                    divisionId: g.divisionId,
                    divisionName: g.divisionName,
                    teamName: g.awayTeam.name,
                    teamLogoUrl: g.awayTeam.logoUrl,
                    isAdult: g.isAdultLeague
                });
            }

            /**
             * Add the games for the standings
             */
            teamStandings[g.homeTeamId].addGame(g);
            teamStandings[g.awayTeamId].addGame(g);
        })

        return Object.values(teamStandings);
    }

    private logError(game: Game, logs: PlayLog[], calculatedHome, calculatedAway) {
        console.warn('There is a score inconsistency. The game\'s score does ' +
            'not match the game logs simulation. (Showing: Simulated game,' +
            'database game, logs)' +
            game,
            {
                homeScore: calculatedHome,
                awayScore: calculatedAway,
            },
            logs
        );
    }
}