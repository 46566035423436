import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// import { News } from '../../classes/tfflModels/news';
import { News } from '../../classes/tfflModels/news';
import { HelperService } from '../util/helper.service';
import { LogsService } from './logs.service';

@Injectable({
    providedIn: 'root'
})
export class NewsService {

    constructor(
        private http: HttpClient,
        private logsService: LogsService,
        private helperService: HelperService,
        private sanitizer: DomSanitizer
    ) { }

    getNews(): Observable<News[]> {

        return this.http.get<{ news: News[] }>('/news').pipe(
            map(response => response.news),
            map(news => this.extractNews(news)), /* IMPORTANT */
            catchError(this.helperService.handleError('get news', []))
        );
    }

    private extractNews(news: News[]) {
        for (let i = 0; i < news.length; i++) {
            this.sanitize(news[i]);
            news[i] = News.fromServer(news[i]);
        }

        return news;
    }

    private sanitize(newsItem: News) {
        if (typeof newsItem.content == 'string') {
            newsItem.content = this.sanitizer.bypassSecurityTrustHtml(newsItem.content);
        }
    }
}
