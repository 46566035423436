
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { ResponsiveService } from './responsive.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {

    constructor(
        public authService: AuthService,
        public router: Router,
        private responsiveService: ResponsiveService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        if (!this.authService.isLoggedIn()) {

            this.authService.setReturnUrl(state.url);
            this.router.navigate(['/account/login']);

            return false;
        }

        return true;
    }

}
