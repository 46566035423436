import { SafeHtml, SafeUrl } from "@angular/platform-browser";

export class BlogPost {
    title: string;
    link: string | SafeUrl;
    comments: string;
    pubDate: Date;
    description: string | SafeHtml;
    content: string;
    thumbnail: string | SafeUrl;

    constructor(data?: {
        title?: string,
        link?: string | SafeUrl,
        comments?: string,
        pubDate?: Date,
        description?: string | SafeHtml,
        content?: string,
        thumbnail?: string | SafeUrl,
    }) {

        if (data) {
            this.title = data.title;
            this.link = data.link;
            this.comments = data.comments;
            this.pubDate = data.pubDate;
            this.description = data.description;
            this.content = data.content;
            this.thumbnail = data.thumbnail;
        }
    }

    static fromServer(post: BlogPost): BlogPost {
        post.pubDate = post.pubDate ? new Date(post.pubDate) : null;
        post = new BlogPost(post);
        return post;
    }
}
