export class TeamColor {
    id: any;
    name: string;
    /**
     * The hex value of the color. Ex. PRIMARY_COLOR
     */
    hex: string;

    constructor(data?: { 
        id?: any,
        name?: string,
        hex?: string,
    }) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.hex = data.hex;
        }
    }

    static fromServer(teamColor: TeamColor): TeamColor {
        teamColor = new TeamColor(teamColor);
        return teamColor;
    }
}