import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
export class Email {
    to: string [];
    from: string; 
    cc: string [];
    bcc: string [];
    message: string;
    attachments: string [];

    constructor(data?: {
        to?: string [],
        from?: string, 
        cc?: string [],
        bcc?: string [],
        message?: string,
        attachments?: string [],
    }) {

        if (data) {
            this.to = data.to;
            this.from = data.from; 
            this.cc = data.cc;
            this.bcc = data.bcc;
            this.message = data.message;
            this.attachments = data.attachments;
        }
    }

    static getFormGroup(fb: FormBuilder): FormGroup {

		let defaultValues = {
            addresses: [],
            message: ''
		}

		let form = fb.group({			
			addresses: fb.array(defaultValues.addresses, Validators.required),
			message: [defaultValues.message, Validators.required]
        });
        
        return form;
    }

    static toEmail(form: FormGroup) {
        let model = form.value;

		let email = new Email({
			to: model.addresses,
            message: model.message,
		});

		return email;
    }
}