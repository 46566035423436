export class Token {
    _token: string;
    userId: any;
    expires: number;
    tokenContent: any;

    get token(): string {
        return this._token;
    }

    set token(token: string) {
        this._token = token;
        try {
            this.tokenContent = this.parseJwt(token);
        } catch (e) {}
    }
    
    constructor(data?: { 
        userId?: any,
        expires?: number,
        token?: string,
    }) {

        if (data) {
            this.userId = data.userId;
            this.expires = data.expires;
            this.token = data.token;
        }  
    }

    static fromServer(token: Token) {
        return new Token(token);
    }

    private parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }
}