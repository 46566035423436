
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuardService } from '@tffl/core';
import { AuthGuardStaffService } from './core/services/auth-guard-staff.service';

const routes: Routes = [
    {
        path: 'games',
        canActivate: [AuthGuardStaffService],
        loadChildren: './gamecast/gamecast.module#GamecastModule'
    },
    {
        path: 'seasons',
        canActivate: [AuthGuardStaffService],
        loadChildren: './league/league.module#LeagueModule'
    },
    {
        path: 'login',
        canActivate: [LoginGuardService],
        loadChildren: './auth/auth.module#AuthModule'
    },
    {
        path: '**',
        redirectTo: '/seasons',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        // enableTracing: true
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }