import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Order } from '../../classes/tfflModels/order';
import { HelperService } from '../util/helper.service';
import { RegistrationsService } from './registrations.service';

@Injectable({ providedIn: 'root' })
export class OrdersService {

    private url = '/orders';

    constructor(
        private http: HttpClient,
        private helperService: HelperService,
        private registrationsService: RegistrationsService,
        private angulartics2: Angulartics2
    ) { }

    create(registrationIds: any[], sponsorIds: any[]) {
        return this.http.post<{ order: Order }>(this.url, { registrationIds: registrationIds, sponsorIds: sponsorIds }).pipe(
            map((response) => response.order),
            map(Order.fromServer), /* IMPORTANT */
            catchError(this.helperService.handleError('create order', null))
        );
    }

    getOrder(orderId: any): Observable<Order> {
        return this.http.get<{ order: Order }>(this.url + '/' + orderId).pipe(
            map((response) => response.order),
            map(Order.fromServer), /* IMPORTANT */
            catchError(this.helperService.handleError('get order', null))
        )
    }

    getInvoice(orderId: any) {
        return this.http.get<any>(this.url + '/' + orderId + '/invoice').pipe(
            catchError(this.helperService.handleError('get order', null))
        );
    }

    pay(orderId: any, cardId: any) {

        this.doPaymentAnalytics(orderId);

        let data = {
            cardId: cardId
        };
        return this.http.post<{ chargeId: string }>(this.url + '/' + orderId + '/actions/charge', data).pipe(
            map((response) => response.chargeId),
            tap(() => this.registrationsService.invalidateCache()),
            catchError(this.helperService.handleError('charge', null))
        );
    }

    private doPaymentAnalytics(orderId: any) {
        /**
         * Fire off the sponsor tracking event
         */
        this.getOrder(orderId).subscribe(order => {
            this.angulartics2.eventTrack.next({
                action: 'Purchase',
                properties: {
                    value: {
                        value: order.total,
                        currency: 'CAD',
                        orderId: order.id,
                        order: order.createdDate,
                        subtotal: order.subtotal,
                        discount: order.discount,
                        discountedPrice: order.discountedPrice,
                        creditedPrice: order.creditedPrice,
                        creditUsed: order.creditUsed,
                        tax: order.tax,
                        total: order.total,
                    }
                }
            });
        });
    }
}
