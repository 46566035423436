import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { UsersFilter } from '../../classes/filters/usersFilter';
import { RelationshipSide, RelationshipType, User } from '../../classes/tfflModels/user';
import { HelperService } from '../util/helper.service';

@Injectable({ providedIn: 'root' })
export class UsersService {

    private url = '/users';
    private cache = {}; // { [userId: any]: User[] }
    private relatedUsersCache = {};// { [userId: any]: User[] }

    signupReasonOptions: { key: string, value: string }[] = [
        { key: 'Friend', value: 'Through a friend' },
        { key: 'Google', value: 'Google search' },
        { key: 'Facebook', value: 'Facebook' },
        { key: 'Camp', value: 'Participated in Camp Program' },
        { key: 'School', value: 'Participated in School Program' },
        { key: 'Other', value: 'Other' },
    ]

    constructor(
        private http: HttpClient,
        private helperService: HelperService,
        private angulartics2: Angulartics2,
    ) { }

    search(term: string): Observable<User[]> {
        if (term.length < 3) {
            return of([]);
        }

        let filter = new UsersFilter();
        filter.search = term;

        let url = this.url + '?' + filter.getQuery();

        return this.http.get<{ users: User[] }>(url).pipe(
            map((response) => response.users),
            map(this.extractUsers), /* IMPORTANT */
            catchError(this.helperService.handleError('userSearch', null))
        );
    }

    getUsers(ids: any): Observable<User[]> {

        if (!ids || !ids.length) {
            throw new Error('ids must be set');
        }

        let params = new URLSearchParams();
        params.append('user_ids[]', ids);
        let paramsStr = params.toString();
        let url = this.url;
        if (paramsStr) url += '?' + paramsStr;

        return this.http.get<{ users: User[] }>(url)
            .pipe(
                map((response) => response.users),
                map(this.extractUsers), /* IMPORTANT */
                catchError(this.helperService.handleError('getUser', null))
            );
    }

    getUser(userId: any): Observable<User> {

        if (this.cache[userId] != null) {
            return of(this.cache[userId]);
        } else {

            return this.http.get<{ user: User }>(this.url + '/' + userId)
                .pipe(
                    map((response) => response.user),
                    map(User.fromServer), /* IMPORTANT */
                    tap(user => {
                        if (user && user.id) {
                            this.cache[user.id] = user;
                        }
                    }),
                    catchError(this.helperService.handleError('getUser', null))
                );
        }
    }

    getUserSensitiveInfo(userId: any): Observable<User> {
        return this.http.get<{ user: User }>(this.url + '/' + userId + '/sensitive-info')
            .pipe(
                map((response) => response.user),
                map(User.fromServer), /* IMPORTANT */
                catchError(this.helperService.handleError('getUserSensitiveInfo', null))
            );
    }

    updateSensitiveInfo(user: User): Observable<User> {
        // return this.http.post<{ user: User }>(this.url + '/' + userId + '/sensitive-info')
        //     .pipe(
        //         map((response) => response.user),
        //         map(User.fromServer), /* IMPORTANT */
        //         catchError(this.helperService.handleError('getUserSensitiveInfo', null))
        //     );

        if (!user) {
            throw new Error('User cannot be null');
        }

        return this.http.put<{ user: User }>(this.url + '/' + user.id + '/sensitive-info', { user: user })
            .pipe(
                map((response) => response.user),
                map(User.fromServer), /* IMPORTANT */
                tap(() => this.invalidateCache()),
                catchError(this.helperService.handleError('updateSensitiveUserInfo', user))
            );
    }

    getRelatedParents(id: any): Observable<User[]> {
        return this.getRelatedUsers(id).pipe(
            map(users => users.filter(user => user.isParent()))
        );
    }

    getRelatedChildren(id: any): Observable<User[]> {
        return this.getRelatedUsers(id).pipe(
            map(users => users.filter(user => user.isChild()))
        );
    }

    getRelatedUsers(id: any): Observable<User[]> {
        if (this.relatedUsersCache[id]) {
            return of(this.relatedUsersCache[id]);
        } else {
            return this.http.get<{ relatedUsers: User[] }>(this.url + '/' + id + '/related-users')
                .pipe(
                    map((response) => response.relatedUsers),
                    map(this.extractUsers), /* IMPORTANT */
                    tap(users => this.updateRelatedUsersCache(users, id)),
                    catchError(this.helperService.handleError('get related Users', []))
                );
        }
    }

    createChild(user: User): Observable<User> {

        this.doNewUserAnalytics(user, true);

        let url = this.url + '/actions/create-child';
        return this.http.post<{ user: User }>(url, { user: user })
            .pipe(
                catchError((err, caught) => {
                    /**
                     * If the email address is set on the user, try creating again,
                     * but leave out the email address.
                     */
                    if (user.email) {
                        let noEmailUser = new User(user);
                        noEmailUser.email = null;
                        return this.http.post<{ user: User }>(url, { user: noEmailUser });
                    } else {
                        throw err;
                    }
                }),
                map((response) => response.user),
                map(User.fromServer), /* IMPORTANT */
                tap(() => this.invalidateCache()),
                catchError(this.helperService.handleError('createUser', user))
            );
    }

    createSelf(user: User, password: string): Observable<User> {

        this.doNewUserAnalytics(user, false);

        let url = this.url + '/actions/create';
        return this.http.post<{ user: User }>(url, { user: user, password: password })
            .pipe(
                map((response) => response.user),
                map(User.fromServer), /* IMPORTANT */
                tap(() => this.invalidateCache()),
                catchError(this.helperService.handleError('createUser', user))
            );
    }

    createAdult(user: User, childUserIds?: any[]): Observable<User> {

        let url = this.url + '/actions/create-adult';

        let data: any = {
            user: user,
        }
        if (childUserIds && childUserIds.length) data.childUserIds = childUserIds;

        return this.http.post<{ user: User }>(url, data)
            .pipe(
                map((response) => response.user),
                map(User.fromServer), /* IMPORTANT */
                tap(() => this.invalidateCache()),
                catchError(this.helperService.handleError('createUser', user))
            );
    }

    update(user: User): Observable<User> {
        if (!user) {
            throw new Error('User cannot be null');
        }

        return this.http.put<{ user: User }>(this.url + '/' + user.id, { user: user })
            .pipe(
                map((response) => response.user),
                map(User.fromServer), /* IMPORTANT */
                tap(() => this.invalidateCache()),
                catchError(this.helperService.handleError('updateUser', user))
            );
    }

    changePassword(userId: any, currentPassword: string, newPassword: string): Observable<User> {
        let data = {
            currentPassword: currentPassword,
            newPassword: newPassword,
        };
        return this.http.post<{}>(this.url + '/' + userId + '/actions/change-password', data).pipe(
            tap(() => this.invalidateCache()),
            catchError(this.helperService.handleError('changePassword', null))
        );
    }

    changeEmail(userId: any, currentPassword: string, email: string): Observable<User> {
        let data = {
            currentPassword: currentPassword,
            email: email,
        };
        return this.http.post<{}>(this.url + '/' + userId + '/actions/change-email', data).pipe(
            tap(() => this.invalidateCache()),
            catchError(this.helperService.handleError('changeEmail', null))
        );
    }

    resetPassword(email: any): Observable<void> {
        let data = {
            email: email,
        };
        return this.http.post<{}>(this.url + '/' + 'actions/reset-password', data).pipe(
            catchError(this.helperService.handleError('reset-password', null))
        );
    }

    isAuthorized(parentId: any, childId: any): Observable<boolean> {

        if (!parentId || !childId) {
            return of(false);
        }

        if (parentId == childId) {
            return of(true);
        }

        return this.getRelatedUsers(parentId).pipe(
            map((users: User[]) => {
                let isAuthorized = users.some(user => {
                    return (
                        user.relationSide == RelationshipSide.CHILD &&
                        user.id == childId
                    );
                });
                return isAuthorized;
            })
        )
    }

    addRelationship(relationshipType: RelationshipType, parentUserId: any, childUserId: any): Observable<void> {
        let data = {
            relationshipType: relationshipType,
            parentUserId: parentUserId,
            childUserId: childUserId,
        };
        return this.http.post<{}>(this.url + '/actions/add-relationship', data).pipe(
            tap(() => this.invalidateCache()),
            catchError(this.helperService.handleError('changePassword', null))
        );
    }

    /* This converts the json response from the server to properly instantiated instances of the class */
    private extractUsers(users: User[]): User[] {
        for (let i = 0; i < users.length; i++) {
            users[i] = User.fromServer(users[i]);
        }

        return users;
    }

    private updateRelatedUsersCache(users: User[], id: any) {
        this.relatedUsersCache[id] = users;
    }

    private invalidateCache() {
        this.relatedUsersCache = {};
        this.cache = {};
    }

    private getNextTempId() {
        return 'Id1234'; //return a temporary id
    }

    private doNewUserAnalytics(user: User, isChild: boolean) {
        /**
         * Fire off the registration tracking event
         */
        this.angulartics2.eventTrack.next({
            action: 'CreateAccount',
            properties: {
                value: {
                    userId: user.id,
                    user: user.birthdate,
                    isChild: isChild
                }
            }
        });
    }
    // private pushNotification(user: User, isChild: Boolean) {

    //     /**
    //      * Don't create a child notification, since the parent would have 
    //      * done it to register
    //      */
    //     if (!isChild) {
    //         let notificationType = NotificationType.CreatedUser;

    //         let notification = new Notification<User>(user, notificationType);
    //         this.notificationService.push(notification);
    //     }
    // }

}
