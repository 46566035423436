import { Injectable } from '@angular/core';
import { Actions, PlayerButton, Sides, States } from '../../classes/statsApp/playerButton';
import { Attendance } from '../../classes/tfflModels/attendance';
import { Registration, RegistrationType } from '../../classes/tfflModels/registration';
import { Team } from '../../classes/tfflModels/team';

@Injectable({ providedIn: 'root' })
export class PlayerButtonsService {

    private players: PlayerButton[] = [];
    private action: Actions;
    private attendances: Attendance[];

    constructor(
    ) { }

    clear() {
        this.players = [];
    }

    updateColors(team: Team) {
        this.players.forEach(player => {
            if (player.registration.team.id == team.id) {
                player.registration.team.color = team.color;
                player.updateColors();
            }
        });

    }

    setTeams(offenseTeam: Team, defenseTeam: Team, attendances: Attendance[]) {
        this.clear();
        if (offenseTeam) {
            this.addTeam(offenseTeam, Sides.OFFENSE, attendances);
        }
        if (defenseTeam) {
            this.addTeam(defenseTeam, Sides.DEFENSE, attendances);
        }
    }

    getOffenseTeam(): Team {
        let players = this.getSide(Sides.OFFENSE);
        if (!players.length) {
            throw new Error("No players found on offense");
        }
        return players[0].registration.team;
    }

    getDefenseTeam(): Team {
        let players = this.getSide(Sides.DEFENSE);
        if (!players.length) {
            throw new Error("No players found on defense");
        }
        return players[0].registration.team;
    }

    setAction(action: Actions) {
        let oldAction = this.action;
        this.action = action;
        this.switchAction();
    }

    switchSides() {
        this.players.map(player => player.side = player.side == Sides.OFFENSE ? Sides.DEFENSE : Sides.OFFENSE);
        this.players.map(player => player.state = null);
    }

    nextState(player: PlayerButton) {

        if (!this.action) {
            throw new Error('Action not defined');
        }

        if (player.side == Sides.OFFENSE) {

            switch (this.action) {
                case Actions.COMPLETION:
                case Actions.RUN:
                case Actions.INCOMPLETION:
                    if (!player.state && !this.existsPlayer(States.QB)) {
                        player.state = States.QB;
                    } else if ((!player.state || player.state == States.QB) && !this.existsPlayer(States.R)) {
                        player.state = States.R;
                    } else if (!player.state || player.state == States.R || player.state == States.QB) {
                        player.state = States.Lat;
                    } else {
                        player.state = null;
                    }
                    break;
                case Actions.KICK:
                case Actions.PUNT:
                    if (!player.state && !this.existsPlayer(States.K)) {
                        player.state = States.K;
                    } else if (player.state == States.K && !this.existsPlayer(States.FP)) {
                        player.state = States.FP;
                    } else if (player.state == States.K) {
                        player.state = null;
                    } else if (player.state == States.FP) {
                        player.state = null;
                    } else {
                        this.clearPlayers(States.FP);
                        player.state = States.FP;
                    }
                    break;
                case Actions.INTERCEPTION:
                    if (!player.state) {
                        this.clearPlayers(States.FP);
                        player.state = States.FP;
                    } else {
                        player.state = null;
                    }
                    break;
            }

        } else if (player.side == Sides.DEFENSE) {

            switch (this.action) {
                case Actions.COMPLETION:
                case Actions.RUN:
                    if (!player.state) {
                        this.clearPlayers(States.FP);
                        player.state = States.FP;
                    } else {
                        player.state = null;
                    }
                    break;
                case Actions.INCOMPLETION:
                    if (!player.state) {
                        this.clearPlayers(States.D);
                        player.state = States.D;
                    } else {
                        player.state = null;
                    }
                    break;
                case Actions.KICK:
                case Actions.PUNT:
                    if (!player.state && !this.existsPlayer(States.RT)) {
                        player.state = States.RT;
                    } else if (!player.state || player.state == States.RT) {
                        player.state = States.Lat;
                    } else {
                        player.state = null;
                    }
                    break;
                case Actions.INTERCEPTION:
                    if (!player.state && !this.existsPlayer(States.Int)) {
                        player.state = States.Int;
                    } else if (!player.state || player.state == States.Int) {
                        player.state = States.Lat;
                    } else {
                        player.state = null;
                    }
                    break;
            }

        }
    }

    swapOffense(oldState, newState) {
        this.getOffensePlayers().filter(p => p.state == oldState).map(player => player.state = newState);
    }

    swapDefense(oldState, newState) {
        this.getDefensePlayers().filter(p => p.state == oldState).map(player => player.state = newState);
    }

    /**
     * Returns whether there was a player that was found
     * 
     * @param oldState 
     * @param newState 
     */
    swapFirstOffense(oldStateOrder, newState) {
        for (let i = 0; i < oldStateOrder.length; i++) {
            let oldState = oldStateOrder[i];
            let player = this.players.find(p => p.state == oldState && p.side == Sides.OFFENSE);

            if (player) {
                player.state = newState;
                return;
            }
        }
    }

    swapFirstDefense(oldStateOrder, newState) {

        for (let i = 0; i < oldStateOrder.length; i++) {
            let oldState = oldStateOrder[i];
            let player = this.players.find(p => p.state == oldState && p.side == Sides.DEFENSE);

            if (player) {
                player.state = newState;
                return;
            }
        }
    }

    getSide(side: Sides): PlayerButton[] {
        return this.players.filter(p => p.side == side && this.isAttending(p.registration));
    }

    existsPlayer(state: States): boolean {
        return this.players.some(p => p.state == state);
    }

    findPlayers(state: States): PlayerButton[] {
        return this.players.filter(p => p.state == state);
    }

    findOnePlayer(state: States): PlayerButton {
        return this.players.find(p => p.state == state);
    }

    reset() {
        this.players.filter(p => p.state != null && p.state != States.QB).forEach(player => player.state = null);
    }

    getActivePlayers() {
        return this.players.filter(p => p.state != null);
    }


    private switchAction() {

        if (!this.action) {
            throw new Error('Action not defined');
        }

        switch (this.action) {
            case Actions.COMPLETION:
            case Actions.RUN:

                /* Set the QB */
                this.swapFirstOffense([States.QB, States.K, States.Lat, States.K, States.FP], States.QB);

                /* Set the Receiver */
                this.swapFirstOffense([States.R, States.K, States.K, States.FP], States.R);

                /* reset others, leave QB, receiver and laterals */
                this.swapOffense(States.FP, null);
                this.swapOffense(States.K, null);

                /* Set the flag puller */
                this.swapFirstDefense([States.FP, States.RT, States.Int, States.D, States.Lat], States.FP);

                /* reset others */
                this.swapDefense(States.RT, null);
                this.swapDefense(States.Int, null);
                this.swapDefense(States.D, null);
                this.swapDefense(States.Lat, null);

                break;
            case Actions.INCOMPLETION:
                /* reset all offense, except for QB AND Receiver */
                this.swapOffense(States.FP, null);
                this.swapOffense(States.K, null);
                this.swapOffense(States.Lat, null);

                /* Set the deflector */
                this.swapFirstDefense([States.D, States.Int, States.FP, States.RT, States.Lat], States.D);

                /* reset others */
                this.swapDefense(States.RT, null);
                this.swapDefense(States.Int, null);
                this.swapDefense(States.FP, null);
                this.swapDefense(States.Lat, null);
                break;
            case Actions.KICK:
            case Actions.PUNT:

                /* Set the kicker */
                this.swapFirstOffense([States.K, States.QB, States.R, States.Lat], States.K);

                /* Set the Flag puller */
                this.swapFirstOffense([States.QB, States.R, States.Lat], States.FP);

                /* Reset others, leave flag puller and the kicker */
                this.swapOffense(States.QB, null);
                this.swapOffense(States.R, null);
                this.swapOffense(States.Lat, null);

                /* Set the kick returner */
                this.swapFirstDefense([States.RT, States.Int, States.FP, States.D, States.Lat], States.RT);

                /* reset others, leave returner and laterals */
                this.swapDefense(States.Int, null);
                this.swapDefense(States.FP, null);
                this.swapDefense(States.D, null);
                this.swapDefense(States.Lat, null);

                break;
            case Actions.INTERCEPTION:

                /* Set the flag puller */
                this.swapFirstOffense([States.FP, States.QB, States.R, States.Lat, States.K], States.FP);

                /* reset others, leave flag puller */
                this.swapOffense(States.QB, null);
                this.swapOffense(States.R, null);
                this.swapOffense(States.K, null);
                this.swapOffense(States.Lat, null);

                /* Set the interceptor */
                this.swapFirstDefense([States.Int, States.RT, States.FP, States.D, States.Lat], States.Int);

                /* reset others, leave interceptor and laterals */
                this.swapDefense(States.RT, null);
                this.swapDefense(States.FP, null);
                this.swapDefense(States.D, null);

                break;
        }
    }

    private addTeam(team: Team, side: Sides, attendances: Attendance[]) {

        this.attendances = attendances;

        if (!team.registrations) {
            console.error('The team has no registrations', team);
            throw new Error('The Team has no registrations');
        }

        /**
         * Sort by jersey number
         */
        let sortedRegistrations = team.registrations.sort((a, b) => {

            let aNum: number;
            let bNum: number;

            aNum = parseInt(a.jerseyNumber);
            if (isNaN(aNum)) {
                return -1;
            }

            bNum = parseInt(b.jerseyNumber);
            if (isNaN(bNum)) {
                return 1;
            }

            return aNum > bNum ? 1 : -1;
        });

        for (let i = 0; i < team.registrations.length; i++) {
            if (team.registrations[i].registrationTypeId == RegistrationType.PLAYER) {
                this.addPlayer(team.registrations[i], side);
            }
        }
    }

    private addPlayer(registration: Registration, side: Sides) {
        this.players.push(new PlayerButton({
            registration: registration,
            state: null,
            side: side
        }));
    }

    private getOffensePlayers(): PlayerButton[] {
        return this.players.filter(player => player.side == Sides.OFFENSE);
    }

    private getDefensePlayers(): PlayerButton[] {
        return this.players.filter(player => player.side == Sides.DEFENSE);
    }

    private isAttending(registration: Registration) {
        return this.attendances.some(attendance => {
            return attendance.registrationId == registration.id && attendance.isAttending
        });
    }

    private clearPlayers(state: States) {
        this.findPlayers(state).map(p => p.state = null);
    }

    private getPlayer(regId: any) {
        let player = this.players.find(p => p.registration.id == regId);

        if (!player) {
            throw new Error('No player button exists for registration id ' + regId);
        }

        return player;
    }
}