import { User } from "./user";

export class Chat {
    
    id: any;
    senderUserId: any;
    senderUser: User;
    recipientTeamId: any;
    recipientUserId: any;
    message: string;
    type: string;
    createdDate: Date;

    constructor(data?: {
        id?: any,
        senderUserId: any,
        senderUser: User,
        recipientTeamId: any,
        recipientUserId: any,
        message?: string,
        type?: string,
        createdDate?: Date,
    }) {

        if (data) {
            this.id = data.id;
            this.senderUserId = data.senderUserId;
            this.senderUser = data.senderUser;
            this.recipientTeamId = data.recipientTeamId;
            this.recipientUserId = data.recipientUserId;
            this.message = data.message;
            this.type = data.type;
            this.createdDate = data.createdDate;
        }
    }

    static fromServer(chat: Chat): Chat {
        if (chat.senderUser) {
            chat.senderUser = User.fromServer(chat.senderUser);
        }
        chat.createdDate = chat.createdDate ? new Date(chat.createdDate) : null;
        chat = new Chat(chat);
        return chat;
    }
}