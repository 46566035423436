import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Coupon } from '../../classes/tfflModels/coupon';
import { NotificationService } from '../other/notification.service';
import { HelperService } from '../util/helper.service';

@Injectable({ providedIn: 'root' })
export class CouponsService {

    private url = '/coupons';

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService,
        private helperService: HelperService,
    ) { }

    getCoupons(couponName: string): Observable<Coupon[]> {
        return this.http.get<{ coupons: Coupon[] }>(this.url + '/' + couponName).pipe(
            map(response => response.coupons),
            map(this.extractCoupons), /* IMPORTANT */
            catchError(this.helperService.handleError('getCoupons', []))
        );
    }

    private extractCoupons(coupons: Coupon[]) {
        return coupons.map(coupon => Coupon.fromServer(coupon));
    }
}
