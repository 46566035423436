import { Registration } from './registration';
export class Order {
    id: any;
    isPaid: boolean;
    cardId: any;
    chequeNumber: any;
    items: Registration [];
    subtotal: number;
    discount: number;
    discountedPrice: number;
    creditedPrice: number;
    creditUsed: number;
    tax: number;
    total: number;
    createdDate: Date;
    paymentDate: Date;

    constructor(data?: {
        id?: any,
        isPaid?: boolean,
        cardId?: any,
        chequeNumber?: any,
        items?: Registration [],
        subtotal?: number,
        discount?: number,
        discountedPrice?: number,
        creditedPrice?: number,
        creditUsed?: number,
        tax?: number,
        total?: number,
        createdDate?: Date,
        paymentDate?: Date,
    }) {
        if (data) {
            this.id = data.id;
            this.isPaid = data.isPaid;
            this.cardId = data.cardId;
            this.chequeNumber = data.chequeNumber;
            this.items = data.items;
            this.subtotal = data.subtotal;
            this.discount = data.discount;
            this.discountedPrice = data.discountedPrice;
            this.creditedPrice = data.creditedPrice;
            this.creditUsed = data.creditUsed;
            this.tax = data.tax;
            this.total = data.total;
            this.createdDate = data.createdDate;
            this.paymentDate = data.paymentDate;
        }
    }


    static fromServer(order: Order): Order {

        order.createdDate = order.createdDate ? new Date(order.createdDate) : null;
        order.paymentDate = order.paymentDate ? new Date(order.paymentDate) : null;
        order.subtotal = +order.subtotal;
        order.discount = +order.discount;
        order.discountedPrice = +order.discountedPrice;
        order.creditedPrice = +order.creditedPrice;
        order.creditUsed = +order.creditUsed;
        order.tax = +order.tax;
        order.total = +order.total;
        order.isPaid = order.isPaid || false;

        if (order.items) {
            order.items.map(item => Registration.fromServer(item));
        }

        return new Order(order);
    }
}