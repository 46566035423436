import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TeamsFilter } from '../../classes/filters/teamsFilter';
import { Chat } from '../../classes/tfflModels/chat';
import { Registration } from '../../classes/tfflModels/registration';
import { Team } from '../../classes/tfflModels/team';
import { TeamColor } from '../../classes/tfflModels/team-colors';
import { HelperService } from '../util/helper.service';

@Injectable({ providedIn: 'root' })
export class TeamsService {

    private teamCache: { [teamId: string]: Team } = {};

    private colorsCache: TeamColor[] = null;

    private url = '/teams';

    constructor(
        private http: HttpClient,
        private helperService: HelperService,
    ) { }

    getTeams(filter?: TeamsFilter): Observable<Team[]> {
        let url = this.url;

        if (filter) {
            url += '?' + filter.getQuery();
        }

        return this.http.get<{ teams: Team[] }>(url)
            .pipe(
                map(result => result.teams),
                map(this.extractTeams), /* IMPORTANT */
                tap(teams => this.updateCache(teams)),
                catchError(this.helperService.handleError('getTeams', []))
            );
    }

    getTeam(id: any): Observable<Team> {

        if (!id) {
            throw new Error('Cannot retrieve null team: ' + id);
        }

        try {
            return of(this.findTeam(id));
        } catch (e) {
            return this.http.get<{ team: Team, registrations: Registration[] }>(this.url + '/' + id)
                .pipe(
                    map(result => {
                        let team = result.team;
                        team.registrations = result.registrations;
                        return team;
                    }),
                    map(Team.fromServer), /* IMPORTANT */
                    tap(team => this.updateCache([team])),
                    catchError(this.helperService.handleError('getTeam', null))
                );
        }
    }

    searchTeam(teamName: string, programId: any): Observable<Team> {

        if (!programId || !teamName) {
            console.error('Team name and program id must be set. You passed, ', { teamName, programId });
            throw new Error('Team name and program id must be set');
        }

        let f = new TeamsFilter({ programIds: [programId] });
        return this.getTeams(f).pipe(
            map(teams => {

                let lowerCaseName = teamName && teamName.toLocaleLowerCase();
                let matching = teams.filter(t => t.name && t.name.toLocaleLowerCase() == lowerCaseName);

                if (!matching.length) {
                    throw new Error('No teams found with the specified program and team name');
                } else if (matching.length == 1) {
                    return matching[0];
                }
                throw new Error('No teams found with the specified program and team name');
            })
        );
    }

    getFranchiseTeams(franchiseId: any): Observable<Team[]> {
        return this.getTeams()
            .pipe(
                map(teams => this.filterTeams(teams, franchiseId)),
                catchError(this.helperService.handleError('getFranchiseTeams', []))
            );
    }

    create(team: Team): Observable<Team> {

        if (team == null) {
            throw new Error('Team cannot be null');
        }

        return this.http.post<{ team: Team }>(this.url, { team: team.toServer() })
            .pipe(
                map(result => result.team),
                map(Team.fromServer), /* IMPORTANT */
                tap(team => this.updateCache([team])),
                catchError(this.helperService.handleError('createTeam', team))
            );
    }

    update(team: Team): Observable<Team> {
        if (team == null || !team.id) {
            throw new Error('Team cannot be null and id must be set');
        }

        return this.http.put<{ team: Team }>(this.url + '/' + team.id, { team: team.toServer() }).pipe(
            map(response => response.team),
            map(Team.fromServer), /* IMPORTANT */
            tap(team => this.updateCache([team])),
            catchError(this.helperService.handleError('updated team', null))
        );
    }

    getMessages(teamId: any): Observable<Chat[]> {
        let url = '/chat/' + teamId;
        return this.http.get<{ messages: Chat[] }>(url).pipe(
            map(result => result.messages),
            map(chats => chats.map(c => Chat.fromServer(c))), /* IMPORTANT */
            catchError(this.helperService.handleError('get team messages', []))
        );
    }

    messageTeam(teamId: any, message: string, recipient: 'team' | 'coaches'): Observable<void> {
        let data = {
            message: message,
            recipient: recipient,
        };
        let url = '/chat/' + teamId + '/actions/message';
        return this.http.post<any>(url, data).pipe(
            catchError(this.helperService.handleError('send team message', null))
        );
    }

    getColors(): Observable<TeamColor[]> {

        if (this.colorsCache) {
            return of(this.colorsCache);
        }

        return this.http.get<{ colors: TeamColor[] }>(this.url + '/colors').pipe(
            map(response => response.colors),
            map(colors => colors.map(TeamColor.fromServer)), /* IMPORTANT */
            tap(colors => this.updateColorsCache(colors)),
            catchError(this.helperService.handleError('updated team', null))
        );
    }

    getTeamLink(team: Team): string {
        return encodeURI('http://torontoflagfootball.com/programs/' + team.seasonId + '/' + team.programId + '/register/join-team/' + team.name);
    }

    private filterTeams(teams: Team[], franchiseId: any): Team[] {

        let franchiseTeams = [];

        for (let i = 0; i < teams.length; i++) {
            if (teams[i].franchiseId == franchiseId) {
                franchiseTeams.push(teams[i]);
            }
        }

        return franchiseTeams;
    }

    private findTeam(id: any): Team {
        let team = this.teamCache[id];
        if (!team) {
            throw new Error('Invalid id. No team exists with the id: ' + id);
        }
        if (!team.registrations) {
            throw new Error('No registrations for the team');
        }

        return team;
    }

    private extractTeams(teams: Team[]): Team[] {
        for (let i = 0; i < teams.length; i++) {
            teams[i] = Team.fromServer(teams[i]);
        }

        return teams;
    }

    private updateCache(teams: Team[]) {
        teams.forEach(t => {
            this.teamCache[t.id] = t;
        });
    }

    private updateColorsCache(colors: TeamColor[]) {
        this.colorsCache = colors;
    }
}