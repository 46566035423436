export class AttendanceFilter {
    gameIds: any[];
    registrationIds: any[];
    seasonIds: any[];

    constructor(data?: {
        gameIds?: any[],
        registrationIds?: any[],
        seasonIds?: any[],
    }) {

        if (data) {
            this.gameIds = data.gameIds;
            this.registrationIds = data.registrationIds;
            this.seasonIds = data.seasonIds;
        }
    }

    getQuery() {

        let params = new URLSearchParams();

        if (this.gameIds) {
            this.gameIds.forEach(id => params.append('game_ids[]', id));
        }

        if (this.registrationIds) {
            this.registrationIds.forEach(id => params.append('registration_ids[]', id));
        }

        if (this.seasonIds) {
            this.seasonIds.forEach(id => params.append('season_ids[]', id));
        }

        return params.toString();
    }
}