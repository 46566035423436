import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Franchise } from '../../classes/tfflModels/franchise';
import { HelperService } from '../util/helper.service';

@Injectable({ providedIn: 'root' })
export class FranchisesService {

    private cache: { [leagueId: string]: Franchise[] } = {};

    private url = '/franchises';

    constructor(
        private http: HttpClient,
        private helperService: HelperService,
    ) { }

    getFranchises(leagueId: any = ''): Observable<Franchise[]> {

        console.log('get franchises', leagueId);
            
        if (this.cache [leagueId]) {
            return of(this.cache [leagueId]);
        } else {
            
            let url = this.url;
            if (leagueId) {
                url += '?league_id=' + leagueId;
            }

            return this.http.get<{ franchises: Franchise[] }>(url)
                .pipe(
                    map(result => result.franchises),
                    map(this.extractFranchises), /* IMPORTANT */
                    tap(franchises => this.updateCache(franchises, leagueId)),
                    catchError(this.helperService.handleError('getFranchises', []))
                );
        }
    }

    getFranchise(id: any): Observable<Franchise> {
        return this.http.get<{ franchise: Franchise }>(this.url + '/' + id)
            .pipe(
                map(result => result.franchise),
                map(Franchise.fromServer), /* IMPORTANT */
                catchError(this.helperService.handleError('getFranchise', null))
            );
    }

    create(franchise: Franchise): Observable<Franchise> {
        return this.http.post<{ franchise: Franchise }>(this.url, { franchise: franchise })
            .pipe(
                map(result => result.franchise),
                map(Franchise.fromServer), /* IMPORTANT */
                tap(() => this.invalidateCache()),
                catchError(this.helperService.handleError('getFranchise', null))
            );
    }

    getByName(name: string): Observable<Franchise> {
        return this.getFranchises()
            .pipe(
                map((franchises: Franchise[]) => this.filterExact(name, franchises)),
                catchError(this.helperService.handleError('getFranchiseByName', null))
            );
    }

    search(term: string): Observable<Franchise[]> {
        if (term.length < 3) {
            return of([]);
        }
        if (this.cache ['']) {
            return of(this.filterFranchises(term));
        } else {
            return this.getFranchises().pipe(
                map((franchises: Franchise[]) => this.filterFranchises(term))
            );
        }
    }

    private findFranchise(id: any, franchises: Franchise[]): Franchise {
        for (let i = 0; i < franchises.length; i++) {
            if (franchises[i].id == id) {
                return franchises[i];
            }
        }
        throw new Error('Invalid id. No franchise exists with the id: ' + id);
    }

    private extractFranchises(franchises: Franchise[]) {
        for (let i = 0; i < franchises.length; i++) {
            franchises[i] = Franchise.fromServer(franchises[i]);
        }

        return franchises;
    }

    private filterExact(term: string, franchises: Franchise[]): Franchise {
        for (let i = 0; i < franchises.length; i++) {
            if (franchises[i].name && franchises[i].name.toLocaleLowerCase() == term.toLocaleLowerCase()) {
                return franchises[i];
            }
        }

        return null;
    }

    private filterFranchises(term: string): Franchise[] {
        let franchises = this.cache [''];
        let filteredFranchises = [];

        franchises.forEach(franchise => {
            let teamName = franchise.name;

            if (teamName && teamName.toLocaleLowerCase().includes(term.toLocaleLowerCase())) {
                filteredFranchises.push(franchise);
            }
        });

        return filteredFranchises;
    }

    private updateCache(franchises: Franchise[], leagueId?: any) {
        
        if (!this.cache [leagueId]) {
            this.cache [leagueId] = [];
        }

        for (let i = 0; i < franchises.length; i++) {

            let isNew = true;

            for (let j = 0; i < this.cache [leagueId].length; j++) {
                if (franchises[i].id == this.cache[leagueId][j].id) {
                    this.cache[leagueId][j] = franchises[i];
                    isNew = false;
                    break;
                }
            }

            if (isNew) {
                this.cache[leagueId].push(franchises[i]);
            }
        }
    }

    private invalidateCache() {
        this.cache = {};
    }
}