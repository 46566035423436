/**
 * This class is used to load specific seasons
 */
export class SeasonsFilter {
    leagueId: any;

    constructor(data?: {
        leagueId?: any,
    }) {

        if (data) {
            this.leagueId = data.leagueId;
        }
    }

    getQuery() {

        let params = new URLSearchParams();

        if (this.leagueId) {
            params.append('league_id', this.leagueId);
        }

        return params.toString();
    }
}