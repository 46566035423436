export class Evaluation {

    id: any;
    gameId: any;
    registrationId: any;
    ratedBy: any;
    bodyLanguageRating: Number;
    enthusiasmRating: Number;
    knowledgeRating: Number;
    positiveInteractionRating: Number;
    notes: string;
    role: string;

    constructor(data?: {
        id?: any,
        gameId?: any,
        registrationId?: any,
        ratedBy?: any,
        bodyLanguageRating?: Number,
        enthusiasmRating?: Number,
        knowledgeRating?: Number,
        positiveInteractionRating?: Number,
        notes?: string,
        role?: string,    
    }) {

        if (data) {
            this.id = data.id;
            this.gameId = data.gameId;
            this.registrationId = data.registrationId;
            this.ratedBy = data.ratedBy;
            this.bodyLanguageRating = data.bodyLanguageRating;
            this.enthusiasmRating = data.enthusiasmRating;
            this.knowledgeRating = data.knowledgeRating;
            this.positiveInteractionRating = data.positiveInteractionRating;
            this.notes = data.notes;
            this.role = data.role;
        }
    }

    static fromServer(item: Evaluation): Evaluation {
        item = new Evaluation(item);

        return item;
    }
}