import * as momentImported from 'moment';
import { RegistrationType } from './registration';

const moment = momentImported;

export class Attendance {
    id: any;
    registrationId: any;
    gameId: any;
    teamId: any;
    seasonId: any;
    registrationTypeId: RegistrationType;
    gameStartTime: Date;
    registrationTeamName: any;
    isAttending: boolean;

    constructor(data?: {
        id?: any,
        registrationId?: any,
        gameId?: any,
        teamId?: any,
        seasonId?: any,
        registrationTypeId?: RegistrationType,
        gameStartTime?: Date,
        registrationTeamName?: string,
        isAttending?: boolean,
    }) {

        if (data) {
            this.id = data.id;
            this.registrationId = data.registrationId;
            this.gameId = data.gameId;
            this.teamId = data.teamId;
            this.seasonId = data.seasonId;
            this.registrationTypeId = data.registrationTypeId;
            this.gameStartTime = data.gameStartTime;
            this.registrationTeamName = data.registrationTeamName;
            this.isAttending = data.isAttending;
        }
    }

    public static fromServer(attendance: Attendance): Attendance {
        attendance.gameStartTime = moment(attendance.gameStartTime).toDate();
        return new Attendance(attendance);
    }
}