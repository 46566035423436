import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HelperService {

    constructor() { }

    getErrorMessage(error: HttpErrorResponse): string {

        if (error == null) {
            return 'An unexpected error occured';
        }

        switch (error.status) {
            case 401:
                return 'Unauthorized';
            case 405:
                return this.getDefaultErrorMessage(error);
            default:
                return error.error && error.error.message ? error.error.message : this.getDefaultErrorMessage(error);
        }
    }

    handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            console.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result or throw an error
            // return of(result as T);
            return throwError(error);
        };
    }

    private getDefaultErrorMessage(error) {
        return 'An unexpected error occured. Please contact us and we\'ll sort out the problem. (' +
            error.status + ' - ' + error.statusText + ')';
    }
}
