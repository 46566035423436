import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Season, SeasonStatus } from '../../classes/tfflModels/seasons';
import { HelperService } from '../util/helper.service';

@Injectable({ providedIn: 'root' })
export class SeasonsService {

    private url = '/seasons';

    private seasonsSubject: ReplaySubject<Season[]>;

    constructor(
        private http: HttpClient,
        private helperService: HelperService,
    ) { }

    getSeasons(leagueId: any): Observable<Season[]> {

        if (!leagueId) {
            throw new Error('You must pass a league id to the getSeasons function');
        }

        return this.loadSeasons().pipe(
            map(seasons => seasons.filter(s => s.leagueId == leagueId))
        );
    }

    getSeason(seasonId: any): Observable<Season> {
        return this.loadSeasons().pipe(
            map(seasons => seasons.find(s => s.id == seasonId))
        );
    }

    getCurrentSeasons(leagueId: any): Observable<Season[]> {
        return this.getSeasons(leagueId).pipe(
            map(seasons => seasons.filter(season => season.isCurrent)),
        );
    }

    getSeasonsFromAllLeagues(): Observable<Season[]> {
        return this.loadSeasons();
    }

    sortSeasons(seasons: Season[]): Season[] {
        seasons.sort((a, b) => {
            if (a.status == b.status) return b.order - a.order;
            if (a.status == SeasonStatus.ACTIVE) return -1;
            if (b.status == SeasonStatus.ACTIVE) return 1;
            if (a.status == SeasonStatus.REG) return -1;
            if (b.status == SeasonStatus.REG) return 1;
            if (a.status == SeasonStatus.INACTIVE) return -1;
            if (b.status == SeasonStatus.INACTIVE) return 1;
            return b.order - a.order;
        });

        return seasons;
    }

    private loadSeasons(): Observable<Season[]> {

        if (!this.seasonsSubject) {
            this.seasonsSubject = new ReplaySubject<Season[]>();

            let url = this.url;
            this.http.get<{ seasons: Season[] }>(url).pipe(
                map((response) => response.seasons),
                map(this.extractSeasons),
                map(seasons => this.sortSeasons(seasons)),
                catchError(this.helperService.handleError('getSeasons', []))
            ).subscribe(seasons => {
                this.seasonsSubject.next(seasons);
            });
        }

        return this.seasonsSubject.asObservable();
    }

    private extractSeasons(seasons: Season[]): Season[] {
        for (let i = 0; i < seasons.length; i++) {
            seasons[i] = Season.fromServer(seasons[i]);
        }

        return seasons;
    }
}
