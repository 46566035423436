import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Token } from '../../classes/auth/token';
import { User } from '../../classes/tfflModels/user';
import { HelperService } from '../util/helper.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private url: string = '/account/actions/login';
    private token: Token;
    private returnUrl: string = null;

    private userSubject: BehaviorSubject<User>;

    constructor(
        private http: HttpClient,
        private router: Router,
        private helperService: HelperService,
    ) {
        this.loadToken();
        this.userSubject = new BehaviorSubject(this.getActiveUser());
    }

    isLoggedIn(): boolean {
        let nowSeconds = Date.now() / 1000;
        let loggedIn = this.token && this.token.expires && this.token.expires > nowSeconds;

        return loggedIn;
    }

    isStaff(): boolean {
        let scope = this.getTokenContent().scope;
        return this.isLoggedIn() && scope.some(scope => ['user.admin', 'user.convenor', 'user.staff'].includes(scope));
    }

    isConvenor(): boolean {
        let scope = this.getTokenContent().scope;
        return scope && scope.some(scope => ['user.admin', 'user.convenor'].includes(scope));
    }

    isAdmin(): boolean {
        let scope = this.getTokenContent().scope;
        return scope && scope.some(scope => ['user.admin'].includes(scope));
    }

    getUserId(): Observable<any> {
        return this.userSubject.pipe(
            map(user => user ? user.id : null)
        );
    }

    /**
     * Retrieves a user based on the auth token. The user's data will be incomplete, 
     * only containing information such as Name
     */
    getUser(): Observable<User> {
        return this.userSubject;
    }

    getToken() {
        return this.token;
    }

    generateShortToken() {
        return this.http.get<Token>('/account/short-token')
            .pipe(
                map(token => Token.fromServer(token)),
                catchError(this.helperService.handleError('login', false))
            );
    }

    generateAdminToken(userId) {
        let params = new URLSearchParams();
        params.append('userId', userId);
        return this.http.get<Token>('/admin/token?' + params.toString()).pipe(
            map(token => Token.fromServer(token)),
            tap((tokenResponse: Token) => {
                /* store username and jwt token in local storage to keep user logged in between page refreshes */
                this.saveToken(tokenResponse);

                console.log('pushing user subject with new admin token', userId);

                /* push the new user to listeners */
                this.userSubject.next(this.getActiveUser());
            }),
            catchError(this.helperService.handleError('login', false))
        )
    }

    setAdminToken(token: Token) {
         /* store username and jwt token in local storage to keep user logged in between page refreshes */
        this.saveToken(token);

        console.log('pushing user subject with new admin token');

        /* push the new user to listeners */
        this.userSubject.next(this.getActiveUser());
    }

    login(email: string, password: string): Observable<boolean> {

        return this.http.post<Token>(this.url, { email: email, password: password })
            .pipe(
                map(token => Token.fromServer(token)),
                tap((tokenResponse: Token) => {
                    /* store username and jwt token in local storage to keep user logged in between page refreshes */
                    this.saveToken(tokenResponse);

                    /* push the new user to listeners */
                    this.userSubject.next(this.getActiveUser());
                }),
                map((tokenResponse: Token) => {
                    // return true to indicate successful login
                    return true;
                }),
                catchError(this.helperService.handleError('login', false))
            );
    }

    logout(): Observable<boolean> {
        this.deleteToken();
        this.deleteLeaguePreferences();
        this.userSubject.next(null);
        return of(true);
    }

    hasReturnUrl(): boolean {
        return this.returnUrl != null;
    }

    getReturnUrl(): string {
        return this.returnUrl;
    }

    setReturnUrl(returnUrl: string): void {
        this.returnUrl = returnUrl;
    }

    clearReturnUrl(): void {
        this.returnUrl = null;
    }

    redirect(): void {
        let url = '/';//default return url

        if (this.hasReturnUrl()) {
            url = this.getReturnUrl();
            this.clearReturnUrl();
        }

        this.router.navigateByUrl(url);
    }

    private getActiveUser(): User {

        if (!this.isLoggedIn()) {
            return null;
        }

        let tokenContent = this.getTokenContent();
        let userId = this.token && this.token.userId;

        return new User({
            id: userId,
            firstName: tokenContent.given_name,
            lastName: tokenContent.family_name
        });
    }

    private getTokenContent() {
        return this.token &&
            this.token.tokenContent ?
            this.token.tokenContent :
            {};
    }

    private loadToken(): void {
        const tokenString = localStorage.getItem('user');
        try {
            let token = JSON.parse(tokenString);
            token = Object.assign(new Token(), token);
            this.token = token;
        } catch (e) {
            this.token = null;
        }
    }

    private saveToken(token: Token): void {
        localStorage.setItem('user', JSON.stringify(token));
        this.token = token;
    }

    private deleteToken(): void {
        localStorage.removeItem('user');
        this.token = null;
    }

    private deleteLeaguePreferences(): void {
        localStorage.removeItem('leaguePreferences');
    }

}
