export class CreditCard {

    /** The Stripe id */
    id: any;
    object: string;
    number: string;
    address_city: string;
    address_country: string;
    address_line1: string;
    address_line1_check: string;
    address_line2: string;
    address_state: string;
    address_zip: string;
    address_zip_check: string;
    brand: string;
    country: string;
    customer: string;
    cvc_check: string;
    dynamic_last4: string;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    funding: string;
    last4: string;
    metadata: any;
    name: string
    tokenization_method: any;

    constructor(data?: {
        id?: any,
        object?: string,
        number?: string,
        address_city?: string,
        address_country?: string,
        address_line1?: string,
        address_line1_check?: string,
        address_line2?: string,
        address_state?: string,
        address_zip?: string,
        address_zip_check?: string,
        brand?: string,
        country?: string,
        customer?: string,
        cvc_check?: string,
        dynamic_last4?: string,
        exp_month?: number,
        exp_year?: number,
        fingerprint?: string,
        funding?: string,
        last4?: string,
        metadata?: any,
        name?: string,
        tokenization_method?: any,

    }) {

        if (data) {
            this.id = data.id;
            this.object = data.object;
            this.number = data.number;
            this.address_city = data.address_city;
            this.address_country = data.address_country;
            this.address_line1 = data.address_line1;
            this.address_line1_check = data.address_line1_check;
            this.address_line2 = data.address_line2;
            this.address_state = data.address_state;
            this.address_zip = data.address_zip;
            this.address_zip_check = data.address_zip_check;
            this.brand = data.brand;
            this.country = data.country;
            this.customer = data.customer;
            this.cvc_check = data.cvc_check;
            this.dynamic_last4 = data.dynamic_last4;
            this.exp_month = data.exp_month;
            this.exp_year = data.exp_year;
            this.fingerprint = data.fingerprint;
            this.funding = data.funding;
            this.last4 = data.last4;
            this.metadata = data.metadata;
            this.name = data.name;
            this.tokenization_method = data.tokenization_method;
        }
    }

    static fromServer(card: CreditCard): CreditCard {
        card.exp_month = +card.exp_month;
        card.exp_year = +card.exp_year;
        card = new CreditCard(card);

        return card;
    }
}