export class RegistrationNotes {

    registrationId: any;
    createdBy: any;
    note: any
    createdDate: any;

    constructor(data?: {
        registrationId?: any,
        createdBy?: any,
        note?: any,
        createdDate?: any,

    }) {

        if (data) {
            this.registrationId = data.registrationId;
            this.createdBy = data.createdBy;
            this.note = data.note;
            this.createdDate = data.createdDate
        }
    }
}