import { Team } from './team';

export interface CoinToss {
    winner?: Team;
    firstChoice?: CoinTossOption;
    secondChoice?: CoinTossOption;
    thirdChoice?: CoinTossOption;
    pickingTeam?: Team;
}

export enum CoinTossOption {
    RECEIVE = 'Receive',
    SIDE = 'Pick Side',
    DEFER = 'Defer'
}