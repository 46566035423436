import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DriftService {

    private driftClient$ = new ReplaySubject<any>();

    constructor() {
    }

    init() {
        let windowRef: any = window;
        
        if (windowRef) {
            windowRef.drift.SNIPPET_VERSION = '0.3.1';
            windowRef.drift.load('sdn43dxufgy2');
            let self = this;
            windowRef.drift.on('ready', function (api) {
                self.driftClient$.next(api);
            });
        }
    }

    hide() {
        this.driftClient$.pipe(first()).subscribe(client => {
            client.widget.hide();
        });
    }
}
