import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { RegistrationsFilter } from '../../classes/filters/registrationfilter';
import { Registration, RegistrationStatus, RegistrationType } from '../../classes/tfflModels/registration';
import { RegistrationsService } from './registrations.service';
import { SeasonsService } from './seasons-service';

@Injectable({
    providedIn: 'root'
})
export class RefereesService {

    activeRefereeCache: Registration[];
    // refereeCache: { [seasonId: number]: Registration []};

    constructor(
        private registrationsService: RegistrationsService,
        private seasonsService: SeasonsService,
    ) { }

    getActiveReferees(seasonId: any): Observable<Registration[]> {

        if (this.activeRefereeCache) {
            return of(this.activeRefereeCache);
        }

        return this.seasonsService.getSeason(seasonId).pipe(
            switchMap(season => {
                let filter = new RegistrationsFilter({
                    registrationTypeId: RegistrationType.STAFF,
                    statuses: [RegistrationStatus.HIRED]
                });

                if (season) {
                    filter.seasonId = season.id;
                }

                return this.registrationsService.getRegistrations(filter);
            }),
            map(referees => {
                referees.sort((reg1, reg2) => {
                    return reg1.nameReversed.localeCompare(reg2.nameReversed);
                });
                return referees;
            }),
            tap(referees => this.activeRefereeCache = referees)
        );
    }
}
