import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Division } from '../../classes/tfflModels/division';
import { Program, ProgramType } from '../../classes/tfflModels/program';
import { HelperService } from '../util/helper.service';

@Injectable({ providedIn: 'root' })
export class ProgramsService {

    private url = '/programs';
    private singleProgramCache: { [programId: string]: Program } = {};
    private allProgramsCache: Program[];
    private divisionCache = null;

    constructor(
        private http: HttpClient,
        private helperService: HelperService,
    ) { }

    getProgram(id: any): Observable<Program> {
        let program = this.findProgramFromCache(id);
        if (program) {
            return of(program);
        } else {
            return this.http.get<{ program: Program }>(this.url + '/' + id)
                .pipe(
                    map((response) => response.program),
                    map(program => Program.fromServer(program)),
                    tap(program => {
                        if (!this.singleProgramCache) {
                            this.singleProgramCache = {};
                        }
                        this.singleProgramCache[program.id] = program;
                    }),
                    catchError(this.helperService.handleError('get program', null))
                );
        }
    }

    /**
     * Retrieves the programs that have a season with a status of 'ACTIVE' or 'REG'
     */
    getActivePrograms(): Observable<Program []> {
        return this.getPrograms().pipe(
            map((programs: Program[]) => programs.filter(p => p.season && p.season.isCurrent))
        );
    }

    getBySeason(seasonId: any): Observable<Program[]> {
        return this.getPrograms().pipe(
            map((programs: Program[]) => this.filterBySeason(seasonId, programs))
        );
    }

    getByType(type: ProgramType) {
        return this.getPrograms().pipe(
            map(programs => programs.filter(p => p.programTypeId == type))
        );
    }

    getDivisions(seasonId: any): Observable<Division[]> {
        let url = '/divisions';
        if (seasonId) url += '?season_id=' + seasonId;

        return this.http.get<{ divisions: Division[] }>(url)
            .pipe(
                map((response) => response.divisions),
                map(this.extractDivisions),
                catchError(this.helperService.handleError('getDivisions', []))
            );
    }

    private getPrograms(): Observable<Program[]> {

        if (this.allProgramsCache != null) {
            return of(this.allProgramsCache);
        } else {
            return this.http.get<{ programs: Program[] }>(this.url)
                .pipe(
                    map((response) => response.programs),
                    map(this.extractPrograms),
                    tap(programs => this.allProgramsCache = programs),
                    catchError(this.helperService.handleError('getPrograms', []))
                );
        }
    }

    private extractPrograms(programs: Program[]): Program[] {
        for (let i = 0; i < programs.length; i++) {
            programs[i] = Program.fromServer(programs[i]);
        }

        return programs;
    }

    private extractDivisions(divisions: Division[]): Division[] {
        for (let i = 0; i < divisions.length; i++) {
            divisions[i] = Division.fromServer(divisions[i]);
        }

        return divisions;
    }

    private findProgramFromCache(id: any) {
        if (this.singleProgramCache[id]) {
            return this.singleProgramCache[id];
        }
        if (this.allProgramsCache) {
            for (let i = 0; i < this.allProgramsCache.length; i++) {
                if (this.allProgramsCache[i].id == id) {
                    return this.allProgramsCache[i];
                }
            }
        }
        return null;
    }

    private filterBySeason(seasonId: any, programs: Program[]) {
        let filtered = [];
        for (let i = 0; i < programs.length; i++) {
            if (programs[i].seasonId == seasonId) {
                filtered.push(programs[i]);
            }
        }

        return filtered;
    }
}
