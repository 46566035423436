import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PRIMARY_COLOR } from '../deleteThis/colorScheme';
import { ColorHelper } from '../util/colorHelper';
import { Division } from './division';
import { League } from './league';
import { Program } from './program';
import { Registration } from "./registration";
import { Season } from './seasons';
import { TeamColor } from "./team-colors";

export class Team {
    id: any;
    name: string;
    divisionId: any;
    division: Division;
    programId: any;
    program: Program;
    seasonId: any;
    season: Season;
    leagueId: any;
    league: League;
    notes: string;
    registrations: Registration[];
    franchiseId: any;
    alternateColor: string;
    wins: number;
    ties: number;
    losses: number;
    isChampion: boolean;
    teamColor: TeamColor;

    private _shortName: string;
    private _logoUrl: string;
    private _color: string;

    get programName(): string { return this.program ? this.program.name : null }
    get divisionName(): string { return this.division ? this.division.name : null }

    get color(): string {
        return this._color;
    }

    set color(color: string) {

        if (!color) {
            color = PRIMARY_COLOR;
        }

        this._color = color;
        let colorHelper = new ColorHelper();
        this.alternateColor = colorHelper.getTextColor(color);
    }

    get logoUrl(): string {
        return this._logoUrl;
    }

    set logoUrl(url: string) {
        this._logoUrl = url || '//s3.amazonaws.com/tffl/logos/tffl-circle-logo-white.png';
    }

    get shortName(): string {
        if (this._shortName) return this._shortName;
        if (this.name) return this.name.substr(0, 3);
    }

    set shortName(shortName: string) {
        this._shortName = shortName;
    }

    constructor(data?: {
        id?: any,
        name?: string,
        shortName?: string,
        divisionId?: any,
        division?: Division,
        programId?: any,
        program?: Program,
        seasonId?: any,
        season?: Season,
        leagueId?: any,
        league?: League,
        notes?: string,
        registrations?: Registration[],
        franchiseId?: any,
        color?: string,
        wins?: number,
        ties?: number,
        losses?: number,
        logoUrl?: string,
        isChampion?: boolean,
        teamColor?: TeamColor;
    }) {

        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.shortName = data.shortName;
            this.divisionId = data.divisionId;
            this.division = data.division;
            this.programId = data.programId;
            this.program = data.program;
            this.seasonId = data.seasonId;
            this.season = data.season;
            this.leagueId = data.leagueId;
            this.league = data.league;
            this.notes = data.notes;
            this.registrations = data.registrations;
            this.franchiseId = data.franchiseId;
            this.color = data.color;
            this.wins = data.wins;
            this.ties = data.ties;
            this.losses = data.losses;
            this.logoUrl = data.logoUrl;
            this.isChampion = data.isChampion;
            this.teamColor = data.teamColor;
        }

    }

    getRegisrationByUserId(userId): Registration {
        for (let i = 0; i < this.registrations.length; i++) {
            if (this.registrations[i].user && this.registrations[i].user.id == userId) {
                return this.registrations[i];
            }
        }

        throw new Error('Player with id ' + userId + ' is not on the team with id ' + this.id);
    }

    getRegistration(registrationId): Registration {

        for (let i = 0; i < this.registrations.length; i++) {
            if (this.registrations[i].id == registrationId) {
                return this.registrations[i];
            }
        }

        throw new Error('Player with id ' + registrationId + ' is not on the team with id ' + this.id);
    }

    static getFormGroup(fb: FormBuilder): FormGroup {
        let defaultValues = {
            franchise: null,
            notes: '',
            waiver: false,
        }

        let form = fb.group({
            franchise: [defaultValues.franchise, Validators.required],
            notes: [defaultValues.notes, Validators.maxLength(1000)],
            waiver: [defaultValues.notes, Validators.requiredTrue],
        });

        return form;
    }

    toServer(): {} {

        let data: any = {};

        if (this.name !== undefined) data.name = this.name;
        if (this.shortName !== undefined) data.shortName = this.shortName;
        if (this.color !== undefined) data.color = this.color;
        if (this.divisionId !== undefined) data.divisionId = this.divisionId;
        if (this.programId !== undefined) data.programId = this.programId;
        if (this.seasonId !== undefined) data.seasonId = this.seasonId;
        if (this.leagueId !== undefined) data.leagueId = this.leagueId;
        if (this.notes !== undefined) data.notes = this.notes;
        if (this.franchiseId !== undefined) data.franchiseId = this.franchiseId;
        if (this.logoUrl !== undefined) data.logoUrl = this.logoUrl;

        return data;
    }

    static fromServer(team: Team): Team {

        if (team.program) {
            team.program = Program.fromServer(team.program);
        }

        if (team.division) {
            team.division = Division.fromServer(team.division);
        }

        if (team.season) {
            team.season = Season.fromServer(team.season);
        }

        if (team.league) {
            team.league = League.fromServer(team.league);
        }

        if (team.registrations) {
            team.registrations = team.registrations.map((registration) => Registration.fromServer(registration));
        }

        if (team.teamColor) {
            team.teamColor = TeamColor.fromServer(team.teamColor); // Convert teamColor from server
        }

        team.isChampion = team.isChampion || false;

        team = new Team(team);

        return team;
    }
}