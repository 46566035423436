import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Token } from '../../classes/auth/token';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

    private token: Token;

    constructor(
        private authService: AuthService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let token: Token = this.authService.getToken();

        if (token) {
            request = request.clone({
                headers: request.headers.set("Authorization", `Bearer ${token.token}`)
            });
        }

        return next.handle(request);
    }
}
