import { TeamStanding } from '../../classes/tfflModels/teamStanding';

export interface ProgramStandings {
    title: string,
    standings: TeamStanding[]
}

export interface SeasonStandingsData { 
    [programDivisionKey: string]: ProgramStandings 
}

export class SeasonStandings {

    private standingsMap: SeasonStandingsData;

    constructor() {
        this.standingsMap = {};
    }

    addStanding(standing: TeamStanding) {
        let key = standing.programId + '-' + standing.divisionId;
        let title = standing.programDivisionTitle;
        if (!this.standingsMap[key]) {
            this.standingsMap[key] = { title: title, standings: [] }
        }

        this.standingsMap[key].standings.push(standing);
    }

    getProgramStandings(programId: any, divisionId: any): ProgramStandings {
        return this.getProgramStandingsByKey(programId + '-' + divisionId);
    }

    getProgramStandingsByKey(programIdHyphenDivisionId: string): ProgramStandings {
        return this.standingsMap [programIdHyphenDivisionId];
    }

    getAllStandings(): SeasonStandingsData {
        return this.standingsMap
    }
}