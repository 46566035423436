import { Address } from '../tfflModels/address';
import { Attendance } from '../tfflModels/attendance';
import { Division } from '../tfflModels/division';
import { Game, GameType } from '../tfflModels/game';
import { GameBundle } from '../tfflModels/GameBundle';
import { Program } from '../tfflModels/program';
import { Registration, RegistrationType } from '../tfflModels/registration';
import { Team } from '../tfflModels/team';
import { User } from '../tfflModels/user';

export class TestUtil {

    constructor() { }

    getMockTeam(options: {
        teamId: number,
        registrationIds: number[]
    }): Team {

        let teamId = options.teamId;
        let registrationIds = options.registrationIds;

        let team = new Team({
            id: teamId,
            name: 'Team ' + teamId,
            color: '#fff',
        });

        let registrations = registrationIds.map(id => {
            return this.getMockRegistration({
                registrationId: id,
                team: team
            });
        });

        team.registrations = registrations;

        return team;
    }

    getMockRegistration(options: {
        team: Team,
        registrationId: number
    }): Registration {

        let team = options.team;
        let id = options.registrationId;
        return new Registration({
            id: id,
            jerseyNumber: '' + id,
            teamId: team.id,
            registrationTypeId: RegistrationType.PLAYER,
            userId: id,
            user: new User({
                id: id,
                firstName: 'First ' + id,
                lastName: 'Last ' + id,
            }),
            team: team,
        });
    }

    getMockAttendances(options: {
        gameId: number,
        ids: number[]
    }): Attendance[] {
        return options.ids.map(id => {

            let attendanceId = id;
            let gameId = options.gameId;
            let isAttending = true;
            let registrationId = id;

            return new Attendance({
                id: attendanceId,
                isAttending: isAttending,
                gameId: gameId,
                registrationId: registrationId,
            })
        });
    }

    getMockGameBundle(): GameBundle {

        let homeTeam = this.getMockTeam({
            teamId: 1,
            registrationIds: [1, 2, 3]
        });
        let awayTeam = this.getMockTeam({
            teamId: 2,
            registrationIds: [4, 5, 6]
        });

        const halfDuration = 1320000;/** 22 minutes */

        let game = new Game({
            id: 1,
            homeTeamId: 1,
            homeTeam: homeTeam,
            awayTeamId: 2,
            awayTeam: awayTeam,
            program: new Program({
                id: 1,
                name: 'NFL'
            }),
            division: new Division({
                id: 1,
                name: 'North'
            }),
            gameTypeId: GameType.SEASON,
            duration: 60,
            startTime: new Date(2018, 5, 15, 10, 0, 0, 0),/** May 15th at 10am */
            location: new Address({
                id: 1,
                name: 'Fieldstone'
            }),
            posession: 1,
            down: 1,
            playNumber: 1,
            half: 1,
            time: halfDuration,
            homeScore: 0,
            homeSportsmanship: 0,
            homeForfeit: false,
            awayScore: 0,
            awaySportsmanship: 0,
            awayForfeit: false,
            ballFirst: null,
            ballSecond: null,
            totalDowns: 4,
            halfDuration: halfDuration
        });

        let attendances = this.getMockAttendances({
            ids: [1, 2, 3, 4, 5, 6],
            gameId: 1
        });

        return {
            game: game,
            stats: [],
            logs: [],
            attendances: attendances
        }
    }
}
