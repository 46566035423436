import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TfflCoreConfig, TFFL_CORE_CONFIG } from '../../tffl-core.config';

@Injectable({ providedIn: 'root' })
export class UrlBaseInterceptor implements HttpInterceptor {

    constructor(
        @Inject(TFFL_CORE_CONFIG) private config: TfflCoreConfig
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = this.config.apiBase;

        if (!req.url.includes('//')) {
            req = req.clone({
                url: url + req.url
            });
        }

        return next.handle(req);
    }
}