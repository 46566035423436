export class Division {
    id: any;
    programId: any;
    programName: string;
    name: string;

    constructor(data?: {
        id?: any,
        programId?: any,
        programName?: string,
        name?: string,
    }) {

        if (data) {
            this.id = data.id;
            this.programId = data.programId;
            this.programName = data.programName;
            this.name = data.name;
        }
    }

    static fromServer(divison: Division) {
        return new Division(divison);
    }
}