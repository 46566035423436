export interface GameAttendanceSummaryConstructorData {
    gameId: any;
    attendingPlayers: number;
    notAttendingPlayers: number;
    attendingCoaches: number;
    notAttendingCoaches: number;
}

export class GameAttendanceSummary {
    gameId: any;
    attendingPlayers: number = 0;
    notAttendingPlayers: number = 0;
    attendingCoaches: number = 0;
    notAttendingCoaches: number = 0;

    constructor(data?: GameAttendanceSummaryConstructorData) {
        if (data) {
            this.gameId = data.gameId;
            this.attendingPlayers = data.attendingPlayers;
            this.notAttendingPlayers = data.notAttendingPlayers;
            this.attendingCoaches = data.attendingCoaches;
            this.notAttendingCoaches = data.notAttendingCoaches;
        }
    }

    isLowAttendancePlayers(): boolean {
        return this.notAttendingPlayers > 2;
    }

    isLowAttendanceCoaches(): boolean {
        return this.notAttendingCoaches > 0;
    }
}

export interface SeasonAttendanceSummary { 
    [gameIdTeamId: string]: GameAttendanceSummary;
}