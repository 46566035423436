import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FriendRequest } from '../../classes/tfflModels/friendrequest';
import { HelperService } from '../util/helper.service';

@Injectable({ providedIn: 'root' })
export class FriendRequestsService {

    private url = '/friend-requests';

    constructor(
        private http: HttpClient,
        private helperService: HelperService,
    ) { }

    getRequests(registrationId: any): Observable<FriendRequest[]> {
        return this.http.get<{ friendRequests: FriendRequest[] }>('/registrations/' + registrationId + '/friend-requests')
            .pipe(
                map(response => response.friendRequests),
                map(this.extractFriendRequests), /* IMPORTANT */
                catchError(this.helperService.handleError('getFriendRequests', []))
            );
    }

    addRequest(friendRequest: FriendRequest): Observable<FriendRequest> {
        return this.http.post<{ friendRequest: FriendRequest }>(this.url, { friendRequest: friendRequest })
            .pipe(
                map(response => response.friendRequest),
                map(FriendRequest.fromServer), /* IMPORTANT */
                catchError(this.helperService.handleError('makenewfriendrequest', null))
            );
    }

    addRequests(friendRequests: FriendRequest[]): Observable<FriendRequest[]> {
        return this.http.post<{ friendRequests: FriendRequest[] }>(this.url, { friendRequests: friendRequests })
            .pipe(
                map(response => response.friendRequests),
                map(this.extractFriendRequests), /* IMPORTANT */
                catchError(this.helperService.handleError('makenewfriendrequest', null))
            );
    }

    sendRequests(friendRequests: FriendRequest[], message?: string): Observable<FriendRequest[]> {
        let data: any = {};
        data.friendRequests = friendRequests;
        if (message) data.message = message;

        return this.http.post<{ friendRequests: FriendRequest[] }>(this.url + '/actions/send', data)
            .pipe(
                map(response => response.friendRequests),
                map(this.extractFriendRequests), /* IMPORTANT */
                catchError(this.helperService.handleError('makenewfriendrequest', null))
            );
    }

    deleteRequest(friendRequest: FriendRequest): Observable<FriendRequest> {
        let url = this.url + '/' + friendRequest.id + '/actions/cancel';
        return this.http.post<{ friendRequest: FriendRequest }>(url, {})
            .pipe(
                map(response => response.friendRequest),
                map(FriendRequest.fromServer), /* IMPORTANT */
                catchError(this.helperService.handleError('deletefriendrequest', null))
            );
    }

    private extractFriendRequests(friendRequests: FriendRequest[]): FriendRequest[] {
        friendRequests = friendRequests.map(request => FriendRequest.fromServer(request));
        return friendRequests;
    }
}
