
export class Notification<T extends any> {

    item: T;
    type: NotificationType;
    status: 'shown' | 'hidden';

    constructor(item: T, type: NotificationType) {
        this.item = item;
        this.type = type;
        this.status = 'shown';
    }

}

export enum NotificationType {
    CreatedUser = 'CreatedUser',
    LoggedIn = 'LoggedIn',
    JoinedTeam = 'JoinedTeam',
    RegisteredPlayer = 'RegisteredPlayer',
    SentTeamMessage = 'SentTeamMessage',
    ArgosOptin = 'ArgosOptin'
}