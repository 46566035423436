import { Injectable } from '@angular/core';
import { ResponsiveState } from 'ngx-responsive';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * This acts as a wrapper for the responsive stuff.
 */
@Injectable({ providedIn: 'root' })
export class ResponsiveService {

    deviceChanged = new BehaviorSubject<void>(null);
    isMobile: boolean = true;
    width: number;

    constructor(
        private responsiveState: ResponsiveState,
    ) {
        this.isMobile = true;
        this.responsiveState.ancho$.pipe(
            distinctUntilChanged(),
        ).subscribe(deviceWidth => {
            this.initDevice(deviceWidth);
            this.deviceChanged.next(null);
        });

    }

    private initDevice(deviceWidth: number) {
        this.isMobile = deviceWidth < 769;
        this.width = deviceWidth;
    }



}
