import { RegistrationStatus, RegistrationType } from '../tfflModels/registration';
/**
 * This class is used to load specific registrations, such as based on user type
 */
export class RegistrationsFilter {
    registrationTypeId: RegistrationType;
    programId: any;
    seasonId: any;
    teamId: any;
    userId: any;
    relatedToUserId: any;
    statuses: RegistrationStatus[];

    constructor(data?: {
        registrationTypeId?: RegistrationType,
        programId?: any,
        userId?: any,
        teamId?: any,
        seasonId?: any,
        relatedToUserId?: any,
        statuses?: RegistrationStatus[],
    }) {

        if (data) {
            this.registrationTypeId = data.registrationTypeId;
            this.programId = data.programId;
            this.teamId = data.teamId;
            this.userId = data.userId;
            this.seasonId = data.seasonId;
            this.relatedToUserId = data.relatedToUserId;
            this.statuses = data.statuses;
        }
    }

    getQuery() {

        let params = new URLSearchParams();

        if (this.registrationTypeId) {
            params.append('registration_type_id', this.registrationTypeId);
        }

        if (this.programId) {
            params.append('program_id', this.programId);
        }

        if (this.teamId) {
            params.append('team_id', this.teamId);
        }

        if (this.userId) {
            params.append('user_id', this.userId);
        }

        if (this.seasonId) {
            params.append('season_id', this.seasonId);
        }

        if (this.relatedToUserId) {
            params.append('related_to_user_id', this.relatedToUserId);
        }

        if (this.statuses) {
            this.statuses.forEach(s => params.append('statuses[]', s));
        }

        return params.toString();
    }

    toString() {
        let statusString = (this.statuses || []).join(',');
        return this.registrationTypeId + '-' + this.programId + '-' + this.teamId + '-' + this.userId + '-' + this.relatedToUserId + '-' + this.seasonId + '-' + statusString;
    }
}