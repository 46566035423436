import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { RegistrationsFilter } from '../../classes/filters/registrationfilter';
import { Registration } from '../../classes/tfflModels/registration';
import { RegistrationNotes } from '../../classes/tfflModels/registrationNotes';
import { HelperService } from '../util/helper.service';

@Injectable({ providedIn: 'root' })
export class RegistrationsService {

    private url = '/registrations';
    private cache: { [filterString: string]: Registration[] } = {};
    private singleRegistrationCache: { [registrationId: number]: ReplaySubject<Registration> } = {};

    constructor(
        private http: HttpClient,
        private helperService: HelperService,
        private angulartics2: Angulartics2
    ) { }

    getRegistration(registrationId: any): Observable<Registration> {

        let regSubject = this.singleRegistrationCache[registrationId];

        if (regSubject != null) {

            return regSubject.pipe(first());

        } else {

            this.singleRegistrationCache[registrationId] = new ReplaySubject<Registration>();

            return this.http.get<{ registration: Registration }>(this.url + '/' + registrationId)
                .pipe(
                    map(response => response.registration),
                    map(Registration.fromServer), /* IMPORTANT */
                    tap(registration => this.singleRegistrationCache[registrationId].next(registration)),
                    catchError(this.helperService.handleError('getRegistration', null))
                );
        }
    }

    getRegistrations(filter?: RegistrationsFilter): Observable<Registration[]> {

        if (filter && this.cache[filter.toString()]) {
            return of(this.cache[filter.toString()]);
        } else {
            let queryParams = filter ? '?' + filter.getQuery() : '';
            let url = this.url + queryParams;

            return this.http.get<{ registrations: Registration[] }>(url)
                .pipe(
                    map(response => response.registrations),
                    map(this.extractRegistrations), /* IMPORTANT */
                    tap(registrations => this.updateCache(registrations, filter)),
                    catchError(this.helperService.handleError('getRegistrations', []))
                );
        }
    }

    create(registration: Registration): Observable<Registration> {

        /**
         * Fire off the registration tracking event
         */
        this.angulartics2.eventTrack.next({
            action: 'Register',
            properties: {
                value: {
                    registrationId: registration.id,
                    registrationType: registration.registrationTypeId,
                    programId: registration.programId,
                    programType: registration.program ? registration.program.programTypeId : null,
                }
            }
        });

        let body: any = {
            registration: registration.toServer()
        }

        return this.http.post<{ registration: Registration }>(this.url, body)
            .pipe(
                map(response => response.registration),
                map(Registration.fromServer), /* IMPORTANT */
                tap(() => this.invalidateCache()),
                catchError(this.helperService.handleError('createRegistration', null))
            );
    }

    update(registration: Registration): Observable<Registration> {
        // return of({ registration: registration })
        return this.http.put<{ registration: Registration }>(this.url + '/' + registration.id, { registration: registration.toServer() })
            .pipe(
                map(response => response.registration),
                map(Registration.fromServer), /* IMPORTANT */
                tap(() => this.invalidateCache()),
                catchError(this.helperService.handleError('update registration', null))
            );
    }

    cancel(registration: Registration): Observable<Registration> {
        return this.http.post<{ registration: Registration }>(this.url + '/' + registration.id + '/actions/cancel', {})
            .pipe(
                tap(() => this.invalidateCache()),
                catchError(this.helperService.handleError('cancel registration', null))
            );
    }

    search(term: string, programId: any): Observable<Registration[]> {
        if (term.length < 3) {
            throw new Error('Cannot search for registrations with less than 3 characters');
        }

        let filter = new RegistrationsFilter({
            programId: programId
        });

        return this.getRegistrations(filter).pipe(
            map((registrations: Registration[]) => {
                return registrations == null ? null : this.filterRegistrations(registrations, term)
            })
        );
    }

    filterRegistrations(registrations: Registration[], term: string): Registration[] {
        let filteredRegistrations = [];

        registrations.forEach(registration => {
            let fullName = registration.name;
            if (fullName && fullName.toLocaleLowerCase().includes(term.toLocaleLowerCase())) {
                filteredRegistrations.push(registration);
            }
        });

        return filteredRegistrations;
    }

    getNotes(registrationId: any): Observable<Registration> {
        return this.http.get<{ notes: RegistrationNotes }>(this.url + '/' + registrationId + '/get-notes')
            .pipe(
                map(response => response.notes),
                catchError(this.helperService.handleError('getNotes', null))
            );

    }

    createNote(registrationNote: RegistrationNotes): Observable<RegistrationNotes[]> {
        return this.http.post<{ notes: RegistrationNotes[] }>(`${this.url}/${registrationNote.registrationId}/create-note`, registrationNote)
            .pipe(
                map(response => response.notes),
                catchError(this.helperService.handleError('createNote', []))
            );
    }

    invalidateCache() {
        this.cache = {};
        this.singleRegistrationCache = {};
    }

    private updateCache(registrations: Registration[], filter: RegistrationsFilter) {
        if (filter) {
            this.cache[filter.toString()] = registrations;
        }
    }

    private extractRegistrations(registrations: Registration[]): Registration[] {
        registrations = registrations.map(registration => Registration.fromServer(registration));
        return registrations;
    }

    // private pushNotification(registration: Registration) {
    //     if (registration.registrationTypeId != RegistrationType.TEAM) {

    //     }
    // }
}
