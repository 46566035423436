export class Season {
    id: any;
    leagueId: any;
    name: string;
    status: SeasonStatus;
    date: Date;
    minBirthdate: Date;
    maxBirthdate: Date;
    order: number;

    get isCurrent(): boolean {
        return ![SeasonStatus.LEGACY, SeasonStatus.INACTIVE].includes(this.status);
    }

    get isActive(): boolean {
        return [SeasonStatus.ACTIVE].includes(this.status);
    }

    get hasStats(): boolean {
        return ![SeasonStatus.REG, SeasonStatus.LEGACY].includes(this.status);
    }

    get isLegacy(): boolean {
        return [SeasonStatus.LEGACY].includes(this.status);
    }


    constructor(data?: {
        id?: any,
        leagueId: any,
        name?: string,
        minBirthdate?: Date,
        maxBirthdate?: Date,
        date?: Date,
        status?: SeasonStatus
        order?: number
    }) {

        if (data) {
            this.id = data.id;
            this.leagueId = data.leagueId;
            this.name = data.name;
            this.status = data.status;
            this.minBirthdate = data.minBirthdate;
            this.maxBirthdate = data.maxBirthdate;
            this.date = data.date;
            this.order = data.order;
        }

    }

    static fromServer(season: Season): Season {
        season.order = +season.order;
        season.minBirthdate = season.minBirthdate ? new Date(season.minBirthdate) : null;
        season.maxBirthdate = season.maxBirthdate ? new Date(season.maxBirthdate) : null;
        season = new Season(season);
        return season;
    }
}


export enum SeasonStatus {
    LEGACY = 'LEGACY',
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
    REG = 'REG',
}