export class Address {
    id: any;
    programLocationId: any;
    programId: any;
    name: string;
    address: string;
    city: string;
    province: string;
    postal: string;
    latitude: number;
    longitude: number;

    constructor(data?: {
        id?: any,
        programLocationId?: any,
        programId?: any,
        name?: string,
        address?: string,
        city?: string,
        province?: string,
        postal?: string,
        latitude?: number,
        longitude?: number,
    }) {

        if (data) {
            this.id = data.id;
            this.programLocationId = data.programLocationId;
            this.programId = data.programId;
            this.name = data.name;
            this.address = data.address;
            this.city = data.city;
            this.province = data.province;
            this.postal = data.postal;
            this.latitude = data.latitude;
            this.longitude = data.longitude;
        }
    }

    public static fromServer(address: Address): Address {
        address.latitude = +address.latitude;
        address.longitude = +address.longitude;
        return new Address(address);
    }
}
