
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../other/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardAdminService implements CanActivate {

    constructor(
        public authService: AuthService,
        public router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        if (!this.authService.isLoggedIn() || !this.authService.isAdmin()) {
            this.authService.setReturnUrl(state.url);
            this.router.navigate(['/account/login']);
            return false;
        }

        return true;
    }
}