import { Division } from '../tfflModels/division';
import { League } from '../tfflModels/league';
import { Program } from '../tfflModels/program';
import { Season } from '../tfflModels/seasons';
import { Team } from '../tfflModels/team';

export class LeagueFilter {

    league: League;
    allSeasons: Season[];
    allPrograms: Program[] = [];
    allTeams: Team[] = [];
    allDivisions: Division[] = [];
    season: Season = null;
    programs: Program[] = [];
    teams: Team[] = [];
    divisions: Division[] = [];

    constructor(data?: {
        league?: League,
        allSeasons?: Season[],
        allPrograms?: Program[],
        allTeams?: Team[],
        allDivisions?: Division[],
        season?: Season,
        programs?: Program[],
        teams?: Team[],
        divisions?: Division[],
    }) {

        if (data) {
            this.league = data.league || null;
            this.allSeasons = data.allSeasons || [];
            this.allPrograms = data.allPrograms || [];
            this.allTeams = data.allTeams || [];
            this.allDivisions = data.allDivisions || [];
            this.season = data.season || null;
            this.programs = data.programs || [];
            this.teams = data.teams || [];
            this.divisions = data.divisions || [];
        }
    }

    getImpliedPrograms(): Program[] {
        if (!this.programs.length) {
            return this.allPrograms;
        } else {
            return this.programs;
        }
    }

    getImpliedTeams(options?: {
        ignoreSelectedTeams?: boolean /** If true, all teams for all selected programs will be allowed */,
        requireProgram?: boolean
    }): Team[] {

        let teams = [];
        let isIgnoreSelectedTeams = options && options.ignoreSelectedTeams;
        let isRequireProgram = options && options.requireProgram;

        if (isRequireProgram && !this.programs.length) {
            teams = [];
        } else if (!this.programs.length) {
            teams = [].concat(this.allTeams);
        } else if (!isIgnoreSelectedTeams) {
            teams = [].concat(this.teams);
        }

        this.programs.forEach(p => {

            let programTeamsAlreadySelected;

            if (isIgnoreSelectedTeams) {
                programTeamsAlreadySelected = [];
            } else {
                programTeamsAlreadySelected = this.teams.filter(t => t.programId == p.id);
            }

            /**
             * If a program is checked, but no team for that program is 
             * checked, then add all of that program's teams, otherwise,
             * add all of the checked teams for that program
             */
            if (!programTeamsAlreadySelected.length) {
                let allProgramTeams = this.allTeams.filter(t => t.programId == p.id);
                teams = teams.concat(allProgramTeams);
            }

        });

        /**
         * Filter out the teams based on division (if the program of each division is checked)
         */
        let impliedDivisions = this.getImpliedDivisions();
        if (impliedDivisions.length && this.programs.length) {
            teams = teams.filter(t => {
                return impliedDivisions.some(d => d.id == t.divisionId || !t.divisionId)
            });
        }

        // console.log('get implied teams', teams);

        return teams;
    }

    getImpliedDivisions(options?: {
        requireProgram?: boolean
    }): Division[] {
        let divisions = [];
        let isRequireProgram = options && options.requireProgram;

        if (!this.programs.length && !isRequireProgram) {
            return this.allDivisions;
        }

        this.programs.forEach(p => {
            let programDivisionsAlreadySelected = this.divisions.filter(d => d.programId == p.id);


            /**
             * If a program is checked, but no division for that program is 
             * checked, then add all of that program's divisions, otherwise,
             * add all of the checked divisions for that program
             */
            if (!programDivisionsAlreadySelected.length) {
                let allProgramDivisions = this.allDivisions.filter(d => d.programId == p.id);
                divisions = divisions.concat(allProgramDivisions);
            } else {
                divisions = divisions.concat(programDivisionsAlreadySelected);
            }

        });

        // console.log('get implied divisions', divisions, this.programs, this.divisions);

        return divisions;
    }
}