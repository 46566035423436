/**
 * This class is used to load specific games that a user wants. 
 * Some examples of filters include:
 *  
 *  - by multiple player ids (ex. parents checking all the games of their
 *  3 children)
 *  - by program (ex. player checking the times of all the games in their
 *  league)
 *  - by startdate and/or enddate (can be in conjuction with other filters)
 *  - by team ids (ex. player checking all of the games for a rival team)
 * 
 * Note, a person should be able to filter by multiple teams, players,
 * and programs, but only by one type at a time. For example, a person
 * should not be able to filter games for 3 teams and 2 players. They 
 * must filter by one or the other.
 */
export class PlayerStatsFilter {
    registrationId: any;
    gameId: any;
    seasonId: any;

    constructor(data?: {
        registrationId?: Date,
        gameId?: Date,
        seasonId?: any [],
    }) {

        if (data) {
            this.registrationId = data.registrationId;
            this.gameId = data.gameId;
            this.seasonId = data.seasonId;
        }
    }

    getQuery() {

        let params = new URLSearchParams();

        /**
         * start_time must be in seconds
         */
        if (this.registrationId) {
            params.append('registration_id', this.registrationId);
        }

        /**
         * end_time must be in seconds
         */
        if (this.gameId) {
            params.append('game_id', this.gameId);
        }

        /**
         * end_time must be in seconds
         */
        if (this.seasonId) {
            params.append('season_id', this.seasonId);
        }

        return params.toString();
    }
}