import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SponsorsFilter } from '../../classes/filters/sponsorsFilter';
import { Sponsor } from '../../classes/tfflModels/sponsor';
import { HelperService } from '../util/helper.service';

@Injectable({
    providedIn: 'root'
})
export class SponsorsService {

    private url = '/sponsors';

    constructor(
        private http: HttpClient,
        private helperService: HelperService,
        private angulartics2: Angulartics2
    ) { }

    getSponsors(filter: SponsorsFilter): Observable<Sponsor[]> {

        if (!filter) {
            throw new Error('You must set a filter for retrieving sponsers');
        }

        let url = this.url + '?' + filter.getQuery();

        return this.http.get<{ sponsors: Sponsor[] }>(url)
            .pipe(
                map(response => response.sponsors),
                map(sponsors => this.fromServer(sponsors)),
                catchError(this.helperService.handleError('getSponsors', []))
            );
    }

    create(sponsor: Sponsor): Observable<Sponsor> {

        /**
         * Fire off the sponsor tracking event
         */
        this.angulartics2.eventTrack.next({
            action: 'Sponsor',
            properties: {
                value: {
                    sponsorId: sponsor.id,
                    seasonId: sponsor.seasonId,
                    price: sponsor.price,
                    teamId: sponsor.teamId
                }
            }
        });

        if (sponsor == null) {
            throw new Error('Sponsor cannot be null');
        }

        return this.http.post<{ sponsor: Sponsor }>(this.url, { sponsor: sponsor })
            .pipe(
                map(result => result.sponsor),
                map(Sponsor.fromServer), /* IMPORTANT */
                catchError(this.helperService.handleError('createSponsor', sponsor))
            );
    }

    private fromServer(sponsors: Sponsor[]): Sponsor[] {
        return sponsors.map(s => Sponsor.fromServer(s));
    }
}
