import { User } from "./user";

export class Coupon {
    
    id: any;
    referralUserId: any;
    referralUser: User;
    name: string;
    programId: any;
    amountFlat: number;
    amountPercentage: number;
    isActive: boolean;
    description: string;

    constructor(data?: {
        id?: any,
        referralUserId?: any,
        referralUser?: User,
        name?: string,
        programId?: any,
        amountFlat?: number,
        amountPercentage?: number,
        isActive?: boolean,
        description?: string,
    }) {

        if (data) {
            this.id = data.id;
            this.referralUserId = data.referralUserId;
            this.referralUser = data.referralUser;
            this.name = data.name;
            this.programId = data.programId;
            this.amountFlat = data.amountFlat;
            this.amountPercentage = data.amountPercentage;
            this.isActive = data.isActive;
            this.description = data.description;
        }
    }

    getDiscountedPrice(amount: number): number {
        if (this.amountFlat) {
            return amount - this.amountFlat;
        } else if (this.amountPercentage) {
            return amount * (1 - this.amountPercentage / 100.0);
        } else {
            throw new Error('No discount provided');
        }
    }

    static fromServer(coupon: Coupon): Coupon {
        coupon.isActive = coupon.isActive || false;
        coupon.amountFlat = +coupon.amountFlat;
        coupon.amountPercentage = +coupon.amountPercentage;

        if (coupon.referralUser) {
            coupon.referralUser = User.fromServer(coupon.referralUser);
        }

        coupon = new Coupon(coupon);
        
        return coupon;
    }
}