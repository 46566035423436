import { Registration } from './registration';

export class StaffHours {

    id: any;
    date: Date;
    registrationId: any;
    registration: Registration;
    hours: number;
    hoursConfirm: number;
    setup: number;
    cleanup: number;
    rating: number;
    notes: string;
    locationId: any;

    private _totalPayableHours: number;
    private _totalPayableDollars: number;

    get totalPayableHours(): number {
        if (this._totalPayableHours === null || this._totalPayableHours === undefined) {
            this._totalPayableHours = this.calculateTotalHours();
        }

        return this._totalPayableHours;
    }

    get totalPayableDollars(): number {
        if (this._totalPayableDollars === null || this._totalPayableDollars === undefined) {
            this._totalPayableDollars = this.calculateTotalPayable();
        }

        return this._totalPayableDollars;
    }

    get isHighSalary(): boolean {
        let threshold = this.registration ? this.registration.salaryRateThreshold : 0;
        return this.totalPayableHours >= threshold;
    }

    get salaryRate(): number {

        if (!this.registration) return 0;

        /**
         * If either rates are 0, return the non-zero of the two.
         * If both rates are 0, return 0
         */
        if (!this.registration.salaryLowRate || !this.registration.salaryHighRate) {
            return this.registration.salaryLowRate || this.registration.salaryHighRate || 0;
        }

        return this.isHighSalary ? this.registration.salaryHighRate : this.registration.salaryLowRate;
    }

    constructor(data?: {
        id?: any,
        date?: Date,
        registrationId?: any,
        registration?: Registration,
        hours?: number,
        hoursConfirm?: number,
        setup?: number,
        cleanup?: number,
        rating?: number,
        notes?: string,
        locationId?: any
    }) {

        if (data) {
            this.id = data.id;
            this.date = data.date;
            this.registrationId = data.registrationId;
            this.registration = data.registration;
            this.locationId = data.locationId;
            this.hours = data.hours;
            this.hoursConfirm = data.hoursConfirm;
            this.setup = data.setup;
            this.cleanup = data.cleanup;
            this.rating = data.rating;
            this.notes = data.notes;
        }
    }

    static fromServer(item: StaffHours): StaffHours {

        if (item.registration) {
            item.registration = Registration.fromServer(item.registration);
        }
        // Added 1 day because of GMT timezone issues not macthing to Game date
        item.date = item.date ? new Date(new Date(item.date).setDate(new Date(item.date).getDate() + 1)) : null;
        item.hours = +item.hours;
        item.hoursConfirm = +item.hoursConfirm;
        item.setup = +item.setup;
        item.cleanup = +item.cleanup;
        item.rating = +item.rating;
        item = new StaffHours(item);
        return item;
    }

    private calculateTotalHours(): number {
        let hours = this.hoursConfirm || 0;
        let setup = this.setup || 0;
        let cleanup = this.cleanup || 0;
        return hours + (setup + cleanup) * 0.25;
    }

    private calculateTotalPayable(): number {
        return this.totalPayableHours * this.salaryRate;
    }
}