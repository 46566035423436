/**
 * This class is used to load specific users, such as based on their name
 */
export class TeamsFilter {
    programIds: any[];
    seasonId: any;
    divisionIds: any[];

    constructor(data?: {
        programIds?: any[],
        seasonId?: any,
        divisionIds?: any[],
    }) {

        if (data) {
            this.programIds = data.programIds;
            this.seasonId = data.seasonId;
            this.divisionIds = data.divisionIds;
        }
    }

    getQuery() {

        let params = new URLSearchParams();

        if (this.programIds) {
            this.programIds.forEach(id => params.append('program_ids[]', id));
        }

        if (this.seasonId) {
            params.append('season_id', this.seasonId);
        }

        if (this.divisionIds) {
            this.divisionIds.forEach(id => params.append('division_ids[]', id));
        }

        return params.toString();
    }
}