import * as momentImported from 'moment';
import { PRIMARY_COLOR } from '../deleteThis/colorScheme';
import { DateHelper } from '../util/dateHelper';
import { Address } from "./address";
import { Division } from './division';
import { GameClock } from './gameClock';
import { League } from './league';
import { Program } from './program';
import { ProgramDivision } from './programDivision';
import { Registration } from './registration';
import { Season } from './seasons';
import { Team } from "./team";

const moment = momentImported;

export class Game {

    id: any;
    homeTeamId: any;
    homeTeam: Team;
    awayTeamId: any;
    awayTeam: Team;
    homeName: string;
    awayName: string;
    programId: any;
    program: Program;
    divisionId: any;
    division: Division;
    seasonId: any;
    season: Season;
    leagueId: any;
    league: League;
    gameTypeId: GameType;
    duration: number;
    endTime: Date; /* This is calculated from the startTime and duration */
    locationId: any;
    location: Address;
    down: number;
    playNumber: number;
    half: number; /* Which half we are in: 1, 2 or null */
    time: number; /* The amount of time left in the half in milliseconds */
    hasLowCoachAttendance: boolean;
    hasLowPlayerAttendance: boolean;
    homeScore: number;
    homeSportsmanship: number;
    homeForfeit: boolean;
    awayScore: number;
    awaySportsmanship: number;
    awayForfeit: boolean;
    coinToss: any;
    refereeHead: any;
    refereeStats: any;
    refereeDownfield: any;
    refereeAdditional: any;
    notes: string;
    status: GameStatus;
    totalDowns: number;
    halfDuration: number;

    get startTime(): Date { return this._startTime; }
    get posession(): any { return this._posession; }
    get isComplete(): boolean { return this.status == GameStatus.COMPLETE; }
    get firstHalfSelector(): any { return this._ballFirst; }
    get secondHalfSelector(): any { return this._ballSecond; }
    get programName(): string { return this.program ? this.program.name : null }
    get divisionName(): string { return this.division ? this.division.name : null }
    get homeTeamName(): string { return this.homeTeam && this.homeTeam.name ? this.homeTeam.name : this.homeName; }
    get awayTeamName(): string { return this.awayTeam && this.awayTeam.name ? this.awayTeam.name : this.awayName; }
    get homeTeamShortName(): string { return this.homeTeam && this.homeTeam.shortName ? this.homeTeam.shortName : this.homeName; }
    get awayTeamShortName(): string { return this.awayTeam && this.awayTeam.shortName ? this.awayTeam.shortName : this.awayName; }
    get homeTeamColor(): string { return this.homeTeam ? this.homeTeam.color : PRIMARY_COLOR; }
    get awayTeamColor(): string { return this.awayTeam ? this.awayTeam.color : PRIMARY_COLOR; }
    get homeRegistrations(): Registration[] { return this.homeTeam ? this.homeTeam.registrations : []; }
    get awayRegistrations(): Registration[] { return this.awayTeam ? this.awayTeam.registrations : []; }
    // get endTime(): Date { return new Date(this.startTime.getTime() + this.duration * 60000); }
    get offenseTeam(): Team { return this.posession == this.homeTeam.id ? this.homeTeam : this.awayTeam; }
    get defenseTeam(): Team { return this.posession != this.homeTeam.id ? this.homeTeam : this.awayTeam; }
    get offenseScore(): number { return this.posession == this.homeTeam.id ? this.homeScore : this.awayScore; }
    get defenseScore(): number { return this.posession != this.homeTeam.id ? this.homeScore : this.awayScore; }

    /**
     *  The team that has posession. Equal to homeId or awayId 
     */
    private _posession: any;
    private _startTime: any;
    private _ballSecond: any;
    private _ballFirst: any;

    set startTime(startTime: Date) {
        this._startTime = startTime;
        if (startTime) {
            this.endTime = new Date(this.startTime.getTime() + this.duration * 60000);
        } else {
            this.endTime = undefined;
        }
    }

    set posession(teamId: any) {
        if (!this.isValidTeam(teamId)) {
            throw new Error('Could not match team: ' + teamId);
        }

        this._posession = teamId;
    }

    set firstHalfSelector(ballFirst: any) {
        this._ballFirst = ballFirst;
        this.status = GameStatus.INGAME;
    }

    set secondHalfSelector(ballSecond: any) {
        this._ballSecond = ballSecond;
        this.status = GameStatus.INGAME;
    }

    set offenseScore(score: number) {
        if (this.posession == this.homeTeam.id) {
            this.homeScore = score;
        } else {
            this.awayScore = score;
        }
    }

    set defenseScore(score: number) {
        if (this.posession != this.homeTeam.id) {
            this.homeScore = score;
        } else {
            this.awayScore = score;
        }
    }

    get isAdultLeague(): boolean {
        if (this.program) {
            return this.program.isAdultLeague();
        } else if (this.homeTeam && this.homeTeam.program) {
            return this.homeTeam.program.isAdultLeague();
        } else {
            return false;
        }
    }

    get winningTeam(): Team {
        if (this.homeScore == this.awayScore) return null;
        return this.homeScore > this.awayScore ? this.homeTeam : this.awayTeam;
    }

    get isTie(): boolean {
        return this.homeScore == this.awayScore;
    }

    get isPractice(): boolean {
        return GameType.PRACTICE == this.gameTypeId;
    }

    get locationName(): string {
        return this.location && this.location.name ? this.location.name : null;
    }

    isValidTeam(teamId: any) {
        return [this.homeTeamId, this.awayTeamId].includes(teamId);
    }

    switchPossession(): void {
        this.posession = this.getOtherTeamId(this.posession);
    }

    getTeam(teamId: any): Team {
        if (!this.isValidTeam(teamId)) {
            throw new Error('Could not match team: ' + teamId);
        }

        return this.homeTeamId == teamId ? this.homeTeam : this.awayTeam;

    }

    getOtherTeamId(teamId: any): any {
        if (!this.isValidTeam(teamId)) {
            throw new Error('Could not match team: ' + teamId);
        }

        return this.homeTeamId == teamId ? this.awayTeam.id : this.homeTeam.id;
    }

    getPlayerFromUserId(userId: any): Registration {
        let player =
            this.getPlayerOnTeamByUserId(userId, this.offenseTeam) ||
            this.getPlayerOnTeamByUserId(userId, this.defenseTeam) ||
            null;

        if (!player) throw new Error('Player does not exist on either team');

        return player;
    }

    getPlayer(registrationId: any): Registration {
        let player =
            this.getPlayerOnTeam(registrationId, this.offenseTeam) ||
            this.getPlayerOnTeam(registrationId, this.defenseTeam) ||
            null;

        if (!player) throw new Error('Player does not exist on either team');

        return player;
    }

    getGameClock(): GameClock {
        return new GameClock({ time: this.time || 0 });
    }

    getSportsmanship(teamId: any) {
        if (!this.isValidTeam(teamId)) {
            throw new Error('Could not match team: ' + teamId);
        }

        return this.homeTeamId == teamId ? this.homeSportsmanship : this.awaySportsmanship;
    }

    getDateString(): string {
        if (!this.startTime) {
            throw new Error('No start time for the game');
        }

        return moment(this.startTime).format('YYYY-MM-DD');
    }

    isHomeTBD(): boolean {
        return !this.isPractice &&
            (!this.homeTeam || !this.homeTeam.name);
    }
    isAwayTBD(): boolean {
        return !this.isPractice &&
            (!this.awayTeam || !this.awayTeam.name);
    }
    isPlayable(): boolean {
        return !this.isPractice && !this.isHomeTBD() && !this.isAwayTBD();
    }

    isDefer(): boolean {
        return this.coinToss != this.firstHalfSelector;
    }

    isStarted(): boolean {
        return [GameStatus.COMPLETE, GameStatus.INGAME].includes(this.status);
    }

    private getPlayerOnTeam(registrationId: any, team: Team): Registration {
        try {
            return team.getRegistration(registrationId);
        } catch (e) {
            return null;
        }
    }

    private getPlayerOnTeamByUserId(userId: any, team: Team): Registration {
        try {
            return team.getRegisrationByUserId(userId);
        } catch (e) {
            return null;
        }
    }

    // private getLogicalStatus(): GameStatus {

    //     if (this.isComplete) {
    //         return GameStatus.COMPLETE;
    //     } else if (this.firstHalfSelector || this.secondHalfSelector) {
    //         return GameStatus.INGAME;
    //     } else if (this.startTime && this.startTime.getTime() >= (new Date()).getTime()) {
    //         return GameStatus.SCHEDULED;
    //     } else if (this.endTime && this.endTime.getTime() > (new Date()).getTime()) {
    //         return GameStatus.COINTOSS;
    //     } else {
    //         // return GameStatus.delayed;
    //         return GameStatus.SCHEDULED;
    //     }
    // }

    constructor(data?: {
        id?: any,
        homeTeamId?: any,
        homeTeam?: Team,
        awayTeamId?: any,
        awayTeam?: Team,
        homeName?: string,
        awayName?: string,
        programId?: any,
        program?: Program,
        divisionId?: any,
        division?: Division,
        seasonId?: any,
        season?: Season,
        leagueId?: any,
        league?: League,
        gameTypeId?: GameType,
        duration?: number,
        date?: Date,
        startTime?: Date,
        locationId?: any,
        location?: Address,
        posession?: any,
        down?: number,
        playNumber?: number,
        half?: number,
        time?: number,
        hasLowCoachAttendance?: boolean;
        hasLowPlayerAttendance?: boolean;
        homeScore?: number,
        homeSportsmanship?: number,
        homeForfeit?: boolean,
        awayScore?: number,
        awaySportsmanship?: number,
        awayForfeit?: boolean,
        coinToss?: any,
        ballFirst?: any,
        ballSecond?: any,
        refereeHead?: any,
        refereeStats?: any,
        refereeDownfield?: any,
        refereeAdditional?: any,
        notes?: string,
        status?: GameStatus,
        totalDowns?: number,
        halfDuration?: number,
    }) {

        if (data) {
            this.id = data.id;
            this.homeTeamId = data.homeTeamId;
            this.homeTeam = data.homeTeam;
            this.awayTeamId = data.awayTeamId;
            this.awayTeam = data.awayTeam;
            this.homeName = data.homeName;
            this.awayName = data.awayName;
            this.programId = data.programId;
            this.program = data.program;
            this.divisionId = data.divisionId;
            this.division = data.division;
            this.seasonId = data.seasonId;
            this.season = data.season;
            this.leagueId = data.leagueId;
            this.league = data.league;
            this.gameTypeId = data.gameTypeId;
            this.duration = data.duration;
            this.startTime = data.startTime;
            this.locationId = data.locationId;
            this.location = data.location;
            if (data.posession) this.posession = data.posession;
            this.down = data.down;
            this.playNumber = data.playNumber;
            this.half = data.half;
            this.time = data.time;
            this.hasLowCoachAttendance = data.hasLowCoachAttendance;
            this.hasLowPlayerAttendance = data.hasLowPlayerAttendance;
            this.homeScore = data.homeScore;
            this.homeSportsmanship = data.homeSportsmanship;
            this.homeForfeit = data.homeForfeit;
            this.awayScore = data.awayScore;
            this.awaySportsmanship = data.awaySportsmanship;
            this.awayForfeit = data.awayForfeit;
            this.coinToss = data.coinToss;
            this.firstHalfSelector = data.ballFirst;
            this.secondHalfSelector = data.ballSecond;
            this.refereeHead = data.refereeHead;
            this.refereeStats = data.refereeStats;
            this.refereeDownfield = data.refereeDownfield;
            this.refereeAdditional = data.refereeAdditional;
            this.notes = data.notes;
            this.status = data.status;
            this.totalDowns = data.totalDowns;
            this.halfDuration = data.halfDuration;
        }
    }

    toServer(): any {
        let data: any = {};
        if (this.id !== undefined) data.id = this.id;
        if (this.programId !== undefined) data.programId = this.programId;
        if (this.divisionId !== undefined) data.divisionId = this.divisionId;
        if (this.seasonId !== undefined) data.seasonId = this.seasonId;
        if (this.leagueId !== undefined) data.leagueId = this.leagueId;
        if (this.homeTeamId !== undefined) data.homeTeamId = this.homeTeamId;
        if (this.awayTeamId !== undefined) data.awayTeamId = this.awayTeamId;
        if (this.homeName !== undefined) data.homeName = this.homeName;
        if (this.awayName !== undefined) data.awayName = this.awayName;
        if (this.gameTypeId !== undefined) data.gameTypeId = this.gameTypeId;
        if (this.startTime !== undefined) data.startTime = this.startTime.toISOString();
        if (this.down !== undefined) data.down = this.down;
        if (this.half !== undefined) data.half = this.half;
        if (this.time !== undefined) data.time = this.time;
        if (this.homeScore !== undefined) data.homeScore = this.homeScore;
        if (this.homeSportsmanship !== undefined) data.homeSportsmanship = this.homeSportsmanship;
        if (this.homeForfeit !== undefined) data.homeForfeit = this.homeForfeit;
        if (this.awayScore !== undefined) data.awayScore = this.awayScore;
        if (this.awaySportsmanship !== undefined) data.awaySportsmanship = this.awaySportsmanship;
        if (this.awayForfeit !== undefined) data.awayForfeit = this.awayForfeit;
        if (this.coinToss !== undefined) data.coinToss = this.coinToss;
        if (this.firstHalfSelector !== undefined) data.ballFirst = this.firstHalfSelector;
        if (this.secondHalfSelector !== undefined) data.ballSecond = this.secondHalfSelector;
        if (this.refereeHead !== undefined) data.refereeHead = this.refereeHead;
        if (this.refereeStats !== undefined) data.refereeStats = this.refereeStats;
        if (this.refereeDownfield !== undefined) data.refereeDownfield = this.refereeDownfield;
        if (this.refereeAdditional !== undefined) data.refereeAdditional = this.refereeAdditional;
        if (this.notes !== undefined) data.notes = this.notes;
        if (this.status !== undefined) data.status = this.status;
        if (this.locationId !== undefined) data.locationId = this.locationId;
        if (this.duration !== undefined) data.duration = this.duration;
        if (this.totalDowns !== undefined) data.totalDowns = this.totalDowns;
        if (this.halfDuration !== undefined) data.halfDuration = this.halfDuration;
        return data;
    }

    static fromServer(game: Game): Game {

        game.homeTeam = new Team(game.homeTeam);
        game.awayTeam = new Team(game.awayTeam);
        if (game.program) game.program = new Program(game.program);
        if (game.division) game.division = new Division(game.division);
        if (game.season) game.season = new Season(game.season);
        if (game.league) game.league = new League(game.league);
        game.location = new Address(game.location);
        game.homeScore = +game.homeScore;
        game.homeSportsmanship = +game.homeSportsmanship;
        game.homeForfeit = game.homeForfeit || false;
        game.awayScore = +game.awayScore;
        game.awaySportsmanship = +game.awaySportsmanship;
        game.awayForfeit = game.awayForfeit || false;
        game.down = +game.down;
        game.half = +game.half;
        game.time = +game.time;
        game.totalDowns = +game.totalDowns;
        game.halfDuration = +game.halfDuration;
        game.startTime = moment(game.startTime).toDate();
        game = new Game(game);

        return game;
    }

    /**
     * Returns whether the game matches the current filter.
     * 
     * If the current filter has no teams, all games that match
     * any of the divisions are displayed.
     * 
     * If the current filter does have teams, all games with either
     * the home team or away team matching any of the teams are displayed.
     */
    static isMatch({ date, game, teams, programDivisionIds }: { date: Date; game: Game; teams?: Team[]; programDivisionIds?: ProgramDivision[] }): boolean {

        let isDateMatch = date == null || DateHelper.isSameDate(game.startTime, date);

        if (!isDateMatch) {
            return false;
        }

        let isProgramDivisionMatch = programDivisionIds && programDivisionIds.some(programDivIds => {
            return game.programId == programDivIds.programId &&
                (
                    !game.divisionId ||
                    !programDivIds.divisionId ||
                    game.divisionId == programDivIds.divisionId
                );
        });

        if (isProgramDivisionMatch) {
            return true;
        }

        let isTBD = game.isHomeTBD() || game.isAwayTBD();
        let isTeamMatch = teams && teams.some(team => {

            /**
             * First check if teams match. If so, return true, otherwise,
             * continue checking
             */
            let isTeamMatch = team.id == game.homeTeamId ||
                team.id == game.awayTeamId;

            if (isTeamMatch) {
                return true;
            }

            /**
             * If teams don't match, check if the home or away team
             * of the game is TBD, if so, check if division/program
             * matches
             */
            if (isTBD) {

                let isProgramMatch = game.programId == team.programId;
                let isDivisionSet = !!game.divisionId;
                let isDivisionMatch = !team.divisionId || !game.divisionId || game.divisionId == team.divisionId;

                return isDivisionSet ? isDivisionMatch && isProgramMatch : isProgramMatch;
            }

            return false;

        });

        return isTeamMatch;
    }

    static getGameDays(games: Game[]): Date[] {

        let days = [];

        if (games.length) {

            days.push(games[0].startTime);

            for (let i = 1; i < games.length; i++) {
                let prevGame = games[i - 1];
                let nextGame = games[i];
                if (!DateHelper.isSameDate(prevGame.startTime, nextGame.startTime)) {
                    days.push(nextGame.startTime);
                }
            }
        }

        return days;
    }

    clone(): Game {
        return Game.fromServer(this);
    }
}

export enum GameSection {
    'checkin' = 'checkin',
    'box' = 'box',
    'log' = 'log',
}

export enum GameStatus {
    'SCHEDULED' = 'SCHEDULED',
    'CANCELLED' = 'CANCELLED',
    'POSTPONED' = 'POSTPONED',
    'COMPLETE' = 'COMPLETE',
    'INGAME' = 'INGAME',
}

export enum GameType {
    'PLAYOFF' = 'PLAYOFF',
    'PRACTICE' = 'PRACTICE',
    'PRESEASON' = 'PRESEASON',
    'SEASON' = 'SEASON',
}

export enum GameTypeDurations {
    "PLAYOFF" = 60,
    "PRACTICE" = 30,
    "PRESEASON" = 60,
    "SEASON" = 60,
}