import * as momentImported from 'moment';
import { Program } from '../tfflModels/program';

const moment = momentImported;

export class ProgramsListFilter {
    id: any;
    ages: { name: string, isChecked: boolean, year: number }[] = [];
    programs: Program[];

    constructor() {
        this.ages = [];
    }

    addAges(min: number, max: number) {
        this.ages = [];
        for (let year = min; year >= max; year--) {

            let name = year == max ? year + "+" : year + "";

            this.addAge({
                name: name,
                isChecked: false,
                year: year
            });
        }
    }

    addAge(newGroup: { name: string, isChecked: boolean, year: number }) {
        this.ages.push(newGroup);
    }

    getFiltered(): Program[] {

        /** If no ages are checked, return all programs */
        if (!this.ages.some(age => age.isChecked)) {
            return this.programs;
        }

        let filtered = this.programs.filter(program => {
            return this.isAgeIncluded(program)
        });

        return filtered;
    }

    private isAgeIncluded(program: Program): boolean {

        for (let i = 0; i < this.ages.length; i++) {
            let age = this.ages[i];

            if (age.isChecked &&
                moment.utc(program.minBirthdate).year() >= age.year &&
                (
                    !program.maxBirthdate ||
                    moment.utc(program.maxBirthdate).year() <= age.year
                )
            ) {
                return true;
            }
        }

        return false;
    }
}