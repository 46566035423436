import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeAll, mergeMap, tap } from 'rxjs/operators';
import { GamesFilter } from '../../classes/filters/gamesFilter';
import { PlayerStatsFilter } from '../../classes/statsApp/playerStatsFilter';
import { Game } from '../../classes/tfflModels/game';
import { PlayerStats } from '../../classes/tfflModels/playerStats';
import { HelperService } from '../util/helper.service';
import { GamesService } from './games.service';
import { SeasonsService } from './seasons-service';

@Injectable({
    providedIn: 'root'
})
export class StatsService {

    passingCategories = [
        { title: 'Comp.', field: 'completions' },
        { title: '1sts', field: 'completionFirsts' },
        { title: 'TDs', field: 'completionTds' },
        { title: 'Yds', field: 'completionYards' },
    ];
    receivingCategories = [
        { title: 'Rec.', field: 'receptions' },
        { title: '1sts', field: 'receptionFirsts' },
        { title: 'TDs', field: 'receptionTds' },
        { title: 'Yds', field: 'receptionYards' },
    ];
    rushingCategories = [
        { title: 'Rush.', field: 'rushes' },
        { title: '1sts', field: 'rushFirsts' },
        { title: 'TDs', field: 'rushTds' },
        { title: 'Yds', field: 'rushYards' },
    ];
    defenseCategories = [
        { title: 'Flags', field: 'flagPulls' },
        { title: 'Defl', field: 'deflections' },
        { title: 'Sack', field: 'sacks' },
        { title: 'Int.', field: 'interceptions' },
        { title: 'TDs', field: 'interceptionTds' },
    ];
    specialOffenseCategories = [
        { title: 'RT', field: 'kickReturns' },
        { title: 'Yds', field: 'kickReturnYards' },
        { title: 'Tds', field: 'kickReturnTds' },
    ];
    specialDefenseCategories = [
        { title: 'Kicks', field: 'kicks' },
        { title: 'Punts', field: 'punts' },
    ];

    constructor(
        private http: HttpClient,
        private helperService: HelperService,
        private gamesService: GamesService,
        private seasonsService: SeasonsService,
    ) { }

    saveStats(stats: PlayerStats[]): Observable<void> {
        let data = { stats: stats.map(stat => stat.toServer()) };
        return this.http.post<{ stats: PlayerStats[] }>('/stats', data).pipe(
            // map(result => result.stats),
            // map(stats => stats.map(s => PlayerStats.fromServer(s))),
            catchError(this.helperService.handleError('saveLogs', null))
        );
    }

    getSeasonStats(filter: PlayerStatsFilter): Observable<PlayerStats[]> {
        return this.getStats(filter).pipe(
            map(stats => this.joinStatsByRegistration(stats))
        )
    }

    getGameStats(filter: PlayerStatsFilter): Observable<PlayerStats[]> {
        return this.getStats(filter);
    }

    uploadSeasonStats(seasonId: any): Observable<any> {
        let gameFilter = new GamesFilter({ seasonId: seasonId });
        return this.gamesService.getGames(gameFilter).pipe(
            // map(games => games.slice(0, 10)),
            mergeAll(),
            filter((game: Game) => game.isComplete),
            mergeMap(game => this.gamesService.getGameInfo(game.id)),
            tap(gameInfo => console.log('saving stats', gameInfo)),
            mergeMap(gameInfo => this.saveStats(gameInfo.stats)),
            tap(stats => console.log('saved stats', stats))
        )
    }

    private getStats(filter: PlayerStatsFilter): Observable<PlayerStats[]> {
        let queryParams = filter ? '?' + filter.getQuery() : '';
        let url = '/stats' + queryParams;
        return this.http.get<{ stats: PlayerStats[] }>(url).pipe(
            map(result => result.stats),
            map(stats => stats.map(s => PlayerStats.fromServer(s))),
            catchError(this.helperService.handleError('saveLogs', null))
        );
    }

    private joinStatsByRegistration(stats: PlayerStats[]): PlayerStats[] {
        let groupedStats: { [regId: number]: PlayerStats } = {};

        stats.forEach(stat => {
            if (!stat.registrationId) {
                return;
            }
            let regId = stat.registrationId;

            /**
             * If the stats are the first for the registration, there 
             * is nothing to combine.
             * 
             * If the stats are not the first, then we have to combine the 
             * accumulated stats with the new stats.
             */
            if (!groupedStats[regId]) {
                groupedStats[regId] = stat;
            } else {
                groupedStats[regId] = groupedStats[regId].combine(stat);
            }
        });

        return Object.values(groupedStats);
    }
}
