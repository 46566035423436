import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class HeaderBackgroundService {

    private subject: BehaviorSubject<string>;

    constructor(
        private router: Router
    ) { 
        let src = this.getUrl(this.router.url);
        this.subject = new BehaviorSubject<string>(src);

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                let src = this.getUrl(event.urlAfterRedirects);
                this.subject.next(src);
            }
        });
    }

    getSrc(): Observable<string> {
        return this.subject;
    }

    getUrl(url) {
        return url == '/' ? '//s3.amazonaws.com/tffl/photos/child-with-mouthguard.jpg' : null;
    }

    // return '//s3.amazonaws.com/tffl/photos/two-brothers-side-by-side.jpg';
    // return '//s3.amazonaws.com/tffl/photos/three-teammates-side-by-side.jpg';
    // return '//s3.amazonaws.com/tffl/photos/cute-kid-celebrating.jpg';
    // return '//s3.amazonaws.com/tffl/photos/child-playing-defense.jpg';
    // return '//s3.amazonaws.com/tffl/photos/child-with-mouthguard.jpg';  

}
