import { Coupon } from "./coupon";
import { FriendRequest } from "./friendrequest";
import { Program } from "./program";
import { Registration, RegistrationType } from "./registration";
import { Season } from "./seasons";
import { Sponsor } from "./sponsor";
import { Team } from "./team";
import { User } from "./user";

export class CartItem {

    orderId: any;
    isPaid: boolean;
    price: number;
    discountedPrice: number;
    creditAvailable: number;
    isSelected: boolean;
    registration: Registration;
    sponsor: Sponsor;

    constructor(data?: {
		orderId?: any,
		isPaid?: boolean,
		price?: number,
        discountedPrice?: number,
        creditAvailable?: number,
		isSelected?: boolean,
		registration?: Registration,
		sponsor?: Sponsor,
	}) {

		if (data) {
			this.orderId =  data.orderId;
			this.isPaid =  data.isPaid;
			this.price =  data.price;
			this.discountedPrice =  data.discountedPrice;
			this.creditAvailable =  data.creditAvailable;
			this.isSelected =  data.isSelected;
			this.registration =  data.registration;
			this.sponsor =  data.sponsor;
		}
    }
    
    static fromRegistration(registration: Registration): CartItem {

        if (!registration) {
            throw new Error('Registration cannot be passed as null when converting to a CartItem');
        }

        let item = new CartItem({
            orderId: registration.orderId,
            isPaid: registration.isPaid,
            price: registration.price,
            discountedPrice: registration.discountedPrice,
            creditAvailable: registration.creditAvailable,
            isSelected: !registration.isPaid && registration.price > 0,
            registration: registration,
        });

        return item;
    }
    
    static fromSponsor(sponsor: Sponsor): CartItem {

        if (!sponsor) {
            throw new Error('Sponsor cannot be passed as null when converting to a CartItem');
        }

        let item = new CartItem({
            orderId: sponsor.orderId,
            isPaid: sponsor.isPaid,
            price: sponsor.price,
            discountedPrice: sponsor.price,
            creditAvailable: 0,
            isSelected: !sponsor.isPaid && sponsor.price > 0,
            sponsor: sponsor,
        });

        return item;
    }
}